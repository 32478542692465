import * as yup from 'yup';

export let updateCandidateSchema = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    // gender: yup.mixed().oneOf(['male', 'female']).nullable(),
    looking_to_immigrate_date_of_birth: yup.date().max(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), 'Date of Birth must be at least 18 years old.').nullable(),
    email : yup.string().email().required(),
    country_id : yup.string().required("Country is required").nullable(),
    country_code : yup.string().required("Country code is required"),
    mobile : yup.number().required(),
    address : yup.string().required().nullable(),
    resume : yup.mixed().nullable(),
    image : yup.mixed().nullable(),
    field_of_study: yup.string().required("Field of study is required").nullable(),
    graduation_year: yup.date().required("Graduation year is required").nullable(),
    is_att_received:yup.mixed().oneOf(['1', '0']).required(),
    test_date:yup.date().required("Test Date is required"),
    is_passed:yup.mixed().oneOf(['1', '0']).required(),
    license_no:yup.string().required("license no is required")
});

