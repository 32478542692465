import { Link } from "react-router-dom";
import React from "react";
import { useMutation } from "react-query";
import { changeApplicationStatus, scheduleMeeting } from "../../../adapters/agencyAdapter";
import { insertAvailability } from "../../../adapters/nurseAdapter";
import { formMutationOptions } from "../../../Helpers/ReactQueryHelper";
import { useAgencyDashboard } from "../AgencyDashboardProvider";


export function InterviewSection({ application }) {
    const { reloadDashboard, setReloadDashboard } = useAgencyDashboard()

    const mutation = useMutation(changeApplicationStatus, {
        onSuccess: (data) => {
            setReloadDashboard(!reloadDashboard);
        },
    })
    return <div className="calendar-tab border-top-dashed">
        <div className="d-flex">
            <div className="calendar-list active">
                <span>
                    2
                </span>
            </div>
            <div className="calendar-flex">
                <h3 className="tab-title">Interview</h3>
                <p>
                    Keep track of scheduled interviews and manage them effortlessly with just a few clicks.
                </p>
                <p className="mt-3">
                    Summary The calendar for April 21, 2022 has been approved.
                </p>
            {console.log(application.meeting_data.ended)}
                {application.meeting_data.ended ?

                    <>
                        <div className="row">
                            <div className="col-lg-4">
                                <button onClick={() => mutation.mutate({ action_status: "cancelled" })} className="btn-white-dark p-2">Cancel</button>
                            </div>
                            <div className="col-lg-4">
                                <button onClick={() => mutation.mutate({ action_status: "approved" })} className="btn_submit_dark p-2">Proceed To Step 3</button>
                            </div>
                        </div>
                    </>

                    :
                    <div className="row">
                        <div className="col-lg-4">
                            <button onClick={() => mutation.mutate({ action_status: "cancelled" })} className="btn-white-dark p-2">Cancel Meeting</button>
                        </div>
                        <div className="col-lg-4">
                            <button onClick={() => {
                                window.location.href = application.meeting_data.meeting_link
                            }} className="btn_submit_dark p-2">Join Meeting</button>
                        </div>
                        <div className="col-lg-4">
                            <button onClick={() => mutation.mutate({ action_status: "rescheduled" })} className="btn_submit_dark p-2 fs-13">
                                Reschedule Meeting
                                <img src="img/calendar-white.svg" /></button>
                            {/*<button className="btn_submit_dark">Approve to proceed</button>*/}
                        </div>
                            <div className="col-lg-4">
                                <button onClick={() => mutation.mutate({ action_status: "approved" })} className="btn_submit_dark p-2">Approve</button>
                            </div>
                    </div>

                }
            </div>
        </div>
    </div>
}