import React, { useState } from 'react';
import { ContactForm } from "../Common/ContactForm";
import { Link } from "react-router-dom";
import * as animationData from "../../assets/animation/Apply_3.json";
import { LoginContainer } from "../Common/LoginContainer";
import { Form, Formik } from "formik";
// import {emailLoginSchema} from "../../schemas/emailLoginSchema";
import { handleFormikSubmit } from "../../Helpers/formHelper";
import { getLoginToken, Login } from "../../adapters/representativeAdapter";
import { AcceptTermsCondition, Countries, CountryCode, FormikRecaptcha, Input, Password, Recaptcha } from "../../Helpers/formComponents";
import { motion } from "framer-motion";
import { useMutation } from "react-query";
import * as RepresentativeAnimation from '../../assets/animation/SignIn.json';
import { useAgencyDashboard } from "../AgencyDashboard/AgencyDashboardProvider";


export default function RepresentativeLogin() {

    const [disable, setDisable] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState({ "text": "", "class": "text-danger" })
    const [tokenError, setTokenError] = useState("")


    const mutation = useMutation(data => {
        return getLoginToken(data)
    }, {

        onError: (error) => {
            setDisable(false)
            console.log(error)
            setTokenError(error.response.data.message);
        },
        onSuccess: (error) => {
            setDisable(true)
            setTokenError("Please check your email");

        },
        onMutate: (data) => {

            // setTokenError("invalid token");

            setDisable(true)
        }
    })


    return (
        <>
            <LoginContainer
                //image={'img/login-agency.png'}
                animationData={RepresentativeAnimation}
                formTitle={'Welcome back,'}
                formDescription={'Mauris eu nisl vitae odio blandit bibendum.' +
                    'Class aptent taciti sociosqu ad litora torquent per conubia nostra, ' +
                    'per inceptos himenaeos. Nam faucibus lectus sit amet.'}
            >
                <Formik
                    validateOnBlur
                    initialValues={{
                        current_id: '',
                        token: '',
                    }}
                    // validationSchema={emailLoginSchema}
                    onSubmit={handleFormikSubmit(Login)}
                >
                    {({ isValid, isSubmitting, status, values }) => (
                        <Form method="post">
                            <div className="form-block n-border">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <Input type="text" name="current_id" placeholder="Representative ID" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">

                                            <Input type="text" name="token" placeholder="OTP">
                                                <button disabled={disable || isSubmitting} onClick={() => {
                                                    mutation.mutate({ current_id: values['current_id'] })
                                                }} type="button" className="token_submit">Get OTP</button>
                                            </Input>
                                            {!!tokenError && !isSubmitting && <small className={"text-danger"}>{tokenError}</small>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="d-flex align-items-center mt-2">
                                <label className="mb-0 checkbox-label">
                                    By Signing in you agree on our
                                    <a href="#terms-popup" data-mfp-src="#terms-popup" className="open-popup-link">
                                        Terms & Conditions
                                    </a>.
                                </label>
                            </div> */}
                            {/* <AcceptTermsCondition /> */}
                            <FormikRecaptcha />
                            <div className="col-md-12">
                                <motion.button
                                    className={status == 200 ? "btn_submit_dark btn btn-success" : "btn_submit_dark"}
                                    type={"submit"}
                                    whileTap={{ scale: 0.7 }}
                                    onHoverStart={e => { }}
                                    onHoverEnd={e => { }}
                                    disabled={isSubmitting}
                                >  {status == 200 ? "Success" : (isSubmitting ? "Please wait..." : "Submit")}
                                </motion.button>
                            </div>
                        </Form>
                    )}
                </Formik>

                <div className="">

                    <h3 className="account-text">I have an account, <Link to="/representative-signup"><b>Sign
                        up</b></Link></h3>
                </div>
            </LoginContainer>

        </>
    )
}
