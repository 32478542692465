import React from 'react'
import {DashboardList} from "./DashboardList";
import Lottie from "lottie-react";
import * as LeftData from "../../assets/animation/AbstractDotsBgLeft.json";
import * as rightData from "../../assets/animation/AbstractDotsBgRight.json";
import FooterImage from "../Common/FooterImage";
import {ContactForm} from "../Common/ContactForm";
import {useAuth} from "../../app/auth";
import {AvailabilityModal} from "../NurseUser/General/AvailabilityModal";
export function Dashboard({children}){
    let {showModal} = useAuth();

    return (<>
        {showModal  && showModal == "availability"&& <AvailabilityModal/> }

        <div className="sigma_banner_inner" style={{
            background: "#f7f9fc"
        }}>
            <div className="animation-left">
                <Lottie animationData={LeftData} />
            </div>
            <div className="animation-right">
                <Lottie animationData={rightData} />
            </div>
            <div className="sigma_subheader_inner style-5">
                <div className="container">
                    <div className="row">
                        {/*<AvailabilityModal/>*/}

                        <DashboardList/>
                        {children}
                    </div>
                </div>

            </div>

        </div>
        <FooterImage />
        <ContactForm />

    </>)
}
