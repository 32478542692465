import React, {useEffect} from "react"
import {
    AcceptTermsCondition, AlertFormik,
    Countries,
    CountryCode,
    Date,
    Gender,
    Input,
    Password
} from "../../../Helpers/formComponents";
import {useFormikContext} from "formik";
import {handleRequest} from "../../../Helpers/formHelper";



export function Step1({setStep,countries,submitForm}){

    const {getFieldProps} = useFormikContext();

    useEffect(function (){
        window.scrollTo({top: 130, left: 0, behavior: 'smooth'});
    },[]);
    return (
        <>
            <div className="form-block n-border">
                <div className="row">
                    <div className="col-lg-12">
                    </div>
                    <div className="col-lg-6">
                        <Input type="text"   name="first_name" placeholder="First Name (Official)"  />
                    </div>
                    <div className="col-lg-6">
                        <Input type="text"    name="last_name" placeholder="Last Name (Official) "  />

                    </div>
                    <div className="col-lg-12">
                        <Gender  name="gender" />
                    </div>
                    <div className="col-lg-12">
                        <Date  label="Date of birth (Optional)" name={'date_of_birth'}/>

                    </div>
                    <div className="col-lg-12">
                        <Input  type="email"   name="email" placeholder="E-Mail"  />

                    </div>
                    <div className="col-lg-4">
                        <CountryCode options={countries?.data?.data } name={"country_code"}/>
                    </div>
                    <div className="col-lg-8">
                        <Input  type="tel"  name="mobile" placeholder="Contact Number"   />
                    </div>
                    <div className="col-lg-12">
                        <Countries options={countries?.data?.data } name={"country_id"} />
                    </div>
                    <div className="col-lg-12">
                        <Password  name="password" placeholder="Password"   />
                    </div>
                </div>
            </div>
            <AcceptTermsCondition/>
            <div className="col-md-12">
                <a className="btn_submit_dark" href={"javascript:;"}
                   onClick={() => {
                      let  t = submitForm();
                   }}
                >Continue</a>
            </div>
        </>
    )
}
