import React, { useEffect, useState } from "react";

const Clock = ({ deadline }) => {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const leading0 = (num) => {
        return num < 10 ? "0" + num : num;
    };

    const getTimeUntil = (deadline) => {
        const time = Date.parse(deadline) - Date.parse(new Date());
        if (time < 0) {
            setDays(0);
            setHours(0);
            setMinutes(0);
            setSeconds(0);
        } else {
            setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
            setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
            setMinutes(Math.floor((time / 1000 / 60) % 60));
            setSeconds(Math.floor((time / 1000) % 60));
        }
    };

    useEffect(() => {
        setInterval(() => getTimeUntil(deadline), 1000);

        return () => getTimeUntil(deadline);
    }, [deadline]);

    return (
        <div>
            <ul className="count-reminder">
                <li className="Clock-days">
                    <p className="days number">{leading0(days)}</p>
                    <p className="timeRefDays">Days</p>
                </li>
                <li className="Clock-hours">
                    <p className="hours number">{leading0(hours)}</p>
                    <p className="timeRefHours">Hours</p>
                </li>
                <li className="Clock-minutes">
                    <p className="minutes number">{leading0(minutes)}</p>
                    <p className="timeRefMinutes">MINUTES</p>
                </li>
                <li className="Clock-seconds">
                    <p className="seconds number">{leading0(seconds)}</p>
                    <p className="timeRefSeconds">SECONDS</p>
                </li>
            </ul>
            {/*<div className="Clock-days">{leading0(days)} Days</div>*/}
            {/*<div className="Clock-hours">{leading0(hours)} Hours</div>*/}
            {/*<div className="Clock-minutes">{leading0(minutes)} Minutes</div>*/}
            {/*<div className="Clock-seconds">{leading0(seconds)} Seconds</div>*/}
        </div>
    );
};

export default Clock;
