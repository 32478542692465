import React from "react";
export function TestimonialCard({ name, image, rating, description, type }) {
    return (

        <div className="sigma_service style-16" >
            <div className="sigma_service-thumb">
                <img alt='img' src={image} />
            </div>
            <div className="sigma_service-body">
                <div className="sigma_rating">
                    <Rating rating={rating} />
                </div>
                <div className="text-left">
                    <img alt='img' src="img/quotes.svg" />
                </div>
                <p className="main-color-dark">{description}</p>
                <div className="text-right">
                    <img alt='img' src="img/quotes-down.svg" />
                </div>
                <h5>
                    {name}
                </h5>
                <h6 className="paragraph-grey">{type}</h6>
            </div>
        </div>

    )
}
export const Rating = ({ rating }) => {
    let td = [];
    
        // td.push(<i className="fas fa-star-o"></i>);

    for (let i = 1; i <= rating; i++) {
        td.push(<i key={i} className="fas fa-star active"></i>);
    }
    if (rating - Math.floor(rating) > 0) {
        td.push(<i className="fas fa-star-half-alt active"></i>);
    }
    


    
    for (let i = Math.ceil(rating); i < 5; i++) {
        td.push(<i key={i} className="fas fa-star" style={{color:  "#6B7280"}}></i>);
    }
    return td;
};