import * as yup from "yup";

export let agencySchema = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email : yup.string().email().required(),
    country_code : yup.string().required("Country code is required"),
    mobile : yup.number().required(),
    // allowed_to_sponsor: yup.mixed().oneOf(['1', '0']).required("allowed to sponsor is required"),
    company_name : yup.string().required("Company name is required"),
    company_country_id : yup.string().required("Country is required").nullable(),
    company_address : yup.string().required("Company address is required").nullable(),
    // company_city : yup.string().required("Company city is required"),
    // company_state : yup.string().required("Company state is required"),
    // company_zip_code : yup.string().required("Company zip code is required"),
    contact_number : yup.number().required("Contact number is required"),
    // company_id_no : yup.number("Company id number must be a number").required("Company id number is required"),
    image : yup.mixed().required(),
});

export let messageSchema = yup.object().shape({
    message: yup.string().required(),
});


