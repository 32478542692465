import React, {useEffect} from "react";
import {TestimonialCard} from "../../Common/TestimonialCard";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.min';
export function Testimonials ({data}){
    useEffect(() => {

        let $ = require('jquery');

        $(".sigma_service-slider").slick({
            slidesToShow: 3,
            centerPadding: '90px',
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            autoplay: true,
            centerMode: true,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        arrows: false,
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        arrows: false,
                        slidesToShow: 1
                    }
                }
            ]
        });

    }, []);
    return (

        <div className="section section-padding">
            <div>
                <div className="section-title centered">
                    <h3 className="sigma_testimonial_title">
                    What are our users<br/>
                        <span className="main-color"> saying about us? </span>
                    </h3>
                </div>
                <div className="sigma_service-slider">
                    {data.testimonials.map((testimonial, index) => {
                        return <TestimonialCard
                            rating={testimonial.rating_stars}
                            description={testimonial.review}
                            name={testimonial.user?.full_name}
                            image={testimonial.user?.image}
                            type={testimonial.user?.type}
                        />
                    })}
                </div>

            </div>
        </div>

    );
}