import axios from "axios";

export function insertNewsletter(data) {
    return axios.post("newsletter", data)
}

export function insertContactUs(data) {
    // let fullName;
    // if(data?.name){
    //      fullName = data.name +' '+data.surname 
    // }else{
    //     fullName = data.surname 
    // }
    return axios.post("contact_us", data)
}
export function inviteFriend(data) {
    return axios.post("invite_friends", data)
}
export function withdrawMoney(data) {
    return axios.post("handleCallback", data)
}


export function getReferalData() {
    return axios.get("getReferalData")
}
export function getTransactions(pageParam) {
    return axios.get('getTransactions?page=' + pageParam)
}


export function getCountries() {
    return axios.get("country")
}
export function docusignConnect() {
    return axios.get('docusign-connect').then((data) => {
        window.location.href = data.data.data.url
    }).catch(() => {
        console.log("error")

    });
}



export function docusignCodeVerification(code) {
    return axios.get("docusign/callback?code=" + code)
}

export function docusignAcceptAndDecline(status, agency_id) {
    return axios.post("docusign/accept-and-decline?", { status, agency_id })
}


export function getFooter() {

    return axios.get("settings", {
        params: {
            "module_name": ["Footer"]
        }
    })
}


