import React from "react";

export function Banner({search,setSearch}) {
    let [searchValue, setSearchValue] = React.useState(search)
    const onFormSubmit = e => {
        e.preventDefault();
        setSearch(searchValue)
        // send state to server with e.g. `window.fetch`
    }
    return (<div className="row">
        <h3 className="sigma_testimonial_title">Specialities</h3>
        <div className="col-md-6 offset-3">
            <div className="search-box">
                <form onSubmit={onFormSubmit}>
                    <input type="text" value={searchValue} name="search" placeholder="Search"
                    onChange={(e)=> setSearchValue(e.target.value)}
                    />
                    <button type="button" onClick={(e)=> setSearch(searchValue)} className="search-btn">
                        <i className="fal fa-search m-0"></i>
                    </button>
                </form>
            </div>
        </div>
    </div>)
}