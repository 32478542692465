import React from "react";
import {TitleLeftBanner} from "./TitleLeftBanner";
import {BannerContainer} from "./BannerContainer";
import {ContactForm} from "./ContactForm";
import Lottie from "lottie-react";

export function SignUpContainer({children,image,formTitle,formDescription,formSpan,animationData}) {
    return (
       <>
           <BannerContainer
               style={{
                   backgroundPosition: "right",
                   backgroundImage:"url(img/GradientBackground.png)",
               }}>
            <div className="row">
                <div className="col-md-6 offset-md-3 text-center">
                    <TitleLeftBanner
                        title={formTitle}
                        subTitle={formSpan}
                        description={formDescription}
                    />
                </div>
                <div className="col-md-6">
                    {children}
                </div>
                <div className="col-lg-6 col-md-6 text-center">
                    <Lottie animationData={animationData} style={{height:"540px"}} />
                </div>
            </div>
        </BannerContainer>
        <ContactForm/>
       </>
    )
}

