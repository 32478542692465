import React, { useEffect, useState } from 'react';
import { TestimonialCard } from '../Common/TestimonialCard';
import { TitleOnlyBanner } from '../Common/TitleOnlyBanner';
import { getRandomTestimonials, getTestimonialsSettings } from '../../adapters/TestimonialsAdapter';
import Slider from 'react-slick';
import { useInfiniteQuery, useQuery } from "react-query";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.min';

export default function Testimonials() {
    const { isLoading: isLoading1, isSuccess: isSuccess1, data: testimonials } = useQuery('testimonialsddd', getRandomTestimonials,
        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });
    const { data: setting } = useQuery('testimonials-setting', getTestimonialsSettings,
        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });
    useEffect(() => {

        let $ = require('jquery');

        $(".sigma_service-slider").slick({
            slidesToShow: 3,
            centerPadding: '90px',
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            autoplay: true,
            centerMode: true,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        arrows: false,
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        arrows: false,
                        slidesToShow: 1
                    }
                }
            ]
        });

    }, []);

    return (
        <>

            <div className="section section-padding">
                <div>
                    <div className="section-title centered">
                        <h3 className="sigma_testimonial_title">
                            <div dangerouslySetInnerHTML={{ __html: setting?.data.data.OurTestimonials?.Description }} /><br />
                            <span className="main-color"></span>
                        </h3>
                    </div>
                    <div className="sigma_service-slider">
                        {testimonials?.data.data.map((testimonial, index) => {
                            return <TestimonialCard
                                rating={testimonial.rating_stars}
                                description={testimonial.review}
                                name={testimonial.user?.full_name}
                                image={testimonial.user?.image}
                                type={testimonial.user?.type}
                            />
                        })}
                    </div>
                </div>
            </div>

        </>
    );
}






