import React from 'react';
import Guest from "./Guest";
import User from "./User";
import {useAuth} from "../../app/auth";


export default function Testimonials() {

    const {currentUser} = useAuth();
    return (
        currentUser ? <User/> : <Guest/>

    )
}
