
import React, { useEffect } from 'react';
import {Banner} from "./Banner";
import {Main} from "./Main";
import {homeTransformer} from "../../adapters/HomeAdapter";
import {useQuery} from "react-query";
import {ContactForm} from "../Common/ContactForm";
import {Newsletter} from "../Common/Newsletter";
import {useLocation} from 'react-router-dom';
import Modal from "react-modal";


export  function Home() {
    const location = useLocation();
    const [modalIsOpen, setIsOpen] = React.useState(true);

    const { data } = useQuery('home', homeTransformer ,
        {
            cacheTime : 1000 * 60 * 60 * 24,
            staleTime : 1000 * 60 * 60 * 12,
        });
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                maxWidth: "800px",
                minWidth: "320px",
        
                transform: 'translate(-50%, -50%)',
                backgroundColor:"#fff",
        
            },
            overlay: {
                zIndex: 100,
                backgroundColor: 'rgba(70, 70, 70, 0.5)',
        
            }
        
        };

        useEffect(function(){

            if(modalIsOpen && location?.state?.message){
                window.scrollTo(0, 0)

            }
        },[modalIsOpen])
        function openModal() {
            setIsOpen(true);
        }
        function closeModal() {
            setIsOpen(false);
        }

    return (
        <>

            { data &&
            <>
                <Banner data={data.settings.HeroSection} />
                <Main
                    counter={data.settings.Counter}
                    sectionOne={data.settings.WhyMedicStaff}
                    sectionTwo={data.settings.HiringIsVeryEasy}
                    ourTestimonials={data.settings.OurTestimonials}
                    testimonials={data.testimonials}
                />
                <Newsletter/>
                <ContactForm/>
                {location?.state?.message &&

                
                <Modal
                backdropColor="transparent"
                shouldCloseOnOverlayClick={true}
                isOpen={modalIsOpen}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {/*<div id="ask-popup" className="white-popup-2 mfp-hide">*/}
                <button title="" type="button" className="mfp-close" onClick={()=>setIsOpen(false)}>×</button>
                <br></br>
                <div className="" style={{ textAlign: 'center' }}>
                    <h4 className="popup-title">Thanks For Joining Us</h4>
                        <h6 className="paragraph-text">
                                {location?.state?.message}
                        </h6>
                </div>
            </Modal>
}   
            </>}
        </>
    );
}

