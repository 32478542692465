import React from "react";

export function InterviewSectionDisabled(){
    return    <div className="calendar-tab border-top-dashed disabled">
        <div className="d-flex">
            <div className="calendar-list">
                            <span>
                                2
                            </span>
            </div>
            <div className="calendar-flex">
                <h3 className="tab-title">Interview</h3>
                <p>
                    Keep track of scheduled interviews and manage them effortlessly with just a few clicks.
                </p>
            </div>
        </div>
    </div>


}