import React, { useEffect } from 'react';
import { Newsletter } from "../Common/Newsletter";
import { BannerContainer } from "../Common/BannerContainer";
import { handleFormikSubmit } from "../../Helpers/formHelper";
import { Formik, Form } from "formik";
import { insertContactUs } from "../../adapters/commonAdapter";
import { FormikRecaptcha, Input, TextArea } from "../../Helpers/formComponents";
import { contactSchema } from "../../schemas/contactSchema";
import { motion } from "framer-motion";
import * as animationData from '../../assets/animation/Contact.json';
import Lottie from "lottie-react";
import * as LeftData from "../../assets/animation/AbstractDotsBgLeft.json";
import * as rightData from "../../assets/animation/AbstractDotsBgRight.json";
import { getContactUsSettings } from '../../adapters/ForumsAdapter';
import { useQuery } from "react-query";

export default function Contact() {
    const { data: setting } = useQuery('booking-setting', getContactUsSettings,
        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });
return (
        <>
            <BannerContainer style={{
                background: "#fff"
            }}>
                <div className="animation-left">
                    <Lottie animationData={LeftData} />
                </div>
                <div className="animation-right">
                    <Lottie animationData={rightData} />
                </div>
                <div className="row">
                    <div className="col-md-6 offset-3 text-center">
                        <h2>Contact Us</h2>
                        <Lottie animationData={animationData} style={{ height: "541px" }} className="mobile-img" />
                        <p className="paragraph-text">
                        <div dangerouslySetInnerHTML={{ __html: setting?.data?.data?.ContactUs?.Contact_Us }} />
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <Formik
                            validateOnBlur
                            initialValues={{
                                surname: '',
                                email: '',
                                description: '',
                            }}
                            validationSchema={contactSchema}
                            onSubmit={handleFormikSubmit(insertContactUs)}
                        >
                            {({ isValid, isSubmitting, status }) => (

                                <Form method="post">
                                    <div className="form-block n-border contact-form">
                                        <div className="row">

                                            <div className="col-lg-12">
                                                <Input type="text" name="surname" placeholder="Surname (official)" />
                                            </div>

                                            <div className="col-lg-12">
                                                <Input type="email" name="email" placeholder="Email" />
                                            </div>
                                            {/*<div className="col-lg-12">*/}
                                            {/*    <div className="form-group">*/}
                                            {/*        <select className="custom-select">*/}
                                            {/*            <option value="1" selected disabled>How can we help you?</option>*/}
                                            {/*            <option value="2">option</option>*/}
                                            {/*            <option value="3">option</option>*/}
                                            {/*            <option value="4">option</option>*/}
                                            {/*        </select>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="col-lg-12">
                                                <TextArea name={"description"} placeholder="Message" />

                                            </div>
                                        </div>
                                    </div>
                                    <FormikRecaptcha />

                                    <div className="col-md-12">
                                        <motion.button
                                            className={status == 200 ? "btn_submit_dark btn btn-success" : "btn_submit_dark"}
                                            type={"submit"}
                                            whileTap={{ scale: 0.7 }}
                                            onHoverStart={e => { }}
                                            onHoverEnd={e => { }}
                                            disabled={isSubmitting}
                                        >  {status == 200 ? "Your Form Has Been Submitted" : (isSubmitting ? "Please wait..." : "Submit")}
                                        </motion.button>
                                    </div>
                                </Form>
                            )}
                        </Formik>

                    </div>

                    <div className="col-lg-6 col-md-6 text-center">
                        <Lottie animationData={animationData} style={{ height: "541px", marginTop: "-43px" }} className="desktop-img" />
                        {/*<img src="img/contact-img.png" alt="img" height="541"/>*/}
                    </div>
                </div>
            </BannerContainer>
            <Newsletter />
        </>
    )
}
