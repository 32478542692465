import React from "react";


export function CalendarSectionDone(){
    return    <div className="calendar-tab">
        <div className="d-flex">
                    <span className="approved-check">
                        <i className="far fa-check"></i>
                    </span>
            <div className="calendar-list">
                        <span>
                            1
                        </span>
            </div>
            <div className="calendar-flex">
                <h3 className="tab-title">Calendar</h3>
                <p>
                    Donec in est eu dui venenatis dapibus. Aenean non turpis suscipit,
                    consequat dolor a,venenatis.Donec in est eu dui venenatis dapibus.
                </p>
            </div>
        </div>
    </div>


}