import React, {useEffect, useState} from 'react';
import {SignUpContainer} from "../../Common/SignUpContainer";
import {Step1} from "./Step1";
import {Step2} from "./Step2";
import {Step3} from "./Step3";
import {handleFormikSubmit} from "../../../Helpers/formHelper";
import {Form, Formik, useFormikContext} from "formik";
import {StepsBar} from "./StepsBar";
import {useQuery} from "react-query";
import {getCountries} from "../../../adapters/commonAdapter";
import {getStatesLicensure} from "../../../adapters/ForumsAdapter";
import {nurseSignUpAdapter} from "../../../adapters/nurseAdapter";
import * as animationData from '../../../assets/animation/Apply 1.json';
import { step1Schema, step2Schema, step3Schema} from "../../../schemas/nurseWithNclexSchema";

import * as yup from 'yup';

export  default function NursesSignUp() {


    const [step,setStep] = useState(1)
    const [validationSchema,setValidationSchema] = useState(1)
    const { isFetching, data:countries } = useQuery('country', getCountries ,
        {
            cacheTime : 1000 * 60 * 60 * 24,
            staleTime : 1000 * 60 * 60 * 12,
        });

    const { data:states_data } = useQuery('states-data', getStatesLicensure ,
        {
            cacheTime : 1000 * 60 * 60 * 24,
            staleTime : 1000 * 60 * 60 * 12,
        });


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
   useEffect(() => {
        if(step == 1){
            setValidationSchema(step1Schema)
        }
        if(step == 2){
                setValidationSchema(step2Schema)
        }
        if(step == 3){
            setValidationSchema(step3Schema)
        }
       }, [step])

    return (
        <>
            <SignUpContainer
                animationData={animationData}
                formTitle={'Apply Form'}
                style={{
                    backgroundPosition: "top",
                }}
                formSpan={'Apply Form Nurses with NCLEX-RN'}
                formDescription={'We ensure your privacy and guarantee that the information you enter in the registration form is kept safe and secure with us.'}
            >

                    <Formik
                        validateOnChange={false} // Disable validate on change
                        validateOnMount={false}
                        validateOnBlur={false}
                        initialValues={{
                            first_name: '',
                            last_name: '',
                            gender: '',
                            date_of_birth: '',
                            email: '',
                            country_id: '',
                            country_code: '',
                            password: '',
                            mobile: '',


                            rn_license_number: '',
                            issuing_state_id: '',
                            expiry_date: '',
                            speciality: '',
                            years_of_experience: '',
                            referred_by_friend: '',
                            unique_referral_number: '',
                            document: null,
                            resume: null,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormikSubmit(nurseSignUpAdapter,function(errors,setErrors,setStatus){
                            if(errors && step==1) {

                                //if array of errors has keys equal to a value of a given array of keys
                                let step1Errors = [
                                    "first_name", "last_name", "gender", "date_of_birth",
                                    "email", "country_id", "country_code", "password", "mobile"
                                ];
                                const hasKey = Object.keys(errors).some(key => step1Errors.includes(key));

                                if (!hasKey) {

                                    setStep(2)
                                    setStatus(null)
                                    setErrors(null)

                                }
                            }
                            if(errors && step==2) {

                                //if array of errors has keys equal to a value of a given array of keys
                                let step2Errors = [

                                    "rn_license_number", "issuing_state_id", "expiry_date"
                                ];
                                const hasKey = Object.keys(errors).some(key => step2Errors.includes(key));

                                if (!hasKey) {

                                    setStep(3)
                                    setStatus(null)
                                    setErrors(null)

                                }
                            }
                        })}
                    >
                        {({isValid, isSubmitting , status,validateForm,errors,setErrors,submitForm}) => {




                            return (
                            <Form method="post">
                                {step == 1 && <Step1 submitForm={submitForm}   countries={countries} setStep={setStep}/>}
                                {step == 2 && <Step2 submitForm={submitForm} states_data={states_data} setStep={setStep}/>}
                                {step == 3 && <Step3 setStep={setStep} isSubmitting={isSubmitting} status={status}/>}
                                <StepsBar step={step} setStep={setStep}/>
                            </Form>
                        )}}
                    </Formik>


            </SignUpContainer>
        </>
    );
}



