import React from 'react';
import {Counter} from "./Counter";
import {SectionOne} from "./SectionOne";
import {SectionTwo} from "./SectionTwo";
import {Testimonials} from "./Testimonials";
import Lottie from "lottie-react";
import * as animationData from '../../../assets/animation/AbstractBgShape.json'

export  function Main({counter, sectionOne, sectionTwo,ourTestimonials, testimonials}) {

    return (

        <div className="main-home fix-main"
                 style={{backgroundImage: "url(img/section-bg.png), linear-gradient(rgb(229 233 240) 53%, rgb(255, 255, 255) 84%)",
        }}>
            <div className="animation-bubble">
                <Lottie animationData={animationData} style={{height:"541px"}}/>
            </div>
            <Counter data={counter}/>
            <SectionOne data={sectionOne}/>
            <SectionTwo data={sectionTwo}/>
            <Testimonials data={{ourTestimonials, testimonials}}/>

        </div>
    );
}

