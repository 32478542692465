import React from "react";
import AgencyMap from './AgencyMap';
export function Map({markers}){


    return (


    <div className="col-md-12">
        <div className="hotel-map">
            <AgencyMap search={false} markers={markers} />


        </div>
    </div>)
}