import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";
import { Field, useField, useFormikContext } from "formik";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { MutationAlert } from "../Components/Common/MutationAlert";
import ReCAPTCHA from 'react-google-recaptcha';


const useStyles = makeStyles(() => ({
    root: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1e2938',

        },
    },
}));

function hasError(props) {
    return props.errors && props.errors[props.name] && props.touched[props.name];
}

export function Input ({children,errorShow=true,...props}){
    const {errors ,touched} = useFormikContext();
    let isError = hasError({ errors, touched, name: props.name });
    // console.log(isError);
    return <div className="form-group">
        <Field {...props} />
        {children}
        {errorShow && isError ? <small className={"text-danger"}>{errors[props.name]}</small> : null}
    </div>


}
export function Password(props) {
    const { setFieldValue, errors, touched } = useFormikContext();
    const [type, setType] = useState("password")
    let isError = hasError({ errors, touched, name: props.name });

    function togglePassword() {
        console.log(type);
        if (type === 'password') {
            setType("text")
        } else {
            setType("password")
        }
    }

    // console.log(isError);
    return <div className="form-group">
        <Field id="txtPassword"
            autoComplete='off'
            {...props}
            type={type}
            onChange={e => {
                setFieldValue(props.name, e.target.value)
                setFieldValue(props.name + "_confirmation", e.target.value)
            }}
        />
        <button type="button" onClick={togglePassword} id="btnToggle" className="toggle">
            <i id="eyeIcon" className={type == "password" ? "far fa-eye" : "far fa-eye fa-eye-slash"}></i>
        </button>
        {isError ? <small className={"text-danger"} >{errors[props.name]}</small> : null}
    </div>


}
export function AcceptTermsCondition() {
    return <>
        <div className="d-flex align-items-center mt-4">
            {/*<input type="checkbox" id="checkbox" name="checkbox"/>*/}
            <label className="mb-0 checkbox-label" htmlFor="checkbox">
                By submitting form you agree to our
                <Link to={"/terms"} className="open-popup-link">
                     Privacy Policy, Terms & Conditions.
                </Link>.
            </label>
        </div>

        {/*<div className="d-flex align-items-center mt-4">*/}
        {/*    <input type="checkbox" id="checkbox" name="checkbox"/>*/}
        {/*    <label className="mb-0 checkbox-label" htmlFor="checkbox">*/}
        {/*        I hereby certify that all of the information provided by me in this application is correct, accurate and complete to the best of my knowledge*/}
        {/*    </label>*/}
        {/*</div>*/}
    </>
}
export function FormikRecaptcha(props) {
    const [isVerified, setIsVerified] = useState(false);

    const { setFieldValue, values, errors, touched } = useFormikContext()
    let isError = errors && errors['g-recaptcha-response']
    console.log(props)
    const handleVerify = (props) => {
        setIsVerified(true);
        setFieldValue('g-recaptcha-response', props)
    };
    return <>
        <div className="mt-2">

            <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA}
                onChange={handleVerify}
            />
        </div>

        {isError ? <small className={"text-danger"}>{errors['g-recaptcha-response']}</small> : null}

    </>
}



export function FormikCheckBox(props) {
    const { setFieldValue, values, errors, touched } = useFormikContext()
    // console.log(errors);
    let isError = hasError({ errors, touched, name: props.name });
    // console.log(isError);

    return <>
        <div className={props.className ? props.className : "d-flex align-items-center mt-2"}>
            <input checked={values[props.name]} type="checkbox" {...props} id={props.name} />
            <label className="mb-0 checkbox-label"
                htmlFor={props.name}
                onClick={(event) => {
                    setFieldValue(props.name, !values[props.name] ? 1 : 0)

                }}
            >{props.label}</label>
        </div>
        {isError ? <small className={"text-danger"}>{errors[props.name]}</small> : null}

    </>
}
export function AlertFormik(props) {

    const { errors } = useFormikContext();
    console.log(errors);
    if (Object.keys(errors).length > 0) {
        return (
            <MutationAlert AlertClass={"alert-danger"} message={"Please fix the form errors"} />
        )
    } else {
        return <></>
    }


}
export function Date(props) {
    const { setFieldValue, values, errors, touched } = useFormikContext()
    let isError = hasError({ errors, touched, name: props.name });

    return <div className="form-group">

        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                {...props}
                value={values[props.name]}
                onChange={e => setFieldValue(props.name, e?.format('YYYY-MM-DD').toString())}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
        {isError ? <small className={"text-danger"}>{errors[props.name]}</small> : null}

    </div>

}
export function Gender({ ...props }) {
    const { errors, touched } = useFormikContext()
    let isError = hasError({ errors, touched, name: props.name });

    return <div className="form-group">
        <div className="radio-btn">
            <h4 className="mb-0 me-4 left">Gender (Optional)</h4>
            <div className="right d-flex">
                <div className="d-flex align-items-center">
                    <Field type="radio" id="radio" {...props} value="male" />
                    <label className="mb-0" htmlFor="radio">Male</label>
                </div>
                <div className="d-flex align-items-center ms-4">
                    <Field type="radio" id="radio2" {...props} value="female" />
                    <label className="mb-0" htmlFor="radio2">Female</label>
                </div>
            </div>

        </div>
        {isError ? <small className={"text-danger"}>{errors[props.name]}</small> : null}

    </div>

}

export function BooleanRadio({ ...props }) {
    const { errors, touched } = useFormikContext()
    let isError = hasError({ errors, touched, name: props.name });
    const id = Math.random().toString(36).substring(2);
    return <div className="form-group">
        <div className="radio-btn">
            <h4 className="mb-0 me-4 left">{props.title}</h4>
            <div className="right d-flex">
                <div className="d-flex align-items-center">
                    <Field type="radio" id={id} {...props} value="1" />
                    <label className="mb-0" htmlFor={id}>Yes</label>
                </div>
                <div className="d-flex align-items-center ms-4">
                    <Field type="radio" id={id + 1} {...props} value="0" />
                    <label className="mb-0" htmlFor={id + 1}>No</label>
                </div>
            </div>

        </div>
        {isError ? <small className={"text-danger"}>{errors[props.name]}</small> : null}

    </div>

}


export function AllowedToSponsor({ ...props }) {
    const { errors, touched } = useFormikContext()
    let isError = hasError({ errors, touched, name: props.name });

    return <div className="form-block">
        <div className="radio-btn">
            <h4 className="mb-0 me-4 left">Are you allowed to sponsor international nurses</h4>
            <div className="right d-flex">
                <div className="d-flex align-items-center">
                    <Field type="radio" id="radio" {...props} value="1" />
                    <label className="mb-0" htmlFor="radio">Yes</label>
                </div>
                <div className="d-flex align-items-center ms-4">
                    <Field type="radio" id="radio2" {...props} value="0" />
                    <label className="mb-0" htmlFor="radio2">No</label>
                </div>
            </div>

        </div>
        {isError ? <small className={"text-danger"}>{errors[props.name]}</small> : null}

    </div>

}


export function Boolean({ ...props }) {
    const { errors, touched } = useFormikContext()
    let isError = hasError({ errors, touched, name: props.name });

    return <div className="form-block">
        <div className="radio-btn">
            <h4 className="mb-0 me-4 left">{props.label}</h4>
            <div className="right d-flex">
                <div className="d-flex align-items-center">
                    <Field type="radio" id="radio" {...props} value="1" />
                    <label className="mb-0" htmlFor="radio">Yes</label>
                </div>
                <div className="d-flex align-items-center ms-4">
                    <Field type="radio" id="radio2" {...props} value="0" />
                    <label className="mb-0" htmlFor="radio2">No</label>
                </div>
            </div>

        </div>
        {isError ? <small className={"text-danger"}>{errors[props.name]}</small> : null}

    </div>

}

export function Countries({ ...props }) {
    const { setFieldValue, values, errors, touched } = useFormikContext()
    let [refreshSelect, setRefreshSelect] = useState(false)

    let isError = hasError({ errors, touched, name: props.name });
    const useStyles = makeStyles({
        option: {
            "&:hover": {
                backgroundColor: "#ee4b5c !important",
                color: "White !important"
            }
        }
    });
    const styles = useStyles();

    useEffect(function () {
        let result = props.options.find(obj => {

            return obj.id === values[props.name]
        })

        if (result?.id && values[props.name + "_object"]?.id !== result.phonecode) {
            console.log(values[props.name + "_object"], result);

            setFieldValue(props.name, result.id)
            setFieldValue(props.name + "_object", result)
        }
        setRefreshSelect(!refreshSelect)


    }, [])
    return refreshSelect && <div className="form-group">
        <Autocomplete
            {...props}
            disablePortal
            getOptionLabel={(option) => {
                return option.name
            }}
            classes={{
                option: styles.option
            }}
            renderInput={(params) => {
                params.inputProps.autoComplete = 'new-password';

                return <TextField  {...params} label="Country" />

            }}
            value={values[props.name + "_object"]}
            onChange={(e, value) => {
                setFieldValue(props.name, value.id)
                setFieldValue(props.name + "_object", value)
            }
            }

        />
        {isError ? <small className={"text-danger"}>{errors[props.name]}</small> : null}

    </div>


}


export function States({ ...props }) {
    const { setFieldValue, values, errors, touched } = useFormikContext()
    let [refreshSelect, setRefreshSelect] = useState(false)

    let isError = hasError({ errors, touched, name: props.name });
    const useStyles = makeStyles({
        option: {
            "&:hover": {
                backgroundColor: "#ee4b5c !important",
                color: "White !important"
            }
        }
    });
    const styles = useStyles();

    useEffect(function () {
        let result = props.options.find(obj => {
            return obj.id === values[props.name]
        })

        if (result?.id && values[props.name + "_object"]?.id !== result.id) {
            console.log(values[props.name + "_object"], result);
            console.log(result.id);
            setFieldValue(props.name, result.id)
            setFieldValue(props.name + "_object", result)
        }
        setRefreshSelect(!refreshSelect)


    }, [])
    return refreshSelect && <div className="form-group">
        <Autocomplete
            {...props}
            disablePortal

            getOptionLabel={(option) => {
                return option.title_en
            }}
            classes={{
                option: styles.option
            }}
            renderInput={(params) => {
                params.inputProps.autoComplete = 'new-password';

                return <TextField  {...params} label={props.label} />

            }}
            value={values[props.name + "_object"]}
            onChange={(e, value) => {
                setFieldValue(props.name, value.id)
                setFieldValue(props.name + "_object", value)
            }
            }

        />
        {isError ? <small className={"text-danger"}>{errors[props.name]}</small> : null}

    </div>


}
export function CountryCode({ ...props }) {
    const { setFieldValue, values, errors, touched } = useFormikContext()
    let isError = hasError({ errors, touched, name: props.name });
    let [refreshSelect, setRefreshSelect] = useState(false)
    useEffect(function () {
        let result = props.options.find(obj => {

            return obj.phonecode === values[props.name]
        })

        if (result?.phonecode && values[props.name + "_object"]?.phonecode !== result.phonecode) {
            console.log(values[props.name + "_object"], result);

            setFieldValue(props.name, result.phonecode)
            setFieldValue(props.name + "_object", result)
        }
        setRefreshSelect(!refreshSelect)


    }, [])
    const classes = useStyles();
    return refreshSelect && <div className="form-group">
        <Autocomplete
            {...props}
            disablePortal
            getOptionLabel={(option) => {
                return option.title
            }}
            className={classes.root}
            renderInput={(params) => {
                params.inputProps.autoComplete = 'new-password';

                return <TextField  {...params} label="Country code" />

            }}
            value={values[props.name + "_object"]}
            onChange={(e, value) => {
                setFieldValue(props.name, value.phonecode)
                setFieldValue(props.name + "_object", value)
            }

            }

        />
        {isError ? <small className={"text-danger"}>{errors[props.name]}</small> : null}

    </div>

}
export function File({ ...props }) {
    const { setFieldValue, errors, touched } = useFormikContext()
    const [fileName, setFileName] = useState(props.label);
    let isError = hasError({ errors, touched, name: props.name });
    const MAX_DISPLAY_LENGTH = 20;

    function getDisplayedFileName(fileName) {
      if (fileName.length <= MAX_DISPLAY_LENGTH) {
        return fileName;
      }
      return fileName.substr(0, MAX_DISPLAY_LENGTH) + '...';
    }

    return     <>
      <div className="form-group custom-file">
        <span>{getDisplayedFileName(fileName)}</span>
        <input
          type="file"
          name={props.name}
          onChange={(event) => {
            const file = event.currentTarget.files[0];
            setFileName(file.name);
            setFieldValue(props.name, file);
          }}
        />
      </div>
      {isError ? (
        <small className={"text-danger"}>{errors[props.name]}</small>
      ) : null}
    </>

}
export function Files({ ...props }) {
    const { setFieldValue, errors, touched } = useFormikContext()

    let isError = errors && !!errors[props.name + ".0"];

    return <><div className="form-group custom-file">
        <span>{props.label}</span>
        <input type="file"
            name={props.name}
            multiple={true}

            onChange={(event) => {
                // console.log(event.currentTarget.files);

                setFieldValue(props.name, Array.from(event.currentTarget.files));
            }}
        />

    </div>
        {isError ? <small className={"text-danger"}>{errors[props.name + ".0"]}</small> : null}

    </>

}
export function TextArea({ ...props }) {
    const { setFieldValue, errors, touched } = useFormikContext();
    // console.log(errors);
    let isError = hasError({ errors, touched, name: props.name });
    // console.log(isError);
    console.log(props);
    return <div className="form-group">
        <textarea   {...props}
            onChange={e => setFieldValue(props.name, e.target.value)}

        ></textarea>
        {isError ? <small className={"text-danger"}>{errors[props.name]}</small> : null}
    </div>



}

export function Rating({ ...props }) {
    const { setFieldValue, errors, touched } = useFormikContext();
    // console.log(errors);
    let isError = hasError({ errors, touched, name: props.name });
    // console.log(isError);
    return <div className="form-group">
        <div className="col-md-12 d-flex justify-content-center">
            <div className="rate" role="group" aria-labelledby="my-radio-group">
                <Field type="radio" id={props.name+"_5"} name={props.name} value="5" />
                <label htmlFor={props.name+"_5"} title="5">5 stars</label>
                <Field type="radio" id={props.name+"_4"} name={props.name} value="4" />
                <label htmlFor={props.name+"_4"} title="4">4 stars</label>
                <Field type="radio" id={props.name+"_3"} name={props.name} value="3" />
                <label htmlFor={props.name+"_3"} title="3">3 stars</label>
                <Field type="radio" id={props.name+"_2"} name={props.name} value="2" />
                <label htmlFor={props.name+"_2"} title="2">2 stars</label>
                <Field type="radio" id={props.name+"_1"} name={props.name} value="1" />
                <label htmlFor={props.name+"_1"} title="1">1 star</label>
            </div>

        </div>

        {isError ? <small className={"text-danger"}>{errors[props.name]}</small> : null}
    </div>



}







