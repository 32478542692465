import {AgencyCard} from "./AgencyCard";
import Pagination from "../../../Common/Pagination";
import {useAuth} from "../../../../app/auth";

export function AgencyListing({markers,setMarker,data, isFetching,fetchNextPage,isFetchingNextPage,hasNextPage}){
    return (<>
{/*<MapPin current={1}/>*/}
{/*<MapPin current={0}/>*/}


        <Pagination
            className={"row justify-content-center"}
            data={data}
            isFetching={isFetching}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
        >{(agency) => {

            return <AgencyCard markers={markers} setMarker={setMarker} online={agency.is_online} data={agency} alert={1} rating={agency.rating_avg} offer={1}/>
        }}
        </Pagination>

    </>)

}