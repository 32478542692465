import {motion} from "framer-motion";
import React from "react";

export function handleFormChange(data,setData){
    return function (e,type="normal",name="",value=""){
        let newData;
        let removeValue = false;
        if(type == "normal") {
            name = e.target.name;
            newData = {[name]: e.target.value}
        }else if (type == "datepicker_date"){
            newData = {[name]: e?.format('YYYY-MM-DD').toString()}
        }else if (type == "datepicker_datetime"){
            newData = {[name] : e?.format('YYYY-MM-DD HH:mm:ss').toString()}
        }else if (type == "file"){
            name = e.target.name;

            if(e.target.files[0] == undefined ){
                removeValue = true;
            }else{

                newData = {[name] : e.target.files[0]}
            }
        }else if (type == "select"){
            newData = {[name]: value}

        }

        if(removeValue){
            let filteredData = {...data};
            if(filteredData.hasOwnProperty(name)){
                delete filteredData[name];
            }
            console.log(filteredData);

            setData(filteredData);
        }else{
            setData({...data, ...newData})
            console.log({...data, ...newData});

        }
    }
}

export function handleFormikSubmit(submitRequest,afterSubmitError,afterSubmitSuccess=function (){}){

    return function (data,{setErrors,setStatus,setSubmitting}){
        submitRequest(data).then(function (res){
            handleRequest (res,{setErrors,setStatus,setSubmitting})
            afterSubmitSuccess(res);
        }).catch(function (res){
            const errors = handleRequest (res,{setErrors,setStatus,setSubmitting})
            if(submitRequest.length && afterSubmitError != undefined){
                afterSubmitError(errors,setErrors,setStatus);
            }

        })
    }
}
export function handleRequest (res,{setErrors,setStatus,setSubmitting}){

    res = res.hasOwnProperty('response') ? res.response :res

    if(res.status == 422){
        console.log(res.data.errors);
        // console.log()
        setStatus(422)
        setSubmitting(false)
        setErrors(null);
        setErrors(res.data.errors);
        return res.data.errors;
    }else if(res.status >= 400){
        setStatus(422)
        setSubmitting(false)

    }else if(res.status == 200){
        setStatus(200)

    }
    return [];
}



