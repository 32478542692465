import React from "react";

export  function CardContainer({children}) {
    return (
            <div class="row mt-5">
                <div class="col-md-1"></div>
                    <div class="col-md-10">
                        <div class="row">
                            {children}
                        </div>
                    </div>
                <div class="col-md-1"></div>
            </div>
    )
}













