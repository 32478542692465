import React, {useState} from 'react';
import {Link, Navigate,useParams} from "react-router-dom";



export default function Redirect() {

    const params = useParams();
    console.log(params); // 👉️ {userId: '4200'}

    const referralCode =params.referral_code
    // Set referral cookie with expiration date 30 days from now
    const expirationDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toUTCString();
    document.cookie = `referral=${referralCode}; expires=${expirationDate}; path=/`;


    return (
        <>
            <Navigate to="/nurses-with-rn-signup" replace={true} />
        </>
    );
}
