/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { useAuth } from '../app/auth';
import { App } from '../App';
import { Home } from "../Components/Home";
import Testimonials from "../Components/Testimonials";
import NurseLogin from "../Components/NurseLogin";
import AgencyLogin from "../Components/AgencyLogin";
import RepresentativeLogin from "../Components/RepresentativeLogin";
import Specialities from "../Components/Specialities";
import ELearning from "../Components/Forums/ELearning";
import Sponsorship from "../Components/Forums/Sponsorship";
import AskALawyer from "../Components/Forums/AskALawyer";
import LiveUsa from "../Components/Forums/LiveUsa";
import States from "../Components/Forums/States";
import Booking from "../Components/Booking/booking";
import Hotels from "../Components/Booking/Hotels";
import ApplicantSignUp from "../Components/ApplicantSignUp";
import RepresentativeSignUp from "../Components/RepresentativeSignUp";
import AgencySignUp from "../Components/AgencySignUp";
import SignUpTypes from "../Components/SignUpTypes";
import Flight from "../Components/Booking/Flight";
import Transport from "../Components/Booking/Transport";
import NclexSignUp from "../Components/ApplicantSignUp/NclexSignUp";
import ClincalSignUp from "../Components/ApplicantSignUp/ClincalSignUp";
import Login from "../Components/Login";
import AgencyDashboardCalendarTwo from "../Components/AgencyDashboard/AgencyDashboardCalendarTwo";
import AgencyDashboardCalendarThree from "../Components/AgencyDashboard/AgencyDashboardCalendarThree";
import AgencyDashboardCalendarFinish from "../Components/AgencyDashboard/AgencyDashboardCalendarFinish";
import AgencyDashboardInbox from "../Components/AgencyDashboard/AgencyDashboardInbox";
import UserInbox from "../Components/AgencyDashboard/UserInbox";
import ChooseRepresentative from "../Components/AgencyDashboard/ChooseRepresentative";
import Contact from "../Components/Contact";
import Terms from "../Components/Terms";
import AboutUs from "../Components/AboutUs";
import Error from "../Components/Error";
import NursesSignUp from "../Components/ApplicantSignUp/NursesSignUp";
import ReferralSignUp from "../Components/ReferralSignUp";
import { Main } from "../Components/NurseUser/Dashboard/Main";
import { Availability } from "../Components/NurseUser/Dashboard/Availability";
import { FileSharing } from "../Components/NurseUser/Dashboard/FileSharing";
import { Reminders } from "../Components/NurseUser/Dashboard/Reminders";
import { AccountSettings } from "../Components/NurseUser/Dashboard/AccountSettings";
import { ForgetPassword } from "../Components/ForgetPassword";
import { ResetPasswordForm } from "../Components/ResetPasswordForm";
import AgencyDashboard from "../Components/AgencyDashboard";
import { AgencyDashboardProvider } from "../Components/AgencyDashboard/AgencyDashboardProvider";
import DocusignAccess from "../Components/DocusignAccess";
import AgencyInbox from "../Components/AgencyDashboard/AgencyInbox";
import { AgencyAccountSettings } from "../Components/AgencyDashboard/AgencyAccountSettings";
import { RepresentativeAccountSettings } from "../Components/RepresentativeUser/RepresentativeAccountSettings";
import { RepresentativeDashboard } from "../Components/RepresentativeUser/RepresentativeDashboard";
import { AddRepresentativeCandidates } from "../Components/RepresentativeUser/AddRepresentativeCandidates";
import { EditRepresentativeCandidates } from "../Components/RepresentativeUser/EditRepresentativeCandidates";
import { RepresentativeEditAccount } from "../Components/AgencyDashboard/RepresentativeEditAccount";
import { Inbox } from "../Components/NurseUser/Dashboard/Inbox";
import { AgencyFiles } from "../Components/AgencyDashboard/AgencyFiles";
import Payment from "../Components/Payment";
import Redirect from "../Components/Referral/Redirect";
import { Referral } from "../Components/Referral/Referral";
import { GetPaid } from "../Components/Referral/GetPaid";
import Meeting from "../Components/Meeting";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;
const AppRoutes = () => {
    const { currentUser, type } = useAuth();
    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route path='/docusign-access' element={<DocusignAccess />} />
                <Route path='/payment' element={<Payment />} />

                <Route element={<App />}>
                    <Route path='/' element={<Home />} />
                    <Route path='/testimonials' element={<Testimonials />} />
                    <Route path='/refer/:referral_code' element={<Redirect />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/specialities' element={<Specialities />} />
                    <Route path='/e-learning' element={<ELearning />} />
                    <Route path='/sponsorship-immigration' element={<Sponsorship />} />
                    <Route path='/ask-a-lawyer' element={<AskALawyer />} />
                    <Route path='/live-work-In-usa' element={<LiveUsa />} />
                    <Route path='/states' element={<States />} />

                    <Route path='/bookings' element={<Booking />} />
                    <Route path='/hotels' element={<Hotels />} />
                    <Route path='/flight' element={<Flight />} />
                    <Route path='/transport' element={<Transport />} />
                    <Route path='/agency-dashboard-calendar' element={<AgencyDashboard />} />
                    <Route path='/agency-dashboard-calendar-two' element={<AgencyDashboardCalendarTwo />} />
                    <Route path='/agency-dashboard-calendar-three' element={<AgencyDashboardCalendarThree />} />
                    <Route path='/agency-dashboard-calendar-finish' element={<AgencyDashboardCalendarFinish />} />
                    <Route path='/agency-dashboard-inbox' element={<AgencyDashboardInbox />} />
                    <Route path='/user-inbox' element={<UserInbox />} />
                    <Route path='/choose-representative' element={<ChooseRepresentative />} />
                    <Route path='/terms' element={<Terms />} />
                    <Route path='/about-us' element={<AboutUs />} />
                    <Route path='/representative-edit-account' element={<RepresentativeEditAccount />} />

                    {/*<Route path='error/*' element={<ErrorsPage />} />*/}
                    {currentUser ? (
                        <>
                            {/*<Route path='PageFormio' key={2112} element={<PageFormio />} />*/}

                            {/*<Route path='/*' element={<PrivateRoutes />} />*/}

                            {type == 'Nurse with NCLEX-RN' &&
                                <>
                                    <Route path='/dashboard' element={<Main />} />
                                    <Route path='/reminders' element={<Reminders />} />
                                    <Route path='/availability' element={<Availability />} />
                                    <Route path='/file-sharing' element={<FileSharing />} />
                                    <Route path='/inbox' element={<Inbox />} />

                                </>
                            }
                            {type == 'Agency' &&
                                <>
                                    <Route path='/dashboard' element={<AgencyDashboardProvider><AgencyDashboard /></AgencyDashboardProvider>} />
                                    <Route path='/inbox' element={<AgencyInbox />} />
                                    <Route path='/file-sharing' element={<AgencyFiles />} />

                                    {/*<Route path='/reminders' element={<Reminders />} />*/}
                                    {/*<Route path='/availability' element={<Availability />} />*/}
                                </>
                            }
                            {type == 'Agency' ?
                                <Route path='/account-settings' element={<AgencyAccountSettings />} />
                                : type === 'Representative' ?
                                    <Route path='/account-settings' element={<RepresentativeAccountSettings />} />
                                    :
                                    <Route path='/account-settings' element={<AccountSettings />} />
                            }
                            {
                                type == 'Representative' &&
                                <>
                                    <Route path='/dashboard' element={<RepresentativeDashboard />} />
                                    <Route path='/add-candidate' element={<AddRepresentativeCandidates />} />
                                    <Route path="/edit-candidate/:candidateToken" element={<EditRepresentativeCandidates />} />
                                </>

                            }
                            <Route path='/Referral' element={<Referral />} />
                            <Route path='/get-paid' element={<GetPaid />} />
                            <Route path='/get-paid/stripe' element={<GetPaid type={'stripe'} />} />
                            <Route path='/get-paid/paypal' element={<GetPaid type={'paypal'} />} />


                            {(type == 'Agency' || type == 'Nurse with NCLEX-RN') &&
                                < Route path='/meeting' element={<Meeting />} />
                            }

                            {/*<Route path='*' element={<Navigate to='/auth' />} />*/}
                        </>
                    ) : (
                        <>
                            <Route path='/login' element={<Login />} />
                            <Route path='/nurse-login' element={<NurseLogin />} />
                            <Route path='/agency-login' element={<AgencyLogin />} />
                            <Route path='/representative-login' element={<RepresentativeLogin />} />
                            <Route path='/forget-password' element={<ForgetPassword />} />
                            <Route path='/reset-password-form' element={<ResetPasswordForm />} />
                            <Route path='/signup-types' element={<SignUpTypes />} />
                            <Route path='/agency-signup' element={<AgencySignUp />} />
                            <Route path='/referral-signup' element={<ReferralSignUp />} />
                            <Route path='/representative-signup' element={<RepresentativeSignUp />} />
                            <Route path='/applicant-signup' element={<ApplicantSignUp />} />
                            <Route path='/nurses-with-rn-signup' element={<NursesSignUp />} />
                            <Route path='/nclex-signup' element={<NclexSignUp />} />
                            <Route path='/clincal-signup' element={<ClincalSignUp />} />
                            {/*<Route path='404' element={<Error />} />*/}
                            {/*<Route path='/page_not_found' element={<Error />} />*/}


                        </>
                    )}
                    <Route path='*' element={<Error />} />

                </Route>

            </Routes>
        </BrowserRouter>
    )
};
export { AppRoutes };
