import React, {useEffect, useState} from 'react';
import { handleFormikSubmit } from "../../Helpers/formHelper";
import { useQuery } from "react-query";
import { getCountries } from "../../adapters/commonAdapter";
import { ClipLoader } from "react-spinners";
import {
    AcceptTermsCondition,
    Boolean, BooleanRadio,
    Countries,
    CountryCode,
    Date,
    File,
    FormikRecaptcha,
    Gender,
    Input,
    Recaptcha
} from "../../Helpers/formComponents";
import { Formik, Form, useFormikContext } from 'formik';
import { representativeSchema } from "../../schemas/representativeSchema";
import { motion } from "framer-motion";
import { representativeRegister } from "../../adapters/representativeAdapter";
import { SignUpContainerVideo } from "../Common/SignUpContainerVideo";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function RepresentativeSignUp() {
    const navigate = useNavigate();

    const redirectMessage = () => {
        navigate('/', { state: { message: "Your application is waiting for approval." } });
    }
    const { isFetching, data: countries } = useQuery('country', getCountries,
        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const theme = useTheme();

    const services = [
        'NCLEX Application Preparation',
        'NCLEX Test Preparation',
        'ENGLISH Test Preparation'
    ];

    // const {getFieldProps} = useFormikContext();
    return (
        <>
            <SignUpContainerVideo
                // image={''}
                // animationData={animationData}
                formTitle={'Become A Representative'}
                formDescription={'Build your business with us by helping nurses apply and ' +
                    'prepare for the NCLEX-RN test and completing the documentation.'}
            >
                {isFetching ?
                    <div className="row mt-5">
                        <div className={'row'}>
                            <div className="col text-center">

                                <ClipLoader
                                    strokeWidth="4"
                                    animationDuration="2"
                                    width="50"
                                    visible={true}
                                />
                            </div>
                        </div>
                    </div>
                    :
                    <Formik
                        validateOnBlur
                        initialValues={{
                            first_name: '',
                            last_name: '',
                            bio: '',
                            gender: '',
                            email: '',
                            country_id: '',
                            country_code: '',
                            address: '',
                            mobile: '',
                            has_more_countries: '',
                            countries: [],
                            services: [],
                        }}
                        validationSchema={representativeSchema}
                        onSubmit={handleFormikSubmit(representativeRegister)}
                    >
                        {({ isValid, isSubmitting, status, setFieldValue, getFieldProps }) => (
                            <Form method="post">
                                <div className="form-block n-border">
                                    <div className="row">
                                        <div className="col-lg-6">

                                            <Input type="text" name="first_name" placeholder="First Name (Official)" />
                                        </div>
                                        <div className="col-lg-6">
                                            <Input type="text" name="last_name" placeholder="Last Name (Official) " />
                                        </div>

                                        <div className="col-lg-12">
                                            <Gender name="gender" />
                                        </div>
                                        <div className="col-lg-12">
                                            <Input type="email" name="email" placeholder="E-Mail" />
                                        </div>
                                        <div className="col-lg-12">
                                            <Countries options={countries?.data?.data} name={"country_id"} />
                                        </div>
                                        <div className="col-lg-5">
                                            <CountryCode options={countries?.data?.data} name={"country_code"} />
                                        </div>
                                        <div className="col-lg-7">
                                            <Input type="tel" name="mobile" placeholder="Contact Number" />
                                        </div>
                                        <div className="col-lg-12">
                                            <Input type="text" name="address" placeholder="Address" />
                                        </div>
                                        <div className="col-lg-12">
                                            <Input type="text" name="bio" placeholder="Bio" />
                                        </div>
                                        <div className="col-lg-12 mb-4">
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Services</InputLabel>
                                                <Select
                                                    labelId="demo-multiple-name-label"
                                                    id="demo-multiple-name"
                                                    multiple
                                                    value={getFieldProps("services").value}
                                                    onChange={(event) => {
                                                        const {
                                                            target: { value },
                                                        } = event;
                                                        setFieldValue("services", typeof value === 'string' ? value.split(',') : value);

                                                    }}
                                                    input={<OutlinedInput label="Services" />}
                                                    MenuProps={MenuProps}
                                                    sx={{
                                                        width: 636,
                                                        height: 21,
                                                        fontWeight: "400",
                                                        fontSize: "14px",
                                                        lineHeight: "21px",
                                                        boxShadow: "0px 8px 2px rgb(0 0 0 / 4%), 0px 2px 2px rgb(0 0 0 / 16%), 0px 16px 32px rgb(0 0 0 / 16%)",
                                                        borderRadius: "4px",
                                                        color: "#1f2937",
                                                        backgroundColor: "#FFF",
                                                        padding: "27px 14px",
                                                        "& .MuiSvgIcon-root": {
                                                            color: "#1f2937",
                                                        },
                                                    }}
                                                >
                                                    {services.map((service) => (
                                                        <MenuItem
                                                            key={service}
                                                            value={service}
                                                            style={getStyles(service, getFieldProps("services").value, theme)}
                                                        >
                                                            {service}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-lg-12 mb-4">
                                            <BooleanRadio name="has_more_countries" title="Add More Countries ?" />
                                        </div>

                                        {getFieldProps("has_more_countries").value === '1' &&
                                            <div className="col-lg-12">
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Add more countries</InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-name-label"
                                                        id="demo-multiple-name"
                                                        multiple
                                                        value={getFieldProps("countries").value}
                                                        onChange={(event) => {
                                                            const {
                                                                target: { value },
                                                            } = event;
                                                            setFieldValue("countries", typeof value === 'string' ? value.split(',') : value);

                                                        }}
                                                        input={<OutlinedInput label="Add More Countries" />}
                                                        MenuProps={MenuProps}
                                                        sx={{
                                                            width: 636,
                                                            height: 21,
                                                            fontWeight: "400",
                                                            fontSize: "14px",
                                                            lineHeight: "21px",
                                                            boxShadow: "0px 8px 2px rgb(0 0 0 / 4%), 0px 2px 2px rgb(0 0 0 / 16%), 0px 16px 32px rgb(0 0 0 / 16%)",
                                                            borderRadius: "4px",
                                                            color: "#1f2937",
                                                            backgroundColor: "#FFF",
                                                            padding: "27px 14px",
                                                            "& .MuiSvgIcon-root": {
                                                                color: "#1f2937",
                                                            },
                                                        }}
                                                    >
                                                        {countries?.data?.data?.map((country) => (
                                                            <MenuItem
                                                                key={country.id}
                                                                value={country.id}
                                                                style={getStyles(country, getFieldProps("countries").value, theme)}
                                                            >
                                                                {country.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        }

                                    </div>
                                </div>
                                <AcceptTermsCondition />
                                <FormikRecaptcha />

                                <div className="col-md-12">
                                    <motion.button
                                        className={status == 200 ? "btn_submit_dark btn btn-success" : "btn_submit_dark"}
                                        type={"submit"}
                                        whileTap={{ scale: 0.7 }}
                                        onHoverStart={e => { }}
                                        onHoverEnd={e => { }}
                                        disabled={isSubmitting}
                                    >  {status == 200 ? "Success": (isSubmitting ? "Please wait..." : "Submit")}
                                        {status == 200 && redirectMessage()}
                                    </motion.button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                }

            </SignUpContainerVideo>
        </>
    );
}



