import React from 'react';
import { ContactForm } from "../../Common/ContactForm";
import { BannerContainer } from "../../Common/BannerContainer";
import FooterImage from "../../Common/FooterImage"
import Banner from "../../Common/Banner";
import * as animationData from '../../../assets/animation/Hotel.json'
import { Card } from '../common/Card';
import Map from '../common/GoogleMpas';
import Lottie from "lottie-react";
import * as LeftData from "../../../assets/animation/AbstractDotsBgLeft.json";
import * as rightData from "../../../assets/animation/AbstractDotsBgRight.json";
import { useInfiniteQuery, useQuery } from "react-query";
import { getBookingBanners, getBookingCards, getBookingSettings } from "../../../adapters/ForumsAdapter";
import { getNextPage } from "../../../Helpers/ReactQueryHelper";
import Pagination from "../../Common/Pagination";
import Slider from 'react-slick';

export default function Hotels() {

    const { isLoading, isSuccess, data: setting } = useQuery('hotel-setting', getBookingSettings,
        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });
    const { isLoading: loading, isSuccess: sliderIsSuccess, data: sliderData } = useQuery('hotel-banners', () => getBookingBanners("hotels"),
        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });



    const {
        status,
        data,
        error,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useInfiniteQuery(
        ['HotelCards'],
        async ({ pageParam = 1 }) => {
            const res = await getBookingCards(pageParam, "hotels")
            return res.data
        },
        {
            getNextPageParam: getNextPage,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,

        },
    )

    const SliderSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        adaptiveHeight: true,
        autoplaySpeed: 5000,
    };
    return (

        <>

            <BannerContainer style={{
                background: "#fff"
            }}>
                <div className="animation-left">
                    <Lottie animationData={LeftData} />
                </div>
                <div className="animation-right">
                    <Lottie animationData={rightData} />
                </div>

                <Banner
                    title={'Hotels'}
                    subTitle={'BOOKING'}
                    paragraph={setting?.data.data.BookingData?.booking_desc_2}
                    image={'img/inner-hotel.png'}
                    animationData={animationData}

                />
                <Pagination
                    data={data}
                    isFetching={isFetching}
                    fetchNextPage={fetchNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                    hasNextPage={hasNextPage}
                >{(currentCard) => {
                    return <Card
                        link={currentCard.embedded_link}
                        Image={currentCard.image}
                    />
                }}
                </Pagination>
                {sliderData && SliderSettings &&
                    <div className="mb-5 slide-img">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <Slider {...SliderSettings}>
                                        {sliderData.data.data.map(slider => (
                                            <div key={slider.id}>
                                                <div className="banner-slider">
                                                    <a href={slider.embedded_link} rel="noreferrer" target="_blank">
                                                        <img alt={'banner'} src={slider.image} class="banner-img" />
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <Map name="lodging" />
            </BannerContainer>
            <FooterImage />
            <ContactForm />

        </>
    )
}
