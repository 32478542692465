import * as yup from 'yup';

export let askLawyerSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    surname: yup.string().required("Surname is required"),
    email : yup.string().email().required(),
    subject : yup.string().required(),
    country_code : yup.string().required("Country code is required"),
    contact_number : yup.number().required(),
});
