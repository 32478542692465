import * as yup from 'yup';

export let sendOtpSchema = yup.object().shape({
    email : yup.string().email().required(),
});


export let changePassword = yup.object().shape({
    email : yup.string().email().required(),
    otp : yup.string().required(),
    password : yup.string().required(),
    password_confirmation : yup.string().required(),
});
