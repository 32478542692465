import * as yup from 'yup';
import {getReferral} from "../axiosSetup";


export let step1Schema = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    // gender: yup.mixed().oneOf(['male', 'female']).nullable(),
    date_of_birth: yup.date().nullable(),
    email : yup.string().email().required(),
    country_id : yup.string().required("Country is required").nullable(),
    country_code : yup.string().required("Country code is required"),
    mobile : yup.number().required(),
    password : yup.string().required(),
});
export let step2Schema = yup.object().shape({

    rn_license_number: yup.string().required("Rn license number is required"),
    issuing_state_id: yup.number().required("Issuing state is required"),
    expiry_date: yup.date().nullable(),
});


let step3Validation = {
    specialty: yup.string().required(),
    years_of_experience: yup.number().required("Years of experience is required"),
    document: yup.mixed().required("document is required"),
    resume: yup.mixed().required("resume is required"),

};
let referral = getReferral()
if(!referral) {
    step3Validation.referred_by_friend = yup.boolean().required("Referred by friend is required");
    step3Validation.unique_referral_number= yup.string().when('referred_by_friend', (referred_by_friend, field) =>
        referred_by_friend ? field.required("unique referral number is required") : field
    );
}
export let step3Schema = yup.object().shape(step3Validation);
