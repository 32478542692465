import React, {useContext, useEffect, useState} from "react";


const AgencyDashboardContext = React.createContext({
    reloadDashboard  : null,
    setReloadDashboard  : ()=>{},
});
export const AgencyDashboardProvider = ({children})=>{
    const [reloadDashboard , setReloadDashboard] = useState(false);
    return <AgencyDashboardContext.Provider value={{reloadDashboard,setReloadDashboard}}  >{children}</AgencyDashboardContext.Provider>
}


export const useAgencyDashboard = () => {
    return useContext(AgencyDashboardContext);
};