import axios from "axios";
import {addUserDataToLocalStorage, useAuth} from "../app/auth";

export  function  representativeRegister(data) {
    if(data.has_more_countries !=='1'){
        data.countries = [];
    }
    return  axios.post("representative_register",data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export  function  getLoginToken(data) {
    return  axios.post("get_representative_token",data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export  function  Login(data) {
    return  axios.post("representative_login?include=details",data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(res => {
        res = res.hasOwnProperty('response') ? res.response :res

        if(res.status == 200) {
            addUserDataToLocalStorage(res.data.data, res.data.data.access_token);
        }
        return res
        //reload page
    });
}
export function getCandadites(pageParam) {
    return axios.get('Representative/candidates?page=' + pageParam)
}
export function getRepresentativeRate(representative_id) {
    return axios.get('rate_representative/?representative_id=' + representative_id)
}

export  function  addCandidate(data) {
    return  axios.post("Representative/candidates",data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
export function editCandidate(id,data) {
  
    return  axios.post(`/Representative/candidates/${id}`,{_method:"PUT",...data}, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });
}
export function showCandidate(id) {
    return axios.get(`/Representative/candidates/${id}`)
      .then(response => response.data)
      .catch(error => {
        throw new Error(error.message);
      });
  }
// editCandidate,