import React, {useState} from 'react';
import {BannerContainer} from "../Common/BannerContainer";
import {ContactForm} from "../Common/ContactForm";
import FooterImage from "../Common/FooterImage";
import {CalendarSectionDone} from "./AgencyDashboardSections/CalendarSectionDone";
import {InterviewSectionDone} from "./AgencyDashboardSections/InterviewSectionDone";


export default function AgencyDashboardCalendarFinish({application}) {
    return (
        <>
            <CalendarSectionDone/>
            <InterviewSectionDone/>
            <div className="calendar-tab border-top-dashed border-bottom-dashed">
                <div className="d-flex">
                    <span className="approved-check">
                        <i className="far fa-check"></i>
                    </span>
                    <div className="calendar-list">
                        <span>
                            3
                        </span>
                    </div>
                    <div className="calendar-flex">
                        <h3 className="tab-title">Contract</h3>
                        <p>
                            Upload and share the contract with the applicant after the interview is done for a seamless hiring process.
                        </p>
                        <a href={application.contract} className="view_doc">
                            <span>
                                <i className="far fa-file"></i>
                            </span>
                            View the agreement
                        </a>
                    </div>
                    <button className="btn_submit_dark p-2 mt-2">
                        Add withdrawal contract
                    </button>
                </div>
            </div>
            {
                application.status =="Offer Accepted" &&
                <div className="text-center mt-5">
                    <img src="img/completed.svg"/>
                </div>
            }
        </>
    );
}



