import {useEffect, useState} from "react";
import $ from "jquery";
import {motion} from "framer-motion";
import React from "react";
import {BigModal} from "./bigModal";
import {AvailabilityGeneral} from "./AvailabilityGeneral";
import Modal from 'react-modal';
import {Form, Formik} from "formik";
import {tokenSchema} from "../../../schemas/emailLoginSchema";
import {handleFormikSubmit} from "../../../Helpers/formHelper";
import {rateRepresentative} from "../../../adapters/nurseAdapter";

import {Input} from "../../../Helpers/formComponents";
import {Rating} from "../../../Helpers/formComponents";
import {useAuth} from "../../../app/auth";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        // maxWidth: "400px",
        maxWidth: "600px",
        minWidth: "320px",
        borderColor:"#63BD7C",
        borderWidth:"3px",
        transform: 'translate(-50%, -50%)',
        backgroundColor:"#fff",
    },
    overlay: {
        zIndex: 10000,
        backgroundColor: 'rgba(70, 70, 70, 0.5)',

    }

};
Modal.setAppElement('#root2');


export const RepresentativeReview = () => {
    let subtitle;
    const {representativeRatingData} = useAuth();
    const [modalIsOpen, setIsOpen] = React.useState(true);
    console.log(representativeRatingData);

    console.log(representativeRatingData);
    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    return (

        <Modal
        backdropColor="transparent"
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        style={customStyles}
        contentLabel="Example Modal"
    >
        <button onClick={closeModal} className="btn_close_modal"><img src="img/remove.svg" /></button>
        <h3 className="popup-review-title">Review Your Experience with {representativeRatingData.representative_full_name}</h3>
        <div className="container">
            <Formik
                validateOnBlur
                initialValues={{
                    representative_id: representativeRatingData.representative_id,
                    communication_rate: null,
                    provided_services_rate: null,
                    help_support_rate: null,
                    commitments_responsibilities_rate: null,
                }}
                // validationSchema={tokenSchema}
                onSubmit={handleFormikSubmit(rateRepresentative)}
            >
                 {({isValid, isSubmitting , status}) => (

                <Form method="post">
        <ul className="rep-popup-list">
            <li>
                <div className="d-flex">
                    <h3>Communication</h3>
                    <div className="rep-popup-stars">
                        <div className="d-flex">
                        <Rating name={"communication_rate"}/>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div className="d-flex">
                    <h3>Provided Services</h3>
                    <div className="rep-popup-stars">
                        <div className="d-flex">
                            <Rating name={"provided_services_rate"}/>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div className="d-flex">
                    <h3>Help & Support</h3>
                    <div className="rep-popup-stars">
                        <div className="d-flex">
                            <Rating name={"help_support_rate"}/>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div className="d-flex">
                    <h3>Commitments & Responsibilities</h3>
                    <div className="rep-popup-stars">
                        <div className="d-flex">
                            <Rating name={"commitments_responsibilities_rate"}/>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div className="col-md-12">
                <motion.button
                    className={status == 200 ? "btn_submit_dark btn btn-success" : status == 422 ? "btn_submit_dark btn-danger" : "btn_submit_dark"}
                    type={"submit"}
                    whileTap={{ scale: 0.7 }}
                    onHoverStart={e => { }}
                    onHoverEnd={e => { }}
                    disabled={isSubmitting}
                >  {status == 200 ? "Success" : (isSubmitting ? "Please wait..." : status == 422 ? "Please fix the form errors" : "Submit")}
                </motion.button>
            </div>

                </Form>
                 )}
            </Formik>
                </div>
    </Modal>

    )
}

