import React, {useEffect, useState, useRef} from 'react';
import {agencyInbox, nurseApplication, SendMessage} from "../../../adapters/agencyAdapter";
import {useInfiniteQuery, useQuery} from "react-query";
import {Dashboard} from "../../UserCommon";
import {messageSchema} from "../../../schemas/agencySchema";
import {handleFormikSubmit} from "../../../Helpers/formHelper";
import {Verify} from "../../../adapters/nurseAdapter";

import {Formik, Form, Field} from "formik";
import {getNextPage} from "../../../Helpers/ReactQueryHelper";
import {Link} from "react-router-dom";

export default function AgencyInbox() {


    const {
        status,
        data:inbox,
        error,
        isFetching:inboxIsFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch:inboxRefetch,
    } = useInfiniteQuery(
        ['chats'],
        async ({ pageParam = 1 }) => {
            const res = await agencyInbox(pageParam)
            return res.data
        },
        {
            getNextPageParam: getNextPage,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,

        },
    )
    const {  refetch,isFetching,data } = useQuery(['current_application'], nurseApplication ,
        {
            cacheTime : 0,
            staleTime : 0,
            refetchOnWindowFocus: false, // default: true
            refetchOnMount:false,
        });



    const [application,setApplication]= useState(null)
    const [refetchMessages,setRefetchMessages]= useState(false)
    const divRef = useRef(null);
    const [isAtTop, setIsAtTop] = useState(false);
    const handleScroll = () => {
        if (divRef.current) {
            setIsAtTop(divRef.current.scrollTop === 0);
        }
    };
    const loadMore = () => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    };
    useEffect(() => {
        if (isAtTop) {
            loadMore();
        }
    }, [isAtTop]);
    useEffect(() => {

        console.log(inbox.pages.length);
        if (inbox && inbox.pages.length == 1 && divRef.current) {

            divRef.current.scrollTop = divRef.current.scrollHeight;
        }


    }, [inbox,divRef.current]);
    useEffect(() => {
        if (divRef.current) {
            divRef.current.addEventListener("scroll", handleScroll);
        }
    }, [divRef.current]);

    useEffect(function (){
        if(data){
            setApplication(data.data.data);
        }
    },[data])
    useEffect(function (){
        inboxRefetch()
    },[refetchMessages])


    return (
        <>

            <Dashboard>
                {!isFetching &&  !!application &&
                    <>
                        <div className="col-md-9">
                            <div className="sigma_subheader_inner style-5">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h2>Inbox</h2>
                                            <p className="paragraph-text">
                                                All your conversations with applicants are displayed here.
                                            </p>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="msg-container p-0">
                                                <div className="candidate-name">
                                                    <div className="d-flex justify-content-start">
                                                        <div className="user-msg">

                                                            <img src={application.candidate.image}/>
                                                        </div>
                                                        <h3 className="cad-name">{application.candidate.full_name}</h3>
                                                    </div>
                                                </div>
                                                <div className="msg_trans">
                                                    <div className="msg-inner h-297" ref={divRef}>

                                                    {
                                                        inbox?.pages.slice(0).reverse().map((page) => {
                                                         return page.data.slice(0).reverse().map((currentRecord) => {
                                                            return  <>
                                                                {!currentRecord.is_current_user ? <div className="d-flex justify-content-start">
                                                                    <div className="user-msg">
                                                                        <img src={currentRecord.sender.image}/>
                                                                    </div>
                                                                    <div className="receive-msg">
                                                                        <p>   {currentRecord.message} </p>
                                                                        <span className="msg-time">
                                                                                          {currentRecord.formated_date}
                                                                                    </span>
                                                                    </div>
                                                                </div>:
                                                                <div className="d-flex justify-content-end">
                                                                    <div className="sender-msg">
                                                                        <span className="msg-sender-time">
                                                                            {currentRecord.formated_date}
                                                                            {/*Dec 7 ,5:27 PM*/}
                                                                        </span>
                                                                        <p> {currentRecord.message}</p>
                                                                    </div>
                                                                    <div className="user-msg">
                                                                        <img src={currentRecord.sender.image}/>
                                                                    </div>
                                                                </div>}
                                                                </>
                                                        })
                                                        })}



                                                    </div>
                                                </div>
                                                <div className="msg-footer">
                                                    <div className="container">

                                                        <Formik

                                                            validateOnBlur
                                                            initialValues={{
                                                                message: '',
                                                            }}
                                                            validationSchema={messageSchema}
                                                            onSubmit={(values,{resetForm})=>{
                                                                resetForm();
                                                                handleFormikSubmit(SendMessage(function(){
                                                                    setRefetchMessages(!refetchMessages)

                                                                })(values))
                                                            }
                                                               }
                                                        >
                                                            {({isValid, isSubmitting , status}) => (

                                                                <Form method="post">
                                                                    <div className={"row"} >
                                                            <div className="col-md-1 p-0 text-center">
                                                                    <span className="btn_upload_msg">
                                                                        <Link to={'/file-sharing'} >
                                                                            <img src="img/attachment.svg"/>
                                                                        </Link>                                                                    </span>
                                                            </div>
                                                            <div className="col-md-8 p-0">
                                                                <Field  type="text" name={'message'} placeholder="Write your message"/>
                                                                {/*<Input errorShow={false} type="text" name={'message'} placeholder="Write your message"/>*/}
                                                            </div>
                                                            <div className="col-md-3">
                                                                <button className="btn_send_msg">
                                                                    Send
                                                                </button>
                                                            </div>
                                                                    </div>
                                                                </Form>
                                                                    )}
                                                        </Formik>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Dashboard>

        </>
    );
}



