import React from "react";

export function BannerContainer({children, style,classNames}) {
    return (
        <div className={`sigma_banner_inner ${classNames}`}
             style={style}>
            <div className="sigma_subheader_inner style-5" >
                <div className="container" >
            {children}
                </div>
            </div>
        </div>
    )
}

