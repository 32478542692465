import React, {useState} from 'react';
import {BannerContainer} from "../Common/BannerContainer";
import {ContactForm} from "../Common/ContactForm";
import FooterImage from "../Common/FooterImage";
import {Link} from "react-router-dom";
import {CalendarSectionDone} from "./AgencyDashboardSections/CalendarSectionDone";
import {ContractSectionDisabled} from "./AgencyDashboardSections/ContractSectionDisabled";
import {InterviewSection} from "./AgencyDashboardSections/InterviewSection";


export default function AgencyDashboardCalendarTwo({application}) {
    return (
        <>
            <CalendarSectionDone/>
            <InterviewSection application={application}/>
            <ContractSectionDisabled/>

        </>
    );
}



