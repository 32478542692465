import React, {useEffect, useState} from "react";

export function ToTop() {

    const [show, setShow] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const controlNavbar = () => {
        if (typeof window !== 'undefined') {
            if (window.scrollY ===0) { // if scroll down hide the navbar
                setShow(false);
            } else { // if scroll up show the navbar
                setShow(true);
            }

            // remember current page location to use in the next move
            setLastScrollY(window.scrollY);
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', controlNavbar);
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            // cleanup function
            return () => {
                window.removeEventListener('scroll', controlNavbar);
            };
        }

    }, []);

    return (
        <div className={"sigma_top style-8 "+(show && 'active') }  onClick={() => {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>
            <i className="far fa-angle-up"></i>
        </div>
    );
}