import React from "react";
import {useFormikContext} from "formik";

export function StepsBar({step,setStep}){
    const {getFieldProps} = useFormikContext();

    return <ul className="step-apply">
        <li className={step==1?"active":""}>
            <a href={"javascript:;"} onClick={()=>{
                if(step > 1){
                    setStep(1)
                }
            }} >
                1{step==1? <>. Personal Information</>:<></>}
            </a>
        </li>
        <li className={step==2?"active":""}>
            <a href={"javascript:;"}  onClick={()=>{
                if(step > 2){
                    setStep(2)
                }
            }}>
                2{step==2? <>. Pass the NCLEX-RN Detail</>:<></>}
            </a>
        </li>

        <li className={(step==3?"active":"")+" "+(getFieldProps("passed").value == 1?"":"disabled")}>
            <a href={"javascript:;"}  >
                3{step==3? <>. Step Name Long - Lorem</>:<></>}
            </a>
        </li>
    </ul>
}