import React from "react";
import {BannerContainer} from "../../Common/BannerContainer";
import FooterImage from "../../Common/FooterImage";
import {ContactForm} from "../../Common/ContactForm";
import {TitleOnlyBanner} from "../../Common/TitleOnlyBanner";
import StateMapSearch from "../Common/StateMapSearch";
import {getStatesLicensure, getStatesLicensureSettings,getStatesCards} from "../../../adapters/ForumsAdapter";
import {useInfiniteQuery, useQuery} from "react-query";
import {getNextPage} from "../../../Helpers/ReactQueryHelper";
import Pagination from "../../Common/Pagination";
import FlipCard from "../Common/FlipCard";
import Lottie from "lottie-react";
import * as LeftData from "../../../assets/animation/AbstractDotsBgLeft.json";
import * as rightData from "../../../assets/animation/AbstractDotsBgRight.json";

export default function States() {
    const {  data:setting } = useQuery('states-setting', getStatesLicensureSettings ,
        {
            cacheTime : 1000 * 60 * 60 * 24,
            staleTime : 1000 * 60 * 60 * 12,
        });

    const { data:states_data } = useQuery('states-data', getStatesLicensure ,
        {
            cacheTime : 1000 * 60 * 60 * 24,
            staleTime : 1000 * 60 * 60 * 12,
        });

    const {
            status,
            data,
            error,
            isFetching,
            isFetchingNextPage,
            fetchNextPage,
            hasNextPage,
            refetch,
        } = useInfiniteQuery(
            ['states_cards'],
            async ({ pageParam = 1 }) => {
                const res = await getStatesCards(pageParam)
                console.log(res.data)
                return res.data
            },
            {
                getNextPageParam: getNextPage,
                cacheTime : 0,
                refetchOnWindowFocus: false,
                refetchOnMount: false,

            },
        )
    return (
        <>
            <BannerContainer style={{
                background: "#fff"
            }}>
                <div className="animation-left">
                    <Lottie animationData={LeftData} />
                </div>
                <div className="animation-right">
                    <Lottie animationData={rightData} />
                </div>
                <TitleOnlyBanner
                    title={'States & Licansure'}
                    description={setting?.data.data.StatesLicensureDetails?.Description}
                />

                {!!states_data?.data?.data &&  <StateMapSearch statesData={states_data?.data.data}/>}
            </BannerContainer>
            <div className="container">
                {
                    (() => {
                        if(!isFetching) {
                            return (
                                <Pagination
                                    data={data}
                                    isFetching={isFetching}
                                    fetchNextPage={fetchNextPage}
                                    isFetchingNextPage={isFetchingNextPage}
                                    hasNextPage={hasNextPage}
                                >{(currentRecord) => {
                                    console.log(currentRecord)
                                    return <FlipCard
                                        FrontTitle={currentRecord.title_en}
                                        FrontImage={currentRecord.image}
                                        Link={currentRecord.link}
                                        LinkLabel={setting?.data.data.StatesLicensureDetails?.button_title}
                                        cardFlip={setting?.data.data.StatesLicensureDetails?.card_flip}
                                        BackTitle={currentRecord.title_en}
                                        BackDescription={currentRecord.description}
                                    />
                                }}
                                </Pagination>
                            )
                        }
                    })()
                }
            </div>
            <FooterImage/>
            <ContactForm/>
        </>
    )
}
