import {useEffect, useState} from "react";
import $ from "jquery";
import {motion} from "framer-motion";
import React from "react";
import {BigModal} from "./bigModal";
import {AvailabilityGeneral} from "./AvailabilityGeneral";
import Modal from 'react-modal';
import {Form, Formik} from "formik";
import {tokenSchema} from "../../../schemas/emailLoginSchema";
import {handleFormikSubmit} from "../../../Helpers/formHelper";
import {Verify} from "../../../adapters/nurseAdapter";
import {Input} from "../../../Helpers/formComponents";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        maxWidth: "400px",
        minWidth: "320px",

        transform: 'translate(-50%, -50%)',
        backgroundColor:"#fff",

    },
    overlay: {
        zIndex: 10000,
        backgroundColor: 'rgba(70, 70, 70, 0.5)',

    }

};
Modal.setAppElement('#root2');


export const OptVerification = () => {
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(true);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    return (

        <Modal
            backdropColor="transparent"
            shouldCloseOnOverlayClick={false}
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
                <h4 className="popup-title">Email Verification</h4>
                <div className="">
                    <p  className="paragraph-text">
                        Please check your email for the OTP to complete your registration. Enter the token to activate your account.
                    </p>
                    <Formik
                        validateOnBlur
                        initialValues={{
                            token: '',
                        }}
                        validationSchema={tokenSchema}
                        onSubmit={handleFormikSubmit(Verify)}
                    >
                        {({isValid, isSubmitting , status}) => (

                            <Form method="post">
                                <div className="form-block n-border contact-form">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <Input type="text" name="token" placeholder="Enter your OTP"/>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <motion.button
                                        className={status == 200 ? "btn_submit_dark btn btn-success" :"btn_submit_dark"}
                                        type={"submit"}
                                        whileTap={{ scale: 0.7 }}
                                        onHoverStart={e => {}}
                                        onHoverEnd={e => {}}
                                        disabled={isSubmitting}
                                    >  {status == 200 ? "Success" : (isSubmitting ? "Please wait..." : "Confirm OTP")}
                                    </motion.button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
        </Modal>
    )
}

