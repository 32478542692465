import React, { useCallback, useState, useEffect} from "react";
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Tooltip,{ tooltipClasses }  from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {Dashboard} from "../UserCommon";
import InputLabel from '@mui/material/InputLabel';
import {useDropzone} from 'react-dropzone';
import {useQuery} from "react-query";
import {
    getFileShare,
    storeFileShare,
    FileShareAction,
    FileShareDownload,
    getAvailabilityForAgency
} from "../../adapters/agencyAdapter";
import {useAuth} from "../../app/auth";
import LoadingOverlay from 'react-loading-overlay';
import {getAvailability, getSubmitedAgency} from "../../adapters/nurseAdapter";

export function FilesShareComponent(){
    const [isUploading, setIsUploading] = useState(false);
    const [agencyId, setAgencyId] = useState(null);
    const [reloadFiles, setReloadFiles] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isSpinner, setIsSpinner] = useState(false);
    const reloadDataAction = function (request){
        setIsSpinner(true)

        return request.then(response => {
            setIsUploading(false);
            setReloadFiles(!reloadFiles)
            setIsSpinner(false)
            return response
        }).catch(error => {
            setIsUploading(false);
            console.log(error);
            let message = error?.response?.data?.message
            if(!message){
                message =error?.message
            }
                setErrorMessage(message);

            setIsSpinner(false)
        })
    }
    const onDrop = (acceptedFiles) => {
        setIsUploading(true);
        setErrorMessage(null);

        // Do something with the file, such as uploading to a server
        const file = acceptedFiles[0];
        const formData = new FormData();
        formData.append('attachement', file);
        if(agencyId && currentUser.type != "Agency"){
            formData.append('receiver_id', agencyId);
        }
        formData.append('attachement', file);
        reloadDataAction(storeFileShare(formData))

    };

    const downloadFile = function(attachement){
        const element = document.createElement('a');
        element.href = attachement;
        element.download = attachement;
        element.target = "_blank";
        document.body.appendChild(element);
        element.click();
        setTimeout(() => {
            document.body.removeChild(element);
        }, 100);
    }
    const { getRootProps, getInputProps, isDragActive,acceptedFiles } = useDropzone({
        onDrop,
        // accept: 'image/*', // specify the type of files allowed to be uploaded
        maxFiles: 1 // allow only one file to be uploaded at a time
    });

    const {currentUser} = useAuth();
    const {  refetch,isFetching,data } = useQuery(['fileShare'], ()=> getFileShare(agencyId),
        {
            cacheTime : 0,
            staleTime : 0,
            refetchOnWindowFocus: false, // default: true
            refetchOnMount:false,
        });
        const { refetch :refetchAgencies,isFetching:isAgenciesFetching,data:Agencies } = useQuery(['submitted_agency_list'], getSubmitedAgency ,
            {
                cacheTime : 0,
                staleTime : 0,
                refetchOnWindowFocus: false, // default: true
                refetchOnMount:false,
                enabled: currentUser.type != "Agency",

            });



    useEffect(function () {
        if(!isFetching){
            refetch()
        }

    },[reloadFiles])


    useEffect(function () {
        if(agencyId){
            refetch()
        }

    },[agencyId])
    const [shared, setShared] = React.useState('');
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: '0px 8px 2px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.16), 0px 16px 32px rgba(0, 0, 0, 0.16)',
            fontSize: 11,
        },
        [`& .${tooltipClasses.arrow}`]: {
            "&:before": {
                border: "1px solid #fff",
                backgroundColor: theme.palette.common.white,
                boxShadow: '0px 8px 2px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.16), 0px 16px 32px rgba(0, 0, 0, 0.16)',
            }
        }
    }));

    const [list, setList] = React.useState('');

    const handleChange = (e) => {

        setAgencyId(e.target.value);
    };

    return (
        <Dashboard>

            <div className="col-md-9">
                <div className="sigma_subheader_inner style-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>File Sharing</h2>
                                    <>{currentUser.type =="Nurse with NCLEX-RN" ?
                                        <p className="paragraph-text">
                                            Easily upload and share frequently needed documents, such as CVs, certifications, and identity cards, with our secure file-sharing feature. Access and share your documents anytime, anywhere.
                                        </p> : ""
                                    }</>
                                <>
                                    {currentUser.type =="Agency" ?
                                        <p className="paragraph-text">
                                            Access and share documents anytime, anywhere, with our secure file-sharing feature.
                                        </p> :""
                                    }
                                </>
                            </div>
                            <div className="col-md-12">
                                <div className="upload">
                                    <div {...getRootProps({className: 'dropzone'})}>
                                        <input {...getInputProps()} />
                                        <div className="text-center">
                                            <img src="img/upload.svg" />
                                        </div>
                                        {isUploading ? (
                                            <p>Uploading file...</p>
                                        ) : (
                                            <p>{isDragActive ? 'Drop the file here' :<>Drop your files here or <span>Browse</span></>}</p>
                                        )}
                                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

                                    </div>
                                </div>
                                <LoadingOverlay
                                    active={isFetching || isSpinner}
                                    spinner
                                    text='please wait...'
                                >
                                    <div className="file_box">
                                        <div className="row mb-4">

                                            {currentUser.type !="Agency" &&
                                                <div className="col-md-4">
                                                    <form>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">List of files for "Company Name"</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={agencyId}
                                                                label="Company Name"
                                                                onChange={handleChange}
                                                            >
                                                                {Agencies?.data?.data?.map(function(value){
                                                                    return <MenuItem value={value.id}>{value.details.company_name}</MenuItem>

                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </form>
                                                </div>}
                                            <div className=  {currentUser.type =="Agency" ? "col-md-4":"col-md-4 text-center"}>
                                                <h3 className="num-file">
                                                    {data?.data?.data?.length} Shared Files
                                                </h3>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="table-responsive desktop-version">
                                                {!!data?.data?.data.length && <table className="table table-file">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">FILE</th>
                                                        <th scope="col">OWNER</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    { data?.data?.data.map(function(data){
                                                        return  <tr>
                                                            <th scope="row">
                                                                <div className="file-name">
                                                                    <div className="file-copy">
                                                                        <img src="../../../img/copy-blue.svg" />
                                                                    </div>
                                                                    <div className="file-box">

                                                                        <h3 className="file-title">{data.attachement_name}</h3>
                                                                        <p className="file-size">{(data.attachement_size / (1024 * 1024)).toFixed(2)} MB</p>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <td>
                                                                <div className="owner-box">
                                                                    <img src={data.sender.image}/>
                                                                    <div className="d-bloc">
                                                                        <h4 className="owner-username">{data.sender.full_name}</h4>
                                                                        <p className="owner-date"> {data.formated_date}</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="owner-list">
                                                                    {data.downloaded &&

                                                                        <div className="tick-icon">
                                                                            <LightTooltip title="This file was seen by the other party." placement="top" arrow>
                                                                                <img src="img/tick.svg" />
                                                                            </LightTooltip>
                                                                        </div>
                                                                    }
                                                                    <div className="download-icon">
                                                                        <button onClick={()=>{
                                                                            if(data.is_current_user){
                                                                                downloadFile(data.attachement)
                                                                            }else{
                                                                                reloadDataAction(FileShareDownload(data.id,'download')).then(function (data){
                                                                                    downloadFile(data?.data?.data?.attachement)

                                                                                })
                                                                            }
                                                                        }}>
                                                                            <img src="img/download-dark.svg"/>
                                                                        </button>
                                                                    </div>
                                                                    {data.is_current_user && <>
                                                                        <div className="shared-box">
                                                                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                                                <Select
                                                                                    value={data.status}
                                                                                    displayEmpty
                                                                                    id="demo-select-small"
                                                                                    onChange={(e)=>{
                                                                                        reloadDataAction(FileShareAction(data?.id,e.target.value))
                                                                                    }}
                                                                                >
                                                                                    <MenuItem value="approved">Shared</MenuItem>
                                                                                    <MenuItem value="suspended">Hidden</MenuItem>
                                                                                </Select>
                                                                            </FormControl>
                                                                        </div>
                                                                        <div className="remove-icon">
                                                                            <button type={'button'} onClick={()=>{
                                                                                reloadDataAction(FileShareAction(data?.id,'delete'))
                                                                            }}>
                                                                                <img src="img/remove.svg"/>
                                                                            </button>
                                                                        </div>
                                                                    </>}
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    })

                                                    }
                                                    </tbody>
                                                </table>
                                                }
                                            </div>
                                            <div className="mobile-version">
                                            {!!data?.data?.data.length &&
                                                 data?.data?.data.map(function(data){
                                                    return <div className="file-sharing-mobile">
                                                         <div className="icon-file">
                                                             <img src="../../../img/copy-blue.svg" />
                                                         </div>
                                                        <div className="file-sharing-title-m">
                                                             <h3 className="title-share-mobile">
                                                                 {data.attachement_name}
                                                             </h3>
                                                            <p>{(data.attachement_size / (1024 * 1024)).toFixed(2)} MB <span>{data.formated_date}</span></p>
                                                             <div className="file-user-m">
                                                                 <img src={data.sender.image} />
                                                                 <h4>{data.sender.full_name}</h4>
                                                             </div>
                                                        </div>
                                                        <div className="file-icons-m">
                                                            {data.is_current_user &&
                                                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                                <Select
                                                                         value={data.status}
                                                                         displayEmpty
                                                                         id="demo-select-small"
                                                                         onChange={(e)=>{
                                                                             reloadDataAction(FileShareAction(data?.id,e.target.value))
                                                                         }}
                                                                     >
                                                                    <MenuItem value="approved">Shared</MenuItem>
                                                                    <MenuItem value="suspended">Hidden</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                            }
                                                                 <div className="file-icons-list-m">
                                                                     {data.downloaded &&
                                                                         <div className="tick-icon">
                                                                             <LightTooltip title="This file was seen by the other party." placement="top" arrow>
                                                                                 <img src="img/tick.svg" />
                                                                             </LightTooltip>
                                                                         </div>
                                                                     }
                                                                         <div className="download-icon">
                                                                             <button onClick={() => {
                                                                                 if (data.is_current_user) {
                                                                                     downloadFile(data.attachement)
                                                                                 } else {
                                                                                     reloadDataAction(FileShareDownload(data.id, 'download')).then(function (data) {
                                                                                         downloadFile(data?.data?.data?.attachement)

                                                                                     })
                                                                                 }
                                                                             }}>
                                                                                 <img src="img/download-dark.svg"/>
                                                                             </button>
                                                                         </div>

                                                                     {data.is_current_user &&
                                                                         <div className="remove-icon">
                                                                             <button type={'button'} onClick={() => {
                                                                                 reloadDataAction(FileShareAction(data?.id, 'delete'))
                                                                             }}>
                                                                                 <img src="img/remove.svg"/>
                                                                             </button>
                                                                         </div>
                                                                     }
                                                                 </div>
                                                             </div>

                                                     </div>
                                                 }) }
                                            </div>
                                        </div>
                                    </div>
                                </LoadingOverlay>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Dashboard>
    )
}
