import { ClipLoader } from "react-spinners";
import { Form, Formik } from "formik";
import { agencySchema } from "../../schemas/agencySchema";
import { handleFormikSubmit } from "../../Helpers/formHelper";
import { agencySignUpAdapter } from "../../adapters/agencySignUpAdapter";
import { AcceptTermsCondition, Countries, CountryCode, File, FormikRecaptcha, Input, Recaptcha } from "../../Helpers/formComponents";
import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from 'react-router-dom';


export const AgencyForm = function ({ isFetching, countries, ...props }) {
    const navigate = useNavigate();

    const redirectMessage = () => {
        navigate('/', { state: { message: "Your application is waiting for approval." } });
    }
    return <>
        {isFetching ?
            <div className="row mt-5">
                <div className={'row'}>
                    <div className="col text-center">

                        <ClipLoader
                            strokeWidth="4"
                            animationDuration="2"
                            width="50"
                            visible={true}
                        />
                    </div>
                </div>
            </div>
            :
            <Formik
                validateOnBlur
                {...props}>
                {({ isValid, isSubmitting, status }) => (
                    <Form method="post" className="form-rep">
                        <h4 className="past-text">Recruıter</h4>
                        <div className="form-block n-border mb-4">
                            <div className="row">
                                <div className="col-lg-6">
                                    <Input type="text" name="first_name" placeholder="First Name (Official)" />
                                </div>
                                <div className="col-lg-6">
                                    <Input type="text" name="last_name" placeholder="Last Name (Official) " />
                                </div>
                                {/* <div className="col-lg-12">
                                    <Input type="number" name="company_id_no" placeholder="Company ID Number" />
                                </div> */}
                                <div className="col-lg-12">
                                    <Input type="email" name="email" placeholder="E-Mail" />
                                </div>
                                <div className="col-lg-6">
                                    <CountryCode options={countries?.data?.data} name={"country_code"} />

                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <Input type="tel" name="mobile" placeholder="Contact Number" />
                                    </div>
                                </div>
                                <div className="col-lg-6"></div>
                            </div>
                        </div>

                        <h4 className="past-text">company</h4>
                        <div className="form-block n-border mb-4">
                            <div className="row">
                                <div className="col-lg-12">
                                    <File name="image" label={'Logo'} />
                                </div>
                                <div className="col-lg-12">
                                    <Input type="text" name="company_name" placeholder="Company name" />
                                </div>
                                <div className="col-lg-12">
                                    <Input type="text" name="company_address" placeholder="Address" />
                                </div>

                                {/*<div className="col-lg-6">*/}
                                {/*        <Input type="text"  name="company_city" placeholder="Company City"  />*/}
                                {/*</div>*/}
                                {/*<div className="col-lg-6">*/}
                                {/*    <Input type="text"  name="company_state" placeholder="Company State"  />*/}

                                {/*</div>*/}
                                {/*<div className="col-lg-6">*/}
                                {/*        <Input type="text"  name="company_zip_code" placeholder="Zipcode"  />*/}
                                {/*</div>*/}

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <CountryCode options={countries?.data?.data} name={"company_country_code"} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <Input type="tel" name="contact_number" placeholder="Contact Number" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <Countries options={countries?.data?.data} name={"company_country_id"} />
                                </div>
                                {/*<div className="col-lg-6"></div>*/}
                            </div>
                        </div>

                        <div className="form-block n-border mb-4">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/*<AllowedToSponsor  name="allowed_to_sponsor" />*/}

                                    <div className="col-lg-12">
                                        <AcceptTermsCondition />
                                        <FormikRecaptcha/>

                                    </div>

                                    <div className="col-md-12">
                                        <motion.button
                                            className={status == 200 ? "btn_submit_dark btn btn-success" : "btn_submit_dark"}
                                            type={"submit"}
                                            whileTap={{ scale: 0.7 }}
                                            onHoverStart={e => { }}
                                            onHoverEnd={e => { }}
                                            disabled={isSubmitting}
                                        >  {status == 200 ? "Changes saved successfully" : (isSubmitting ? "Please wait..." : "SAVE ACCOUNT SETTINGS")}
                                            {status == 200 && redirectMessage()}

                                        </motion.button>
                                        {status && status == 200 ?
                                            <p className="mt-3">
                                                Your form has been submitted, One of our Agents will contact you shortly
                                            </p> : (isSubmitting ? "" :
                                                "")
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        }
    </>
}