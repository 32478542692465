import React, {useEffect} from 'react';
import {BannerContainer} from "../Common/BannerContainer";
import {TitleLeftBanner} from "../Common/TitleLeftBanner";
import {Types} from "../SignUpTypes/Types";
import {ContactForm} from "../Common/ContactForm";
import * as NclexSignUp from "../../assets/animation/Thumbnail_NursesApplyingforNCLEX-RN.json";
import * as ClincalSignUp from "../../assets/animation/Thumbnail_Clinicalandnonclinical.json";
import * as applicantSignUp from "../../assets/animation/Thumbnail_NurseswithNCLEX-RN.json";

export default function ApplicantSignUp() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <BannerContainer>
                <div className="row">
                    <div className="col-md-6">
                        <TitleLeftBanner
                            title={'Apply Form'}
                            description={'MedicStaff is here to take your nursing career to the next level.'}
                            descriptionTwo={'Fill out your application form to avail of our services.'}
                        />
                    </div>
                </div>
                <div className="row">
                    <Types
                        link={'/nurses-with-rn-signup'}
                        image={'img/IMG-nurses.png'}
                        // animationData={applicantSignUp}
                        title={'Nurses With NCLEX-RN'}
                    />
                    <Types
                        link={'/nclex-signup'}
                        image={'img/IMG-nurse-2.png'}
                        // animationData={NclexSignUp}
                        title={'Nurses Applying For NCLEX-RN'}
                    />
                    <Types
                        link={'/clincal-signup'}
                        image={'img/IMG-nurse-3.png'}
                        // animationData={ClincalSignUp}
                        title={'Clinical And Non Clinical Specialities And Looking To Immigrate'}
                    />

                </div>
            </BannerContainer>
            <ContactForm/>
        </>
    );
}



