import React from 'react';
import { BannerContainer } from '../Common/BannerContainer';
import { ContactForm } from '../Common/ContactForm';
import FooterImage from '../Common/FooterImage';
import { useQuery } from "react-query";
import { getAboutUsSettings } from "../../adapters/ForumsAdapter";

import Testimonials from './Testimonials';
import OurTeam from './OurTeams';
export default function AboutUs() {
    const { isLoading, isSuccess, data: setting } = useQuery('About_us-setting', getAboutUsSettings,
        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });


    return (
        <>
            <BannerContainer
                style={{
                backgroundImage: "url(img/about-img.png)",
                backgroundColor:"transparent",
            }}
                classNames={"about-us-mobile"}>
                <div class="row">
                    <h3 class="sigma_testimonial_title">About Us</h3>
                    <div class="col-md-6 offset-3">
                        <p class="main-color-dark text-center">
                            <div dangerouslySetInnerHTML={{ __html: setting?.data.data.AboutUs?.short_about }} />
                        </p>
                    </div>
                    <div class="pt-400">
                        <div class="white-box">
                            <div dangerouslySetInnerHTML={{ __html: setting?.data.data.AboutUs?.description_about }} />
                        </div>
                    </div>
                </div>
            </BannerContainer>
            <Testimonials></Testimonials>
            <OurTeam></OurTeam>
            <FooterImage />
            <ContactForm />
        </>
    );
}



