import { handleFormikSubmit } from "../../../Helpers/formHelper";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useAuth } from "../../../app/auth";
import React, { useEffect } from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import {
    withdrawMoney,
    getCountries,
    getTransactions
} from "../../../adapters/commonAdapter";
import { motion } from "framer-motion";
import { Dashboard } from "../../UserCommon";
import { agencyInbox } from "../../../adapters/agencyAdapter";
import { getNextPage } from "../../../Helpers/ReactQueryHelper";
import { getBookingCards } from "../../../adapters/ForumsAdapter";
import ScriptTag from "react-script-tag";
import { useNavigate } from "react-router-dom";

export function GetPaid({ type }) {
    const params = new URLSearchParams(window.location.search);
    const navigate = useNavigate();


    const [page, setPage] = React.useState(1)
    const [numbersArray, setNumbersArray] = React.useState([])


    const {
        isLoading,
        isError,
        error,
        data: transactions,
        isFetching: isFetchingtransactions,
        isPreviousData,
    } = useQuery({
        queryKey: ['transactions', page],
        queryFn: () => getTransactions(page),
        keepPreviousData: true
    })
    useEffect(() => {
        console.log(transactions, page)
        setNumbersArray(Array.from(Array(transactions?.data?.meta?.pagination?.total_pages)))
    }, [transactions, page])


    const { isFetching, data } = useQuery('referalData',
        getCountries,
        // getReferalData ,

        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });


    let { currentUser } = useAuth()

    let referralLink = process.env.REACT_APP_WEB_URL + "/refer/" + currentUser.referral_code;
    return (
        <Dashboard>

            <div className="col-md-9">
                <div className="sigma_subheader_inner style-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>Get Paid</h2>
                                <p className="paragraph-text">
                                  Check your earnings and request payouts with just a few clicks. We employ a secure payment system to ensure that all transactions are made safely and without risk.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="white-box-dash">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <h4 className="wall-text">Wallet</h4>
                                            <p className="paragraph-text mt-3">
                                                Choose your desired payment service provider from the options below for transactions.
                                            </p>
                                        </div>
                                        <div className="col-md-5"></div>
                                        <div className="col-md-2">
                                            <h1 className="span-number"><span>${currentUser?.balance}</span></h1>
                                            <p className="ava-label">Avaliable Balance</p>
                                        </div>
                                        <div className="col-md-12">
                                            <hr className="border-dark" />
                                        </div>
                                    </div>
                                    {
                                        !type &&
                                        <>
                                            {/* <ScriptTag isHydrating={true} type="text/javascript"
                                                src="https://www.paypalobjects.com/js/external/api.js"
                                                onLoad={() => {

                                                    window.paypal.use(['login'], function (login) {

                                                        login.render({
                                                            "appid": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                                            "authend": "sandbox",
                                                            "containerid": "container22",
                                                            "responseType": "id_token",
                                                            "locale": "en-us",
                                                            "scopes": "openid profile https://uri.paypal.com/payments/payouts https://uri.paypal.com/services/paypalattributes",
                                                            "buttonType": "CWP",
                                                            "buttonShape": "rectangle",
                                                            "buttonSize": "sm",
                                                            "fullPage": "true",
                                                            "returnurl": process.env.REACT_APP_PAYPAL_REDIRECT_URL
                                                        });
                                                    });
                                                }}

                                            /> */}
                                            <div className="row justify-content-center">
                                                {/* <div className="col-md-2">
                                                    <div id={'container22'}></div>
                                                </div> */}


                                                <div className="col-md-2">
                                                    <a href="#" className="btn_stripe stripe-connect"
                                                        onClick={() => {
                                                            if (window) {
                                                                const url = "https://www.sandbox.paypal.com/connect?flowEntry=static&client_id="+process.env.REACT_APP_PAYPAL_CLIENT_ID+"&scope=openid profile https://uri.paypal.com/payments/payouts https://uri.paypal.com/services/paypalattributes&response_type=id_token&redirect_uri="+process.env.REACT_APP_PAYPAL_REDIRECT_URL
                                                                window.document.location.href = url;
                                                            }
                                                        }}>
                                                        <img src="/img/pngwing.com (1).png" style={{ zoom:'200%' }}  />
                                                    </a>
                                                </div><div className="col-md-2">
                                                    <a href="#" className="btn_stripe stripe-connect"
                                                        onClick={() => {
                                                            if (window) {
                                                                const url = `https://connect.stripe.com/oauth/authorize?client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID
                                                                    }&response_type=code&scope=read_write&redirect_uri=${process.env.REACT_APP_STRIPE_REDIRECT_URL
                                                                    }&state=`
                                                                window.document.location.href = url;
                                                            }
                                                        }}>
                                                        <img src="/img/Stripe_Logo.svg" style={{ zoom:'200%' }} />
                                                    </a>
                                                </div>
                                            </div>
                                            <br />
                                        </>
                                    }
                                    {type && <>


                                        <Formik
                                            validateOnBlur
                                            initialValues={{
                                                payment_gateway: type,
                                                amount: '',
                                                code: params.get('code'),
                                            }}
                                            onSubmit={handleFormikSubmit(withdrawMoney)}
                                        >
                                            {({ isValid, isSubmitting, status, errors }) => {
                                                if (status == 200) {
                                                    navigate('/get-paid', { replace: true });
                                                    window.location.reload();

                                                }
                                                return (
                                                    <Form method="post" className="form-block n-border mb-4">

                                                        <div className="row">
                                                            <div className="col-md-6 ">
                                                                <div className="form-group">
                                                                    <Field as="select" readonly className="custom-select" disabled="true" name="payment_gateway">
                                                                        <option value={type} selected>{type}</option>
                                                                    </Field>
                                                                    <div class="mt-2">
                                                                        <ErrorMessage name="code" />
                                                                        <ErrorMessage name="amount" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <Field type="number" name="amount" required placeholder="quantity" />
                                                                    <button type="button" className="copy_button"><img
                                                                        src="/img/dollar-sign.svg" /></button>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <motion.button
                                                                    className={status == 200 ? "btn_submit_dark mt-0 btn btn-success" : "btn_submit_dark mt-0"}
                                                                    type={"submit"}
                                                                    whileTap={{ scale: 0.7 }}
                                                                    onHoverStart={e => { }}
                                                                    onHoverEnd={e => { }}
                                                                    disabled={isSubmitting}
                                                                >
                                                                    {status == 200 ? "Success" : (isSubmitting ? "Please wait..." : status >= 400 ? "an error has occurred" : "Proceed")}

                                                                </motion.button>

                                                            </div>

                                                        </div>

                                                    </Form>
                                                )
                                            }}
                                        </Formik>
                                    </>
                                    }
                                    <div className="border-green">&nbsp;</div>
                                </div>
                            </div>
                            <div className="col-md-12 mt-5">
                                <h4 className="past-text">PAST TRANSACTIONS</h4>
                                <div className="table-responsive mt-4">
                                    <table className="table table-dashboard">
                                        <thead className="thead-light border-theme">
                                            <tr>
                                                <th scope="col">DATE</th>
                                                <th scope="col">PAYMENT GATEWAY</th>
                                                <th scope="col">PAYMENT PROCESS</th>
                                                <th scope="col">AMOUNT</th>
                                                {/* <th scope="col">BILLING</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactions && transactions?.data?.data.map((trans) => {
                                                return <tr>
                                                    <th scope="row">{trans?.created_at}</th>
                                                    <td>{trans?.gateway}</td>
                                                    <td>{trans?.status}</td>
                                                    <td>$ {trans?.amount}</td>
                                                    {/* <td>
                                                                <button className="down-table"><img src="/img/download.svg" />
                                                                </button>
                                                            </td> */}
                                                </tr>
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                                <div className="d-flex paid-pagination">
                                    <div className="paid-pagination-button">
                                        {page != 1 &&
                                            < button
                                                onClick={() => setPage(old => Math.max(old - 1, 0))}
                                            ><img src={"/img/arrow-left.svg"} /> <span>Previous</span></button>
                                        }
                                    </div>

                                    < div className="pagination-number">
                                        <ul className="list-number">
                                            {numbersArray.map((val, i) => {
                                                const pageNumber = i + 1;
                                                return (
                                                    <li

                                                        onClick={() => {
                                                            if (pageNumber != page) {
                                                                setPage(pageNumber)
                                                            }
                                                        }}
                                                        className={pageNumber == transactions?.data?.meta?.pagination?.current_page ? "active" : ''}>
                                                        {pageNumber}
                                                    </li>
                                                )
                                            })
                                            }
                                        </ul>
                                    </div>

                                    <div className="paid-pagination-button">
                                        {transactions?.data?.meta?.pagination?.total_pages != 1 && page != transactions?.data?.meta?.pagination?.total_pages &&
                                            < button
                                                onClick={() => {
                                                    setPage(old => old + 1)
                                                }}
                                            // Disable the Next Page button until we know a next page is available
                                            ><span>Next </span><img src={"/img/arrow-right.svg"} /> </button>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </Dashboard >
    )
}