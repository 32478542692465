import React, {useEffect} from 'react';
import {getBase64FromUrl} from "../../../Helpers/image";
import {Link} from "react-router-dom";
import { Player,BigPlayButton } from 'video-react';
import '../../../../node_modules/video-react/dist/video-react.css';
import {useAuth} from "../../../app/auth";

export  function Banner({data}) {
    const bImage = getBase64FromUrl("img/HomepaeBGDoctors.png")
    const {currentUser,logout ,type} = useAuth();
    return (
        <div className="sigma_banner" style={{backgroundImage:`url(${bImage})`}}>
            <div className="sigma_subheader style-5">
                <div className="container">
                    <div className="sigma_subheader-inner" >
                        <h1 className="main-title">
                            {data.title}
                            {/*<span className="main-color">your future</span>.*/} <br />
                            {currentUser ?
                                <span className="main-color"> {currentUser.first_name}</span>:''
                            }
                            
                        </h1>
                        <div className="section sigma_video-sec style-2 pt-0">
                            <div className="container">
                                <div className="bg-vide-right">
                                    <img src="img/video-right.svg" className="desktop-img"/>
                                    <img src="img/video-right-mobile.svg" className="mobile-footer-img"/>
                                </div>
                                <Player
                                    playsInline
                                    poster="img/video-bg.png"
                                    src={data.video}
                                >
                                    <BigPlayButton position="center" />
                                </Player>
                                <div className="bg-vide-left">
                                    <img src="img/video-left.svg" className="desktop-img"/>
                                    <img src="img/video-left-mobile.svg" className="mobile-footer-img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 text-center">
                            <Link to="about-us" className="sigma_btn_transparent btn-sm">
                                Discover more
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

