import axios from "axios";

export function getELearningSettings() {

    return axios.get("settings", {
        params: {
            "module_name": ["ELearningDetails"]
        }
    })
}

export function getELearning(pageParam, search) {
    return axios.get('e-learning?page=' + pageParam + "&search=" + search)
}

export function getAskLawyerSettings() {

    return axios.get("settings", {
        params: {
            "module_name": ["AskLawyerDetails"]
        }
    })
}

export function getLawyers(pageParam, search) {
    return axios.get('lawyers?page=' + pageParam + "&search=" + search)
}

export function getSponsorshipSettings() {

    return axios.get("settings", {
        params: {
            "module_name": ["SponsershipImmigrationDetails"]
        }
    })
}

export function getSponsorship(pageParam, search) {
    return axios.get('sponsorship?page=' + pageParam + "&card_type=" + search)
}

export function getStatesLicensureSettings() {

    return axios.get("settings", {
        params: {
            "module_name": ["StatesLicensureDetails"]
        }
    })
}

export function getStatesLicensure(pageParam, search) {
    return axios.get('states?page=' + pageParam + "&search=" + search)
}

export function getStatesCards(pageParam, search) {
    return axios.get('states_cards?page=' + pageParam + "&search=" + search)
}

export function getLiveWorkInUSASettings() {

    return axios.get("settings", {
        params: {
            "module_name": ["LiveWorkInUSA"]
        }
    })
}

export function getLiveWorkInUSA(pageParam, search) {
    return axios.get('live-in-usa?page=' + pageParam + "&search=" + search)
}





export function getBookingSettings() {

    return axios.get("settings", {
        params: {
            "module_name": ["BookingData"]
        }
    })
}

export function getBookingCards(pageParam, type) {
    return axios.get('booking_cards?type=' + type + '&page=' + pageParam)

}
export function getBookingBanners(type) {
    return axios.get('booking_banners?type=' + type)
}
export function getTermsAndPrivacy(type) {
    return axios.get('terms_privacy')
}
export function getSystemVideo(type) {
    return axios.get("settings", {
        params: {
            "module_name": ["SystemVideos"]
        }
    })
}
export function getAboutUsSettings() {

    return axios.get("settings", {
        params: {
            "module_name": ["AboutUs"]
        }
    })
}
export function getOurTeamSettings() {

    return axios.get("settings", {
        params: {
            "module_name": ["OurTeamsDetails"]
        }
    })
}
export function getContactUsSettings() {

    return axios.get("settings", {
        params: {
            "module_name": ["ContactUs"]
        }
    })
}
export function getOurTeam(pageParam, search) {
    return axios.get('our_teams?page=' + pageParam + "&search=" + search)
}
export  function  insertAskLawyer(data) {
    return  axios.post("lawyers",data)
}