import React, {useState} from 'react';
import {BannerContainer} from "../Common/BannerContainer";
import {ContactForm} from "../Common/ContactForm";
import FooterImage from "../Common/FooterImage";
import Lottie from "lottie-react";
import * as LeftData from "../../assets/animation/AbstractDotsBgLeft.json";
import * as rightData from "../../assets/animation/AbstractDotsBgRight.json";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";


export default function AgencyDashboardInbox() {

    return (
        <>
            <BannerContainer style={{
                background: "#f7f9fc"
            }}>
                <div className="animation-left">
                    <Lottie animationData={LeftData} />
                </div>
                <div className="animation-right">
                    <Lottie animationData={rightData} />
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="dashboard-list">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="user-img-list">
                                        <img src="img/teams1.png"/>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <h4 className="welcome-text">Welcome,</h4>
                                    <h3 className="user-name">Sofia Verzan</h3>
                                    <h4 className="welcome-text">01321405</h4>
                                </div>
                            </div>
                            <hr className="border-white"/>
                            <ul className="dashboard-list-ul">
                                <li>
                                    <a href="user-dashboard.html"><img src="img/dashboard.svg" /> Dashboard</a>
                                </li>
                                <li>
                                    <a href="user-reminders.html" className="active"><img
                                        src="img/calendar.svg" /> Reminders</a>
                                </li>
                                <li>
                                    <a href="user-inbox.html"><img src="img/mail.svg" /> Inbox</a>
                                </li>
                                <li>
                                    <a href="referrals.html"><img src="img/user-add.svg" /> Referral</a>
                                </li>
                                <li>
                                    <a href="get-paid.html"><img src="img/getpaid.svg" /> Get Paid</a>
                                </li>
                                <li>
                                    <a href="account-settings-representative.html"><img
                                        src="img/chat-alt.svg" /> Account Settings</a>
                                </li>
                            </ul>
                            <hr className="border-white"/>
                            <a href="" className="logout-btn"><img src="img/logout.svg" /> Log out</a>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="sigma_subheader_inner style-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Inbox</h2>
                                        <p className="paragraph-text">
                                            Donec in est eu dui venenatis dapibus. Aenean non turpis suscipit, consequat
                                            dolor a,venenatis.
                                        </p>
                                    </div>
                                    <div className="col-md-6"></div>
                                    <div className="col-md-3">
                                        <div className="inbox-leftside">
                                            <div className="search-inbox-leftside">
                                                <form>
                                                    <i className="far fa-search"></i>
                                                    <input type="text" placeholder="Choose One"/>
                                                </form>
                                            </div>
                                            <ul className="inbox-list">
                                                <li className="active">
                                                    <div className="inbox-users-box">
                                                        <div className="inbox-users-img">
                                                            <Badge badgeContent={4} color="primary">
                                                                <Avatar alt="company icon" src={"img/teams1.png"}/>
                                                            </Badge>
                                                            {/*<img src="img/teams1.png"/>*/}
                                                        </div>
                                                       <h3 className="imbox-user-name">Company Name</h3>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="msg-container">
                                            <div className="msg_trans">
                                                <div className="msg-inner">
                                                    <div className="d-flex justify-content-start">
                                                        <div className="user-msg">
                                                            <img src="img/teams1.png"/>
                                                        </div>
                                                        <div className="receive-msg">
                                                            <p> Hi </p>
                                                            <span className="msg-time">
                                                                                    Dec 7 ,5:27 PM
                                                                                </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <div className="sender-msg">
                                                                    <span className="msg-sender-time">
                                                                        Dec 7 ,5:27 PM
                                                                    </span>
                                                            <p> In publishing and graphic design, Lorem ipsum is a
                                                                placeholder text commonly used to demonstrate the visual
                                                                form of a document or a typeface without relying on
                                                                meaningful content. Lorem ipsum may be used as a
                                                                placeholder before final copy is available. </p>
                                                        </div>
                                                        <div className="user-msg">
                                                            <img src="img/teams1.png"/>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-start">
                                                        <div className="user-msg">
                                                            <img src="img/teams1.png"/>
                                                        </div>
                                                        <div className="receive-msg">
                                                            <p> graphic design, Lorem ipsum is a placeholder text
                                                                commonly used </p>
                                                            <span className="msg-time">
                                                                                    Dec 7 ,5:27 PM
                                                                                </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <div className="sender-msg">
                                                                    <span className="msg-sender-time">
                                                                        Dec 7 ,5:27 PM
                                                                    </span>
                                                            <p> Lorem ipsum is a placeholder text commonly used </p>
                                                        </div>
                                                        <div className="user-msg">
                                                            <img src="img/teams1.png"/>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-start">
                                                        <div className="user-msg">
                                                            <img src="img/teams1.png"/>
                                                        </div>
                                                        <div className="receive-msg">
                                                            <p> In publishing and graphic design, Lorem ipsum is a
                                                                placeholder text commonly used </p>
                                                            <span className="msg-time">
                                                                                    Dec 7 ,5:27 PM
                                                                                </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <div className="sender-msg">
                                                                    <span className="msg-sender-time">
                                                                        Dec 7 ,5:27 PM
                                                                    </span>
                                                            <p> In publishing and graphic designd </p>
                                                        </div>
                                                        <div className="user-msg">
                                                            <img src="img/teams1.png"/>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-start">
                                                        <div className="user-msg">
                                                            <img src="img/teams1.png"/>
                                                        </div>
                                                        <div className="receive-msg">
                                                            <p> graphic design </p>
                                                            <span className="msg-time">
                                                                                    Dec 7 ,5:27 PM
                                                                                </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <div className="sender-msg">
                                                                    <span className="msg-sender-time">
                                                                        Dec 7 ,5:27 PM
                                                                    </span>
                                                            <p> Hello </p>
                                                        </div>
                                                        <div className="user-msg">
                                                            <img src="img/teams1.png"/>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <div className="sender-msg">
                                                                    <span className="msg-sender-time">
                                                                        Dec 7 ,5:27 PM
                                                                    </span>
                                                            <p> Lorem ipsum is a placeholder text commonly used </p>
                                                        </div>
                                                        <div className="user-msg">
                                                            <img src="img/teams1.png"/>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-start">
                                                        <div className="user-msg">
                                                            <img src="img/teams1.png"/>
                                                        </div>
                                                        <div className="receive-msg">
                                                            <p> publishing graphic design used </p>
                                                            <span className="msg-time">
                                                                                    Dec 7 ,5:27 PM
                                                                                </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <div className="sender-msg">
                                                                    <span className="msg-sender-time">
                                                                        Dec 7 ,5:27 PM
                                                                    </span>
                                                            <p> Lorem ipsum is a placeholder text commonly used </p>
                                                        </div>
                                                        <div className="user-msg">
                                                            <img src="img/teams1.png"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="msg-footer">
                                                <div className="container">
                                                    <form className="row">
                                                        <div className="col-md-1 p-0 text-center">
                                                                    <span className="btn_upload_msg">
                                                                        <input type="file" title=""
                                                                               className="input-img"/>
                                                                        <img src="img/attachment.svg"/>
                                                                    </span>
                                                        </div>
                                                        <div className="col-md-8 p-0">
                                                            <input type="text" placeholder="Write your message"/>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <button className="btn_send_msg">
                                                                Send
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BannerContainer>
            <FooterImage/>
            <ContactForm/>
        </>
    );
}



