import {Dashboard} from "../../../UserCommon";
import Modal from "react-modal";
import React from "react";
import {useInfiniteQuery, useMutation, useQuery} from "react-query";
import {changeApplicationStatus, getReminders} from "../../../../adapters/nurseAdapter";

import Clock from "./Clock";
import moment from "moment-timezone";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        // maxWidth: "400px",
        maxWidth: "600px",
        minWidth: "320px",

        transform: 'translate(-50%, -50%)',
        backgroundColor:"#fff",

    },
    overlay: {
        zIndex: 10000,
        backgroundColor: 'rgba(70, 70, 70, 0.5)',

    }

};
Modal.setAppElement('#root2');

export function Reminders(){
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalData, setModalData] = React.useState(null);
    const {  refetch,isFetching,data } = useQuery(['reminders'], getReminders ,
        {
            cacheTime : 0,
            staleTime : 0,
            refetchOnWindowFocus: false, // default: true
            refetchOnMount:false,
        });
    const mutation = useMutation(changeApplicationStatus,{
        onSuccess: (data) => {
            refetch()
            closeModal();
        },
    })
    function openModal(value) {
        // console.log(value);
        let stillUtc = moment.utc(value?.schedule_time?.date+" "+value?.schedule_time?.time).toDate();
        let local = moment(stillUtc).local();
        let date = local.format('dddd, MMMM D, h:mm a z')
        setModalData({...value,date:date})
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    // let deadline = "March, 29, 2023";

    return (
        <Dashboard>
            <div className="col-md-9">
                <div className="sigma_subheader_inner style-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>Reminders</h2>
                                <p className="paragraph-text">
                                    Stay on top of your schedule. Get notifications for all your scheduled meetings and appointments in one place.
                                </p>
                            </div>
                            <div className="col-md-12">
                                <ul className="reminder-list desktop-img">
                                    {data?.data?.data.map(function(value){

                                        var stillUtc = moment.utc(value?.schedule_time?.date+" "+value?.schedule_time?.time).toDate();
                                        var local = moment(stillUtc).local();


                                       return <li className="row">
                                            <div className="col-md-2">
                                                <div className="time-box">
                                                    <h4>
                                                        {local.format('D MMMM Y')}
                                                    </h4>
                                                    <h5>{local.format('hh:mm a')}</h5>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="reminder-note"  onClick={()=>openModal(value)}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h3 className="company-reminder">
                                                                {value?.agency?.details?.company_name}
                                                            </h3>
                                                            <h4>Jitsu Meeting interview</h4>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Clock deadline={local.format('YYYY-MM-DDTHH:mm:ss.sssZ')} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                    })}
                                </ul>
                                <ul className="reminder-list-mobile mobile-footer-img">
                                    {data?.data?.data.map(function(value){
                                        var stillUtc = moment.utc(value?.schedule_time?.date+" "+value?.schedule_time?.time).toDate();
                                        var local = moment(stillUtc).local();


                                        return <li>
                                            <div className="reminder-note" onClick={()=>openModal(value)}>
                                                <div className="time-box">
                                                    <h4>
                                                        {local.format('D MMMM Y')}
                                                    </h4>
                                                    <h5>{local.format('hh:mm a')}</h5>
                                                </div>
                                                <h3 className="company-reminder">
                                                    {value?.agency?.details?.company_name}
                                                </h3>
                                                <h4>Google Meeting interview</h4>
                                                <Clock deadline={local.format('YYYY-MM-DDTHH:mm:ss.sssZ')} />
                                            </div>
                                        </li>
                                    })}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                backdropColor="transparent"
                shouldCloseOnOverlayClick={false}
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <button onClick={closeModal} className="btn_close_modal"><img src="img/remove.svg" /></button>
                <h3 className="title-meeting">Meeting scheduled</h3>
                <p className="des-meeting">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus fringilla arcu et venenatis hendrerit. Orci varius natoque pen.
                </p>
                <div className="col-md-12">
                   <ul className="list-calendar">
                       <li>
                           <h4 className="list-calendar-title">
                               <img src="img/calendar-dark.svg" />
                               Meeting with {modalData?.agency?.details?.company_name}
                           </h4>
                       </li>
                       <li>
                           <h4 className="list-calendar-title red">
                               <img src="img/clock-red.svg" />
                               {modalData?.date}
                           </h4>
                       </li>
                       <li>
                           <h4 className="list-calendar-title">
                               <img src="img/time-dark.svg" />
                               60 minutes
                           </h4>
                       </li>
                       <li>
                           <h4 className="list-calendar-title">
                               <img src="img/pin-dark.svg" />
                               {modalData?.agency?.details?.country?.name}
                           </h4>
                       </li>
                    </ul>
                    <button onClick={() => {
                        window.location.href = modalData?.meeting_data?.meeting_link
                    }} className="btn_submit_dark">Join Meeting <img src='img/Desktop-screen.svg'/></button>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <button onClick={()=>mutation.mutate({action_status : "rescheduled",'id':modalData.id})} className="btn-white-dark w-100 p-2 mt-1">Reschedule <img src="img/reschedule.svg"/> </button>
                    </div>
                    <div className="col-md-6">
                        <button onClick={()=>mutation.mutate({action_status : "cancelled",'id':modalData.id})} className="btn-white-dark w-100 p-2 mt-1" >Cancel <img src="img/cancel.svg"/> </button>
                    </div>
                </div>
            </Modal>
        </Dashboard>
    )
}