import React from "react";
import Lottie from "lottie-react";

export default function Banner({paragraph, title,subTitle, image,animationData}) {


    return (
        <div className="row">
            <div className="col-lg-6 col-md-6 mt-127">
                <span className="subtitle-span">{subTitle}</span>
                <h2>{title}</h2>
                <Lottie animationData={animationData} style={{height:"541px"}} className="mobile-img" />
                <p className="paragraph-text">
                    <div dangerouslySetInnerHTML={{__html: paragraph}} />

                </p>
            </div>
            <div className="col-lg-6 col-md-6 text-center">
                {/*<img src={image} alt="img" height="541"/>*/}
                <Lottie animationData={animationData} style={{height:"541px"}} className="desktop-img" />
            </div>
        </div>
    )
}
