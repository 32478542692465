import React,{useEffect} from "react";
import {TitleLeftBanner} from "./TitleLeftBanner";
import {BannerContainer} from "./BannerContainer";
import {ContactForm} from "./ContactForm";
import { Player,BigPlayButton } from 'video-react';
import '../../../node_modules/video-react/dist/video-react.css';

export function SignUpContainerVideo({children,formTitle,formDescription,formSpan , video }) {
    return (
       <>
           <BannerContainer
               style={{
                   backgroundPosition: "right",
                   backgroundImage:"url(img/GradientBackground.png)",
               }}>
            <div className="row">
                <div className="col-lg-6 col-md-6 text-center mobile-footer-img">
                    <div className="section sigma_video-sec video-signup style-2 pt-0 mt-0">
                        <div className="container">
                            <div className="bg-vide-right">
                                <img src="img/video-right.svg" className="desktop-img"/>
                                <img src="img/video-right-mobile.svg" className="mobile-footer-img"/>
                            </div>
                            <Player
                                playsInline
                                poster="img/video-bg.png"
                                src={video ?? ""}
                            >
                                <BigPlayButton position="center" />
                            </Player>
                            <div className="bg-vide-left">
                                <img src="img/video-left.svg" className="desktop-img"/>
                                <img src="img/video-left-mobile.svg" className="mobile-footer-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 offset-md-3 text-center">
                    <TitleLeftBanner
                        title={formTitle}
                        subTitle={formSpan}
                        description={formDescription}
                    />
                </div>
                <div className="col-md-6">
                    {children}
                </div>
                <div className="col-lg-6 col-md-6 text-center desktop-img">
                    <div className="section sigma_video-sec video-signup style-2 pt-0 mt-0">
                        <div className="container">
                            <div className="bg-vide-right">
                                <img src="img/video-right.svg" className="desktop-img"/>
                                <img src="img/video-right-mobile.svg" className="mobile-footer-img"/>
                            </div>
                            <Player
                                playsInline
                                poster="img/video-bg.png"
                                src={video ?? ""}
                            >
                                <BigPlayButton position="center" />
                            </Player>
                            <div className="bg-vide-left">
                                <img src="img/video-left.svg" className="desktop-img"/>
                                <img src="img/video-left-mobile.svg" className="mobile-footer-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BannerContainer>
        <ContactForm/>
       </>
    )
}

