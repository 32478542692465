import React from "react";
import {insertContactUs} from "../adapters/commonAdapter";
import {useMutation} from "react-query";

export function onSuccessDefaultAlert(data,success_message=null) {
    data = data.hasOwnProperty('response') ? data.response :data

    if(data.status == 422) {
        return {"text": data.data.message, "class": "alert-danger"}
    }else{
        return {"text" : success_message || data.data.message, "class" : "alert-success"}

    }
}

export function onErrorDefaultAlert(error) {
    return {"text" : error.response.data.message, "class" : "alert-danger"}
}
export function onMutate() {
    return {"text" : "sending...", "class" : "alert-dark"}
}

export function formMutationOptions (setMessage,success_message=null){
    return {
        onSuccess: (data) => {
            setMessage(onSuccessDefaultAlert(data,success_message))
        },
            onError: (error) => {
        setMessage(onErrorDefaultAlert(error))
        },
        //     onMutate: () => {
        //     setMessage(onMutate())
        // }
    }
}


export  const getNextPage = (lastPage) =>{
    if(lastPage.meta.pagination.current_page === lastPage.meta.pagination.total_pages){
        return undefined;
    }else{
        return lastPage.meta.pagination.current_page + 1;
    }
}

