import React from "react";

export function TitleOnlyBanner({title,description}){
    return (

        <div className="row">
            <h3 className="sigma_testimonial_title">{title}</h3>
            <div className="col-md-6 offset-3">
                <p className="main-color-dark text-center">
                    
                    <div dangerouslySetInnerHTML={{__html: description}} />
                </p>
            </div>
        </div>

    )
}