import React, {useEffect, useState} from 'react';

import { useSearchParams } from "react-router-dom";
import {ClipLoader} from "react-spinners";
import axios from "axios";
import {docusignCodeVerification} from "../../adapters/commonAdapter";
import {useNavigate} from "react-router-dom";
import Splash from "../../layout/core/Splash";



export default function DocusignAccess() {
    const [searchParams] = useSearchParams();
    const [status,setStatus] = useState("pending")
    const navigate = useNavigate();

    console.log(searchParams);
    useEffect(function(){
       docusignCodeVerification( searchParams.get('code')).then(()=>{
           navigate("/dashboard");
       }).catch(()=>{
           console.log("error")

       });
    },[])

    return <Splash/>
}
