import {CountUp} from "use-count-up";
import React from "react";

export function Counter({data}) {
    return (

        <div className="counter-bg">
            <div className="container">
                <div className="sigma_info-wrapper style-26 mb-5" style={{backgroundImage:"img/counter-bg.png"}}>
                    <div className="sigma_counter">
                        <div className="row">
                            <div className="col-md-2 border-right">
                                <div className="sigma_counter style-5">
                      <span>
                        <b className="counter" ><CountUp  isCounting end={parseInt(data.counter_no_1)} duration={4} /></b>
                        <span className="plus">+</span>
                      </span>
                                    <p>{data.counter_title_1}</p>
                                </div>
                            </div>
                            <div className="col-md-2 border-right">
                                <div className="sigma_counter style-5">
                      <span>
                        <b className="counter"><CountUp  isCounting end={parseInt(data.counter_no_2)} duration={4} /></b>
                        <span className="plus">+</span>
                      </span>
                                    <p>{data.counter_title_2}</p>
                                </div>
                            </div>
                            <div className="col-md-2 border-right">
                                <div className="sigma_counter style-5">
                      <span>
                        <b className="counter"><CountUp  isCounting end={parseInt(data.counter_no_3)} duration={4} /></b>
                        <span className="plus">+</span>
                      </span>
                                    <p>{data.counter_title_3}</p>
                                </div>
                            </div>
                            <div className="col-md-2 border-right">
                                <div className="sigma_counter style-5">
                      <span>
                        <b className="counter"><CountUp  isCounting end={parseInt(data.counter_no_4)} duration={4} /></b>
                        <span className="plus">+</span>
                      </span>
                                    <p>{data.counter_title_4}</p>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="sigma_counter style-5">
                      <span>
                        <b className="counter"><CountUp  isCounting end={parseInt(data.counter_no_5)} duration={4} /></b>
                        <span className="plus">+</span>
                      </span>
                                    <p>{data.counter_title_5}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

