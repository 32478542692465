import React from 'react';
import {TitleLeftBanner} from "../Common/TitleLeftBanner";
import {BannerContainer} from "../Common/BannerContainer";
import {Types} from "./Types";
import {ContactForm} from "../Common/ContactForm";
import * as AngencySignUp from '../../assets/animation/Thumbnail_AgencySignUp.json';
import * as applicantSignUp from '../../assets/animation/Thumbnail_NurseswithNCLEX-RN.json';
import * as representativeSingUp from '../../assets/animation/Thumbnail_Representative_Sign_Up.json';


export default function SignUpTypes() {
    return (
        <>
            <BannerContainer style={{
                    backgroundImage: "url(img/RegisterBackground.png)",
                    backgroundPosition: "inherit",
                    backgroundSize: "cover",
                    padding: "200px 0px",
                }}>
                    <div className="row">
                        <div className="col-md-6">
                            <TitleLeftBanner
                                title={'Create an account'}
                                description={'Start your journey with MedicStaff by signing up today.'}
                                descriptionTwo={'Choose the account type that suits you and create your profile now.'}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <Types
                            link={'/agency-signup'}
                            image={'img/agency-img.png'}
                           // animationData={AngencySignUp}
                            title={'Agency'}
                        />
                        <Types
                            link={'/applicant-signup'}
                            image={'img/applicant-img.png'}
                            //animationData={applicantSignUp}
                            title={'Applicant'}
                        />
                        <Types
                            link={'/representative-signup'}
                             image={'img/representative-img.png'}
                            // animationData={representativeSingUp}
                            title={'Representative'}
                        />

                    </div>
            </BannerContainer>
            <ContactForm/>
        </>
    );
}



