import React from "react";
import {Dashboard} from "../../UserCommon";
import {
    Countries,
    CountryCode,
    File,
    FormikCheckBox,
    Gender,
    Input,
    Password,
    TextArea
} from "../../../Helpers/formComponents";
import {Form, Formik} from "formik";


export function RepresentativeEditAccount(){

    return (
        <Dashboard>
            <div className="col-md-9">
                <div className="sigma_subheader_inner style-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Representative Account Sittings</h2>
                                <p className="paragraph-text">
                                    Donec in est eu dui venenatis dapibus. Aenean non turpis suscipit, consequat dolor
                                    a,venenatis.
                                    ean non turpis suscipit, consequat dolor a,venenatis.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                               <Formik>
                                    <Form method="post" className="form-rep">
                                        <h4 className="past-text">personal information</h4>
                                        <div className="form-block n-border mb-4">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Input type="text" name="first_name" placeholder="First Name (Official)" />
                                                </div>
                                                <div className="col-lg-6">
                                                    <Input type="text" name="last_name" placeholder="Last Name (Official)" />
                                                </div>
                                                <div className="col-lg-12">
                                                    <Input  type="email" name="email" placeholder="E-Mail"  />
                                                </div>
                                                <div className="col-lg-6">
                                                    <Gender  name="gender" />
                                                </div>
                                                <div className="col-lg-6"></div>
                                                <div className="col-lg-3">
                                                    {/*<CountryCode  name={"country_code"}/>*/}
                                                </div>
                                                <div className="col-lg-3">
                                                    <Input  type="tel"  name="mobile" placeholder="Contact Number"   />
                                                </div>
                                                <div className="col-lg-6"></div>
                                                <div className="col-lg-6">
                                                    <File name={"image"} label={"Profile photo"}/>
                                                </div>
                                            </div>
                                        </div>

                                        <h4 className="past-text">Securıty <span>(Change password)</span></h4>
                                        <div className="form-block n-border mb-4">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Password  name="current_password" placeholder="Current Password" />

                                                </div>
                                                <div className="col-lg-6">
                                                    <Password  name="password" placeholder="New Password" />

                                                </div>
                                            </div>
                                        </div>

                                        <h4 className="past-text">Address information</h4>
                                        <div className="form-block n-border mb-4">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <Input type="text" name="address_one" placeholder="Address 1" />
                                                </div>
                                                <div className="col-lg-12">
                                                    <Input type="text" name="address_two" placeholder="Address 2" />
                                                </div>
                                                <div className="col-lg-6">
                                                    {/*<Countries options={countries?.data?.data } name={"country_id"} />*/}
                                                </div>
                                                <div className="col-lg-6">
                                                    {/*<City options={countries?.data?.data } name={"country_id"} />*/}
                                                </div>
                                                <div className="col-lg-12">
                                                    <hr className="m-2" />
                                                </div>
                                                <div className="col-lg-12">
                                                    <TextArea placeholder="Services you Provide" style={{height:'100px'}}>
                                                    </TextArea>
                                                </div>
                                                <div className="col-lg-12">
                                                    <TextArea placeholder="Bio" style={{height:'100px'}}>
                                                    </TextArea>
                                                </div>
                                                <div className="col-lg-6">
                                                    {/*<FormikCheckBox  name={'req_more_countries'} label='Request More Countries'/>*/}
                                                </div>
                                                <div className="col-lg-12">
                                                    <Input type="text" name="suggestions" placeholder="Write your country suggestions" />
                                                </div>
                                            </div>
                                        </div>

                                        <h4 className="past-text">permissions and notices</h4>
                                        <div className="form-block n-border mb-4">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    {/*<FormikCheckBox  name={'offer_mail'} label='I would like to receive emails regarding offers.'/>*/}
                                                </div>
                                                <div className="col-lg-6"></div>
                                                <div className="col-lg-6">
                                                    {/*<FormikCheckBox  className={"d-flex align-items-center mt-4"} name={'newsletter'} */}
                                                    {/*                 label='I would like to receive newsletter.'/>*/}
                                                </div>
                                                <div className="col-lg-6"></div>
                                                <div className="col-md-6">
                                                    <button className="btn_submit_dark">SAVE ACCOUNT SETTINGS</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                               </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dashboard>
    )
}