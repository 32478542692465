import axios from "axios";
import {addUserDataToLocalStorage, useAuth} from "../app/auth";

export  function  nurseSignUpAdapter(data) {
    return  axios.post("nurse_nclex_register",data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(res => {
        res = res.hasOwnProperty('response') ? res.response :res

        if(res.status == 200) {

            addUserDataToLocalStorage(res.data.data, res.data.data.access_token,"/");
        }
        return res
        //reload page
    });
}
export  function  Login(data) {
    return  axios.post("applicant_login",data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(res => {
        res = res.hasOwnProperty('response') ? res.response :res

        if(res.status == 200) {
            addUserDataToLocalStorage(res.data.data, res.data.data.access_token);
        }
        return res
        //reload page
    });
}
export  function  getAvailability(startTime,timezone) {
    // console.log(startTime.toLocaleDateString());
    return axios.get("availability?date="+startTime+"&timezone="+timezone)

}
export  function  nurseInbox(pageParam,chatId) {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone ;
    // console.log(startTime.toLocaleDateString());
    return axios.get("ChatMessages/"+chatId+"?include=sender&&timezone="+timezone+"&page="+pageParam);

}
export  function  SendMessage(callback,chatId) {

    return function (data){
        axios.post("sendMessage",data).then(callback);
    }
}
export  function  getCurrentDate(data) {
    return axios.get("user_dates?timezone="+data)

}
export  function  getChats() {
    return axios.get("chats?include=agency.details,messagesNotReadedByNurseCount")

}

export  function  Verify(data) {
    return  axios.post("email_verification",data).then(res => {
        //reload page
        // console.log(res.response.status);
        res = res.hasOwnProperty('response') ? res.response :res
        if(res.status == 200){
         window.location.reload();
        }
        return res;
    });
}
export  function  insertAvailability(data) {

    return  axios.post("availability",data);
}
export  function  updateProfile(data) {
    return  axios.post("account_settings",data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export  function  rateAgency(data) {
    return  axios.post("rate_agency",data).then(res => {
        //reload page
        // console.log(res.response.status);
        res = res.hasOwnProperty('response') ? res.response :res
        if(res.status == 200){
            window.location.reload();
        }
        return res;
    });
}

export  function  rateRepresentative(data) {
    return  axios.post("rate_representative",data).then(res => {
        //reload page
        // console.log(res.response.status);
        res = res.hasOwnProperty('response') ? res.response :res
        if(res.status == 200){
            window.location.reload();
        }
        return res;
    });
}
export function getAgencies(pageParam) {
    return axios.get("agency_list?include=details,active_job_application&page=" + pageParam)
}
export function getReminders(pageParam) {
    return axios.get("reminders?include=schedule_time,agency.details.country")
}
export function getSubmitedAgency() {
    return axios.get("submitted_agency_list?include=details" )
}
export  function  changeApplicationStatus(data,id) {
    return  axios.delete("availability/"+data.id, {data:data})
}