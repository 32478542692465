import React from 'react';
import { BannerContainer } from '../Common/BannerContainer';
import { ContactForm } from '../Common/ContactForm';
import Lottie from "lottie-react";
import * as LeftData from "../../assets/animation/AbstractDotsBgLeft.json";
import * as rightData from "../../assets/animation/AbstractDotsBgRight.json";
import {Link} from "react-router-dom";


export default function Error() {


    return (
        <>
            <BannerContainer style={{
                background: "#fff"
            }}>
                <div className="animation-left">
                    <Lottie animationData={LeftData} />
                </div>
                <div className="animation-right">
                    <Lottie animationData={rightData} />
                </div>
                <div className="row">
                    <div className="col-md-6 offset-3">
                        <h3 className="sigma_testimonial_title">Oops, Page Not Found</h3>
                        <h1 className="error-title">404</h1>
                        <Link className="btn_submit_dark" to='/'> Back to Homepage</Link>
                    </div>
                </div>
            </BannerContainer>
            <ContactForm />
        </>
    );
}



