import React from "react";
import Lottie from "lottie-react";
import * as animationData from '../../../assets/animation/HomepageSection2.json';

export function SectionOne ({data}){

    return (

        <div className="section container">
            <div className="row">
                <div className="col-md-5 text-center">
                    <div className="about-home">
                        <h2 className="text-white">
                            {data.title}
                        </h2>
                        <p className="text-white">
                            {/*{data.description}*/}
                            <div dangerouslySetInnerHTML={{ __html: data.description }} />

                        </p>
                    </div>
                </div>
                <div className="col-md-7 text-center">
                    {/*<img src="img/about-home-bg.png" />*/}
                    <Lottie animationData={animationData} style={{height:"400px"}} />
                    {/*<img alt='img' src={data.image} height="400" className="img-home"/>*/}
                </div>
            </div>
        </div>

    );
}
