import React, { useState } from 'react';
import { SignUpContainer } from "../Common/SignUpContainer";
import { handleFormikSubmit } from "../../Helpers/formHelper";
import { useQuery } from "react-query";
import { getCountries } from "../../adapters/commonAdapter";
import { nurseApplyingRegister } from "../../adapters/applicantSignUpAdapter";
import { ClipLoader } from "react-spinners";
import { AcceptTermsCondition, Countries, CountryCode, Date, File, FormikRecaptcha, Gender, Input, Recaptcha } from "../../Helpers/formComponents";
import { Formik, Form } from 'formik';
import { applicantSchema } from "../../schemas/applicantSchema";
import { motion } from "framer-motion";
import * as animationData from '../../assets/animation/Apply_2.json';
import ChooseRepresentative from "../AgencyDashboard/ChooseRepresentative";


export default function NclexSignUp() {



    const { isFetching, data: countries } = useQuery('country', getCountries,
        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });


    const [showChooseRepresentative, setShowChooseRepresentative] = useState(false);
    return (
        <>
            <Formik
                validateOnBlur
                initialValues={{
                    first_name: '',
                    last_name: '',
                    looking_to_immigrate_date_of_birth: '',
                    gender: '',
                    email: '',
                    country_id: '',
                    country_code: '',
                    mobile: '',
                    address: '',
                    resume: null,
                    image: null,
                    "g-recaptcha-response": "",
                }}
                validationSchema={applicantSchema}
                onSubmit={handleFormikSubmit(nurseApplyingRegister,function (errors){
                        if(errors.hasOwnProperty('representative_id') && Object.keys(errors).length  == 1){
                            setShowChooseRepresentative(true);
                        }
                    },
                    function (){
                        window.scrollTo(0, 0)
                        //wait for 2 seconds
                        setTimeout(function (){
                            //redirect to home page
                            window.location.href = "/";
                        }
                        ,2000)
                    }
                )
                }
            >

                {({ isValid, isSubmitting, status ,setFieldValue,submitForm,getFieldProps}) => (
                    <Form method="post">
            {showChooseRepresentative && <ChooseRepresentative isSubmitting={isSubmitting} status={status} setFieldValue={setFieldValue} getFieldProps={getFieldProps} submitForm={submitForm}/>}
            {!showChooseRepresentative && <SignUpContainer
                animationData={animationData}
                formTitle={'Apply Form'}
                formDescription={'Fill out the required information below and get assistance from our representatives for all needed documents and exam preparation.'}
            >
                {isFetching ?
                    <div className="row mt-5">
                        <div className={'row'}>
                            <div className="col text-center">

                                <ClipLoader
                                    strokeWidth="4"
                                    animationDuration="2"
                                    width="50"
                                    visible={true}
                                />
                            </div>
                        </div>
                    </div>
                    :
<>
                                <div className="form-block n-border">
                                    <div className="row">
                                        <div className="col-lg-6">

                                            <Input type="text" name="first_name" placeholder="First Name (Official)" />
                                        </div>
                                        <div className="col-lg-6">
                                            <Input type="text" name="last_name" placeholder="Last Name (Official) " />
                                        </div>
                                        <div className="col-lg-12">

                                            <Date label="Date of birth (Optional)" name={'looking_to_immigrate_date_of_birth'} />
                                        </div>
                                        <div className="col-lg-12">
                                            <Gender name="gender" />
                                        </div>
                                        <div className="col-lg-12">
                                            <Input type="email" name="email" placeholder="E-Mail" />
                                        </div>
                                        <div className="col-lg-12">
                                            <Countries options={countries?.data?.data} name={"country_id"} />
                                        </div>
                                        <div className="col-lg-5">
                                            <CountryCode options={countries?.data?.data} name={"country_code"} />
                                        </div>
                                        <div className="col-lg-7">
                                            <Input type="tel" name="mobile" placeholder="Contact Number" />
                                        </div>
                                        <div className="col-lg-12">
                                            <Input type="text" name="address" placeholder="Address" />
                                        </div>
                                        <div className="col-lg-12">
                                            <File name="resume" label={'Upload Resume'} />
                                        </div>
                                        <div className="col-lg-12">
                                            <File name="image" label={'Image'} />
                                        </div>
                                    </div>
                                </div>
                                <AcceptTermsCondition />
                                <FormikRecaptcha />
                                <div className="col-md-12">
                                    <motion.button
                                        className={status == 200 ? "btn_submit_dark btn btn-success" : "btn_submit_dark"}
                                        type={"submit"}
                                        whileTap={{ scale: 0.7 }}
                                        onHoverStart={e => { }}
                                        onHoverEnd={e => { }}
                                        disabled={isSubmitting}
                                    >  {status == 200 ? "Success" : (isSubmitting ? "Please wait..." : "Submit")}
                                    </motion.button>
                                </div>
</>

                }

            </SignUpContainer>
            }
                    </Form>
                )}
            </Formik>
        </>
    );
}



