import {handleFormikSubmit,handleRequest} from "../../../Helpers/formHelper";
import {editCandidate,showCandidate} from "../../../adapters/representativeAdapter";

import {Form, Formik} from "formik";
import {useAuth} from "../../../app/auth";
import {
    Countries,
    CountryCode,
    File,
    FormikCheckBox,
    Gender,
    BooleanRadio,
    Input,
    Date,

    Password
} from "../../../Helpers/formComponents";
import React from "react";
import {useQuery} from "react-query";
import {getCountries} from "../../../adapters/commonAdapter";
import {motion} from "framer-motion";
import {Dashboard} from "../../UserCommon";
import {updateCandidateSchema} from "../../../schemas/updateCandidateSchema";
import { useParams } from 'react-router-dom';
import moment from "moment-timezone";

export function EditRepresentativeCandidates(){
    const { isFetching, data:countries } = useQuery('country', getCountries ,
        {
            cacheTime : 1000 * 60 * 60 * 24,
            staleTime : 1000 * 60 * 60 * 12,
        });
    const { candidateToken } = useParams();
    const { data: candidate, isLoading, error } = useQuery(['candidate', candidateToken], () => showCandidate(candidateToken), {
        cacheTime: 1000 * 60 * 60, // cache for 1 hour
        staleTime: 1000 * 60 * 5, // show stale data for up to 5 minutes
      });
    

      // console.log(currentTimezone);
      const now = moment().format('YYYY-MM-DD');
    return (
        <Dashboard>

            <div className="col-md-9">
                <div className="sigma_subheader_inner style-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Edit Candidate</h2>
                                <p className="paragraph-text">
                                    Donec in est eu dui venenatis dapibus. Aenean non turpis suscipit, consequat dolor
                                    a,venenatis.
                                    ean non turpis suscipit, consequat dolor a,venenatis.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">

                            { !isFetching && !isLoading && <Formik
                                    validateOnBlur
                                    initialValues={{
                                        first_name: candidate?.data?.first_name,
                                        last_name: candidate?.data?.last_name,
                                        email: candidate?.data?.email,
                                        gender: candidate?.data?.gender,
                                        country_code: candidate?.data?.country_code,
                                        mobile: candidate?.data?.mobile,
                                        country_id: candidate?.data?.country_id ??"",
                                        image: null,
                                        address: candidate?.data?.address??"",
                                        looking_to_immigrate_date_of_birth:candidate?.data?.looking_to_immigrate_date_of_birth ??"",
                                        resume:null,
                                        field_of_study: candidate?.data?.field_of_study ?? "",
                                        graduation_year:candidate?.data?.graduation_year ?? "",
                                        is_att_received:candidate?.data?.is_att_received ?? "",
                                        test_date:candidate?.data?.test_date ?? "",
                                        is_passed:candidate?.data?.is_passed ?? "",
                                        license_no:candidate?.data?.license_no ?? ""

                                    }}
                                    validationSchema={updateCandidateSchema}
                                    onSubmit={(values, { setSubmitting, setStatus, setErrors }) => {
                                        editCandidate(candidateToken, values).then(function (res){
                                                handleRequest (res,{setErrors,setStatus,setSubmitting})
                                                window.location.href = "/dashboard";
                                            }).catch(function (res){
                                                handleRequest (res,{setErrors,setStatus,setSubmitting})
                                            })
                                      
                                      }}
                                >
                                    {({isValid, isSubmitting , status,getFieldProps,setFieldValue}) => {
                                console.log(isValid, isSubmitting , status) 
                                        return (
                                            <Form method="post" className="form-rep">
                                                <h4 className="past-text">Personal information</h4>
                                                <div className="form-block n-border mb-4">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <Input type="text"   name="first_name" placeholder="First Name (Official)"  />

                                                        </div>
                                                        <div className="col-lg-6">
                                                            <Input type="text"    name="last_name" placeholder="Last Name (Official) "  />
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <Input  type="email"   name="email" placeholder="E-Mail"  />

                                                        </div>
                                                        <div className="col-lg-6">
                                                            <Gender  name="gender" />
                                                        </div>
                                                       <div className="col-lg-6"></div>
                                                        <div className="col-lg-3">
                                                            <CountryCode options={countries?.data?.data } name={"country_code"}/>

                                                        </div>
                                                        <div className="col-lg-3">
                                                            <Input  type="tel"  name="mobile" placeholder="Contact Number"   />
                                                        </div>
                                                        <div className="col-lg-6"></div>
                                                        <div className="col-lg-6">
                                                            <Date label="Date of birth (Optional)" name={'looking_to_immigrate_date_of_birth'}/>
                                                        </div>
                                                        <div className="col-lg-6"></div>
                                                        <div className="col-lg-6">
                                                            <File name={"image"} label={"Profile photo"}/>

                                                        </div>
                                                    </div>
                                                </div>

                                                <h4 className="past-text">Address information</h4>
                                                <div className="form-block n-border mb-4">
                                                    <div className="row">

                                                        <div className="col-lg-6">
                                                            <Countries options={countries?.data?.data } name={"country_id"} />
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <Input  type="text" name="address" placeholder="Address"  />
                                                        </div>
                                                    </div>
                                                </div>

                                                <h4 className="past-text">Other </h4>
                                                <div className="form-block n-border mb-4">
                                                    <div className="row">
                                                    <div className="col-lg-6">
                                                            <Input  type="text" name="field_of_study" placeholder="Field of study"  />
                                                        </div>
                                                        <div className="col-lg-6"></div>
                                                       

                                                        <div className="col-lg-6">
                                                            <Date label="graduation year" name={'graduation_year'}/>
                                                        </div>
                                                        <div className="col-lg-6"></div>
                                                        <div className="col-lg-6 mb-5">
                                                            <BooleanRadio  name="is_att_received" title="ATT riceived"/>

                                                        </div>
                                                        <div className="col-lg-6"></div>
                                                        <div className="col-lg-6">
                                                            <Date label="test  Date" name={'test_date'}/>
                                                        </div>
                                                        { getFieldProps("test_date").value && getFieldProps("test_date").value < now ?
                                                        
                                                    <>
                                                        <div className="col-lg-6"></div>
                                                        <div className="col-lg-6 mb-5">
                                                            <BooleanRadio name="is_passed" title="pass NCLEX-RN" />
                                                        </div>
                                                        </>
                                                        :
                                                        <>
                                                        
                                                         { getFieldProps("is_passed").value == 1 &&
                                                           setFieldValue("is_passed", "0")}
                                                        </>
                                                      }  
                                                        <div className="col-lg-6"></div>
                                                        
                            
                                                        <div className="col-lg-6">
                                                            <Input  type="text" name="license_no" placeholder="License no"  />
                                                        </div>
                                                        <div className="col-lg-6"></div>
                                                      
                                                        <div className="col-lg-6">
                                                            <File   name="resume"  label={'Upload Resume'}  />
                                                        </div>
                                                      

                                                    </div>
                                                </div>
                                                <div className="form-block n-border mb-4">
                                                    <div className="row">
                                                       
                                                        <div className="col-md-6">
                                                            <motion.button
                                                                className={status == 200 ? "btn_submit_dark btn btn-success" :"btn_submit_dark"}
                                                                type={"submit"}
                                                                whileTap={{ scale: 0.7 }}
                                                                onHoverStart={e => {}}
                                                                onHoverEnd={e => {}}
                                                                disabled={isSubmitting}
                                                            >  
                                                            {status == 200 ? "Success" : (isSubmitting ? "Please wait..." : "Update")}
                                                                        

                                                            </motion.button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>)
                                    }}
                                        </Formik>}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Dashboard>
    )
}