import React from "react";

export function ContractSectionDisabled(){
    return   <div className="calendar-tab border-top-dashed disabled">
        <div className="d-flex">
            <div className="calendar-list">
                            <span>
                                3
                            </span>
            </div>
            <div className="calendar-flex">
                <h3 className="tab-title">Contract</h3>
                <p>
                    Donec in est eu dui venenatis dapibus. Aenean non turpis suscipit,
                    consequat dolor a,venenatis.Donec in est eu dui venenatis dapibus.
                </p>
            </div>
        </div>
    </div>



}