import React from "react";

export function InterviewSectionDone(){
    return    <div className="calendar-tab border-top-dashed">
        <div className="d-flex">
                    <span className="approved-check">
                        <i className="far fa-check"></i>
                    </span>
            <div className="calendar-list">
                        <span>
                            2
                        </span>
            </div>
            <div className="calendar-flex">
                <h3 className="tab-title">Interview</h3>
                <p>
                    Keep track of scheduled interviews and manage them effortlessly with just a few clicks.
                </p>
                <p className="mt-3">
                    Summary The calendar for April 21, 2022 has been approved.
                </p>
            </div>
        </div>
    </div>


}