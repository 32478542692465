import React, { useRef, useEffect, useState } from "react";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    InfoWindow,
    Marker,
} from "react-google-maps";
import {MapPin} from "../../../Common/MapPin";

const MapContainer = (props) => {
    const google = window.google;
    console.log(props.param);
    const bounds = new window.google.maps.LatLngBounds();
    const [markers, setMarkers] = useState([]);

    useEffect(function (){
        console.log(props.param.markers,"hehe")
        setMarkers(props.param.markers);
    },[props.param.markers])
    const [selectedPlace, setSelectedPlace] = useState(null);
    // let selectedPlace = null

    useEffect(() => {
        setSelectedPlace(null);

        if (markers.length > 0) {
            markers.forEach((marker) => {
                if(marker.latitude && marker.longitude){
                bounds.extend(new google.maps.LatLng(marker.latitude, marker.longitude));
                }else{
                    return <></>
                }
            });
            props.mapRef.current.fitBounds(bounds);
        }
    }, [markers, props.mapRef]);

    // Search for hotels in the selected city, within the viewport of the map.

    return (
        <>
            <GoogleMap
                options={{ streetViewControl: false, zoomControl: true }}
                defaultZoom={5}
                defaultCenter={{ lat: 37.1, lng: -95.7 }}
                ref={props.mapRef}
                onGoogleApiLoaded={({ map }) => map.fitBounds(bounds)}
            >
                {markers.map((marker) => {
                    if(marker.latitude && marker.longitude){

                    return <div key={marker.place_id}>
                        <Marker
                            position={new google.maps.LatLng(marker.latitude, marker.longitude)}
                            onClick={() => {
                                setSelectedPlace(marker.id);
                                console.log(selectedPlace === marker.place_id);
                            }}

                            icon={{
                                url: selectedPlace === marker.id || marker.current ? '/current-pin.svg':'/dimmed-pin.svg'   ,
                                anchor: new google.maps.Point(35, 46),
                            }}
                        >

                            {(selectedPlace === marker.id || marker.current) && (

                                <InfoWindow
                                    marker={marker}
                                    showingInfoWindow={'true'}
                                    onCloseClick={() => {
                                        setSelectedPlace(null);
                                        console.log(selectedPlace === marker.place_id);
                                    }}
                                    position={marker.position}
                                >

                                    <div  className={'company-map-label'} >{marker.company_name}</div>


                                </InfoWindow>
                            )}


                        </Marker>
                    </div>
                    }else{
                        return <></>
                    }

                }
                )}
            </GoogleMap>
        </>
    );
};

const MapWrapped = withScriptjs(withGoogleMap(MapContainer));

export default function AgencyMap(name) {

    const mapRef = useRef(null);
    return (
        <>

            <div className="col-md-12">
                <div className="hotel-map">
                    <div  style={{border:0,height:262}}>

                        <MapWrapped
                            mapRef={mapRef}
                            autocompleteRef={''}
                            param={name}
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCR4bXy0QjJnAcGE4t65W20UJDcVhGXt7w&libraries=places`}
                            loadingElement={<div style={{height: `100%`}}/>}
                            containerElement={<div style={{height: `100%`}}/>}
                            mapElement={<div style={{height: `100%`}}/>}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
