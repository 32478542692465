import React from "react";
import {Formik,Field, Form} from "formik";
import {representativeSchema} from "../../../schemas/representativeSchema";
import {handleFormikSubmit} from "../../../Helpers/formHelper";
import {representativeRegister} from "../../../adapters/representativeAdapter";
import {motion} from "framer-motion";
import {Rating, TextArea} from "../../../Helpers/formComponents";
import {setTestimonial} from "../../../adapters/TestimonialsAdapter";
import {testimonialSchema} from "../../../schemas/testimonialSchema";
import {useAuth} from "../../../app/auth";

export function TestimonialsForm(props) {
    const {currentUser} =  useAuth();
    return (
        <div className="section bg_theme">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h3 className="testm-white">
                            <span>{currentUser?.full_name}</span> would you like to share your valuable comments with us?
                        </h3>
                        <p className="testm-white-p">
                            Donec in est eu dui venenatis dapibus. Aenean non turpis suscipit, consequat dolor a,venenatis dapiban non turpis suscipit.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <Formik
                            validateOnBlur
                            initialValues={{
                                review: '',
                                rating_stars: '',
                            }}
                            validationSchema={testimonialSchema}
                            onSubmit={handleFormikSubmit(setTestimonial,()=>{},()=>{
                                console.log('asdas')
                                props.refetchHasTestimonials()
                            })}
                        >
                            {({isValid, isSubmitting , status}) => {
                                console.log(status);
                                return (

                                <Form method={"post"}>
                                    <div className="row rating-testmonials">
                                        <div className="col-12">
                                            <TextArea name="review" autocomplete="off" rows="10" placeholder="Please write your comment"></TextArea>
                                        </div>
                                        <h3 className="rateus-title">rate us</h3>
                                        <Rating name="rating_stars" />
                                        <div className="col-12 text-center">
                                            <motion.button
                                                className={status == 200 ? "btn_submit_dark btn btn-success" :"btn_submit_dark"}
                                                type={"submit"}
                                                whileTap={{ scale: 0.7 }}
                                                onHoverStart={e => {}}
                                                onHoverEnd={e => {}}
                                                disabled={isSubmitting}
                                            >  {status == 200 ? "Success" : (isSubmitting ? "Please wait..." : "Share your comment")}
                                            </motion.button>
                                        </div>
                                    </div>
                                </Form>)}}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}
