import axios from "axios";

export  function  nurseApplyingRegister(data) {
    console.log(nurseApplyingRegister);
    return  axios.post("nurse_applying_register",data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export  function  lookingToImmigrateRegister(data) {
    return  axios.post("looking_to_immigrate_register",data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export  function  getRepresentatives(pageParam,countryId) {
    return axios.get('choose-representative?include=country,ratingCount&page=' + pageParam+"&country_id="+countryId)
}
