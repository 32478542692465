import axios from "axios";


export function getUserData() {

    return axios.get(`profile`);
}
export function sendOtp(callback) {

    return function (data){
        return axios.post(`sendOtp`,data).then((res)=>{
            callback(res , data)
        });
    }
}

export function sendChangePassword(callback) {

    return function (data){
        return axios.post(`changePassword`,data).then(callback);
    }
}