import * as yup from 'yup';

export let representativeSchema = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    bio: yup.string().required("bio is required"),
    // gender: yup.mixed().oneOf(['male', 'female']).nullable(),
    email : yup.string().email().required(),
    address : yup.string().required().nullable(),
    country_id : yup.string().required("Country is required").nullable(),
    country_code : yup.string().required("Country code is required"),
    mobile : yup.number().required(),
});
