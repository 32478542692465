import React from "react";
import {Link} from "react-router-dom";

function CandidateCard({name,token,image}) {
    const imageStyle = {
        width: "100%",
      };

    return (
        
        <div className="col-md-4">
          <div className="rep-box">
              <img src={image} class="rep-img" style={imageStyle}/>
              <div className="rep-box-white">
                  <div className="row">
                      <div className="col-md-9">
                          <h4>{name}</h4>
                          <p>ID #{token}</p>
                      </div>
                      <div className="col-md-3">
                          <Link to={`/edit-candidate/${token}`} className="edit-btn"><img src="img/edit.svg" /></Link>

                      </div>
                  </div>
              </div>
          </div>
      </div>
    )
}
export { CandidateCard };