import {useEffect, useState} from "react";
import $ from "jquery";
import {motion} from "framer-motion";
import React from "react";
import {BigModal} from "./bigModal";
import {AvailabilityGeneral} from "./AvailabilityGeneral";
import Modal from 'react-modal';
import {Form, Formik} from "formik";
import {tokenSchema} from "../../../schemas/emailLoginSchema";
import {handleFormikSubmit} from "../../../Helpers/formHelper";
import {rateAgency} from "../../../adapters/nurseAdapter";

import {Input} from "../../../Helpers/formComponents";
import {Rating} from "../../../Helpers/formComponents";
import {useAuth} from "../../../app/auth";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        // maxWidth: "400px",
        maxWidth: "1000px",
        minWidth: "600px",
        transform: 'translate(-50%, -50%)',
        backgroundColor:"#fff",

    },
    overlay: {
        zIndex: 10000,
        backgroundColor: 'rgba(70, 70, 70, 0.5)',

    }

};
Modal.setAppElement('#root2');


export const AgencyReview = () => {
    let subtitle;
    const {agencyRatingData} = useAuth();
    const [modalIsOpen, setIsOpen] = React.useState(true);
    console.log(agencyRatingData);
    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    return (

        <Modal
        backdropColor="transparent"
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        style={customStyles}
        contentLabel="Example Modal"
    >
        <button onClick={closeModal} className="btn_close_modal"><img src="img/remove.svg" /></button>
        <h4 className="modal-review-title mt-3">Review</h4>
        <h4 className="modal-review-title mb-3">{agencyRatingData.company_name}</h4>
        <div className="container">
            <Formik
                validateOnBlur
                initialValues={{
                    application_id: agencyRatingData.application_id,
                    agency_id: agencyRatingData.agency_id,
                    rate: null,
                }}
                // validationSchema={tokenSchema}
                onSubmit={handleFormikSubmit(rateAgency)}
            >
                 {({isValid, isSubmitting , status}) => (

                <Form method="post">

                <div className="col-md-12 text-center">
                <div className="review-popup-stars">
                    <div className="d-block">

                                    <Rating name={"rate"}/>

                    </div>
                    <h6 className="small-grey-title pt-2">{!!agencyRatingData.rate_avg && agencyRatingData.rate_avg+" out of 5 stars. "}{agencyRatingData.review_count} Review(s)</h6>
                </div>
            </div>
            <div className="col-md-12">
                <motion.button
                    className={status == 200 ? "btn_submit_dark btn btn-success" : status == 422 ? "btn_submit_dark btn-danger" : "btn_submit_dark"}
                    type={"submit"}
                    whileTap={{ scale: 0.7 }}
                    onHoverStart={e => { }}
                    onHoverEnd={e => { }}
                    disabled={isSubmitting}
                >  {status == 200 ? "Success" : (isSubmitting ? "Please wait..." : status == 422 ? "Please fix the form errors" : "Submit")}
                </motion.button>
            </div>

                </Form>
                 )}
            </Formik>
                </div>
    </Modal>
    )
}

