
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useMutation } from "react-query";
import { insertNewsletter } from "../../adapters/commonAdapter";
import { Recaptcha } from '../../Helpers/formComponents';

export function Newsletter() {
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState({ "text": "", "class": "text-danger" })
    const [recaptcha, setRecaptcha] = useState('')
    const mutation = useMutation(data => {
        return insertNewsletter(data)
    }, {
        onSuccess: (data) => {
            setMessage({ "text": "Thanks for subscribing to our newsletter", "class": "text-success" })
        },
        onError: (error) => {
            setMessage({ "text": error.response.data.message, "class": "text-danger" })
        },
        onMutate: () => {
            setMessage({ "text": "sending...", "class": "text-info" })
        }
    })


    return (
        <>

            <div className="newsletter" style={{ backgroundImage: "url(img/newsletter.jpg)" }}>
                <div className="container">
                    <div className="col-md-12">
                        <h3 className="newsletter-subtitle">Subscrıbe now</h3>
                        <h2 className="sigma_testimonial_title">Stay up date <br />
                            with our newsletter.</h2>
                        <div className="col-md-4 offset-4">
                            <form method="post" className="row">
                                <div className="col-md-8">
                                    <div className="user-input-wrp form-group">
                                        <input type="email"
                                            className="inputText"
                                            onChange={e => setEmail(e.target.value)}
                                            required
                                        />
                                        <span className="floating-label">E-MAIL-ADDRESS</span>
                                        <small className={message.class}>{message.text}</small>

                                    </div>

                                    <div className="mt-2 mb-3">
                                        <input type='hidden' name="g-recaptcha-response" id="g-recaptcha-response" />
                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_RECAPTCHA}
                                            onChange={(props) => setRecaptcha(props)}
                                        />
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <button type="button" className="btn-block"
                                        onClick={() => mutation.mutate({ email, recaptcha })}

                                    >Subscribe</button>

                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

