// import {nurseUpdateProfileSchema} from "../../../schemas/";
import {handleFormikSubmit} from "../../../Helpers/formHelper";
import {updateProfile} from "../../../adapters/nurseAdapter";
import {Form, Formik} from "formik";
import {useAuth} from "../../../app/auth";
import {
    Countries,
    CountryCode,
    File,
    FormikCheckBox,
    Gender,
    Input,
    Password
} from "../../../Helpers/formComponents";
import React from "react";
import {useQuery} from "react-query";
import {getCountries} from "../../../adapters/commonAdapter";
import {motion} from "framer-motion";
import {Dashboard} from "../../UserCommon";
import {agencySchema} from "../../../schemas/agencySchema";
import {agencySignUpAdapter} from "../../../adapters/agencySignUpAdapter";
import {AgencyForm} from "../../AgencySignUp/AgencyForm";

export function AgencyAccountSettings(){
    const { isFetching, data:countries } = useQuery('country', getCountries ,
        {
            cacheTime : 1000 * 60 * 60 * 24,
            staleTime : 1000 * 60 * 60 * 12,
        });
    let {currentUser} = useAuth()
    return (
        <Dashboard>

            <div className="col-md-9">
                <div className="sigma_subheader_inner style-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Account Settings</h2>
                                <p className="paragraph-text">
                                    Manage and update your account information and notification preferences. Keep this information up-to-date to ensure seamless account activity.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">

                                {currentUser && !isFetching &&

                                    <AgencyForm
                                        initialValues={{
                                            first_name: currentUser.first_name,
                                            last_name: currentUser.last_name,
                                            email: currentUser.email,
                                            country_code: currentUser.country_code,
                                            mobile: currentUser.mobile,
                                            company_name: currentUser.details.company_name,
                                            company_country_id: currentUser.details.company_country_id,
                                            company_address: currentUser.details.company_address,
                                            contact_number: currentUser.details.contact_number,
                                            company_country_code: currentUser.details.company_country_code,
                                            company_id_no: currentUser.details.company_id_no,
                                            image: null,
                                        }}
                                        // validationSchema={agencySchema}
                                        onSubmit={handleFormikSubmit(updateProfile)}
                                        isFetching={isFetching}
                                        countries={countries}
                                    />}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Dashboard>
    )
}