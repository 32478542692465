import React from "react";
import {Link} from "react-router-dom";
import Lottie from "lottie-react";

export function Types({title,image,link,animationData}){
    return (
        <div className="col-lg-4 col-md-6">
            <article className="sigma_post style-15">
                <Link to={link}>
                    <div className="sigma_post-thumb">
                        <img src={image} alt="img" />
                        {/*<Lottie animationData={animationData} style={{height:"254px"}}/>*/}
                    </div>
                    <div className="sigma_post-body">
                        <div className="sigma_post-content">
                            <h5>
                                {title}
                            </h5>
                        </div>
                    </div>
                </Link>
            </article>
        </div>
    )
}
