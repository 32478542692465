import React, { useState } from 'react';
import { ContactForm } from "../../Common/ContactForm";
import { BannerContainer } from "../../Common/BannerContainer";
import { TitleOnlyBanner } from "../../Common/TitleOnlyBanner";
import { useInfiniteQuery, useQuery } from "react-query";
import { getTestimonials, getTestimonialsSettings, hasTestimonials } from "../../../adapters/TestimonialsAdapter";
import { getNextPage } from "../../../Helpers/ReactQueryHelper";
import Pagination from "../../Common/Pagination";
import { TestimonialCard } from "../../Common/TestimonialCard";
import Lottie from "lottie-react";
import * as LeftData from "../../../assets/animation/AbstractDotsBgLeft.json";
import * as rightData from "../../../assets/animation/AbstractDotsBgRight.json";
import FooterImage from "../../Common/FooterImage";
import { TestimonialsForm } from './TestimonialsForm';

import * as Testmonial from "../../../assets/animation/Testimonial.json";
import {useAuth} from "../../../app/auth";

export default function Guest() {
    const {currentUser} =  useAuth();
    const [isSubmittingData, setIsSubmitting] = useState(false);
    const { data: setting } = useQuery('testimonials-setting', getTestimonialsSettings,
        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });

    const {
        status,
        data,
        error,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useInfiniteQuery(
        ['e-learnings'],
        async ({ pageParam = 1 }) => {
            const res = await getTestimonials(pageParam)
            return res.data
        },
        {
            getNextPageParam: getNextPage,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,

        },
    )

    const {
        isLoading,
        isError,
        data: hasTestimonial,
        isFetching: isFetchingTestimonials,
        isPreviousData,
        refetch:refetchHasTestimonials,
    } = useQuery({
        queryKey: ['hasTestimonials'],
        queryFn: () => hasTestimonials(),
        keepPreviousData: true
    })
    return (
        <>
            <BannerContainer style={{
                background: "#fff"
            }}>

                <TitleOnlyBanner
                    title={'Our Testimonials'}
                    description={setting?.data.data.OurTestimonials?.Description}
                />
            </BannerContainer>
            {hasTestimonial && !hasTestimonial?.data?.data.has_testimonial ?

                <TestimonialsForm refetchHasTestimonials = {refetchHasTestimonials}/> 
                :
                <div className="section bg_theme">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6" style={{padding: "71px"}}>
                                <h3 className="testm-white">
                                 Thanks for sharing your comments with us.
                                </h3>
                                <p className="testm-white-p">
                                    Donec in est eu dui venenatis dapibus. Aenean non turpis suscipit, consequat dolor a,venenatis dapiban non turpis suscipit.
                                </p>
                            </div>
                            <div className="col-md-6">
                                <Lottie animationData={Testmonial} />
                            </div>
                        </div>
                    </div>
                </div>

            }
            <BannerContainer style={{
                background: "#fff"
            }}>
                <div className="animation-left">
                    <Lottie animationData={LeftData} />
                </div>
                <div className="animation-right">
                    <Lottie animationData={rightData} />
                </div>
                <div className="">
                    <Pagination
                        data={data}
                        isFetching={isFetching}
                        fetchNextPage={fetchNextPage}
                        isFetchingNextPage={isFetchingNextPage}
                        hasNextPage={hasNextPage}
                    >{(testimonial) => {
                        return <div className="col-lg-4 col-md-4 col-sm-6">
                            <TestimonialCard
                                rating={testimonial.rating_stars}
                                description={testimonial.review}
                                name={testimonial.user?.full_name}
                                image={testimonial.user?.image}
                                type={testimonial.user?.type}
                            />
                        </div>
                    }}
                    </Pagination>
                </div>
            </BannerContainer>
            <FooterImage />
            <ContactForm />

        </>
    )
}
