
import React, { useEffect, useState } from 'react';
import { useMutation } from "react-query";
import { motion } from "framer-motion";
import { insertContactUs } from "../../adapters/commonAdapter";
import {
    formMutationOptions,
} from "../../Helpers/ReactQueryHelper";
import { MutationAlert } from "./MutationAlert";
import { handleFormChange } from "../../Helpers/formHelper";
import { FormikRecaptcha } from '../../Helpers/formComponents';
import ReCAPTCHA from 'react-google-recaptcha';

export function ContactForm() {


    const [data, setData] = useState({})
    const [recaptcha, setRecaptcha] = useState('')
    const handleChange = handleFormChange(data, setData);
    const [message, setMessage] = useState({ "text": "", "class": "" })
    const [formState, setFormState] = useState({ data: data, recaptcha: recaptcha })

    const mutation = useMutation(data => {
        return insertContactUs(data)
    }, formMutationOptions(setMessage,'You’re inquiry has been submitted and we will contact you shortly'))
    useEffect(() => {
        setFormState({ ...data, 'g-recaptcha-response': recaptcha })
    }, [data, recaptcha])
    return (
        <div className="section contact" style={{ backgroundImage: "url(/img/contact-bg.png)" }}>
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 offset-3">
                        <h1 className="main-title-white text-ceter">
                            Do you need help<br />
                            Contact us!
                        </h1>
                        <div className="sigma_form style-2">
                            <form className="form_validate ajax_submit form_alert" method="post" encType="multipart/form-data">

                                <div className="row mt-5">


                                    <div className="col-lg-12">
                                        <MutationAlert message={message.text} AlertClass={message.class} />
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="text" name="surname" autoComplete="off" placeholder="Name Surname"
                                                onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="email" name="email" autoComplete="off" placeholder="E-Mail adress"
                                                onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <textarea name="description" autoComplete="off" rows="10" placeholder="Message"
                                                onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <input type='hidden' name="g-recaptcha-response" id="g-recaptcha-response" />
                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_RECAPTCHA}
                                            onChange={(props) => setRecaptcha(props)}
                                        />
                                    </div>
                                    <div className="col-12 text-center">
                                        <motion.button
                                            type="button" className="contact_submit"
                                            onClick={() => mutation.mutate(formState)}
                                            whileTap={{ scale: 0.7 }}
                                            onHoverStart={e => { }}
                                            onHoverEnd={e => { }}
                                            disabled={mutation.isLoading}
                                        >
                                            {mutation.isLoading ? "please wait..." : "Send Message"}

                                            <div className="server_response w-100"></div>
                                        </motion.button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

