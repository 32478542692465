import React from "react";
import {Link} from "react-router-dom";
import Lottie from "lottie-react";


export  function Card({title,Image,link,animationData}) {
    return (
                            <div class="col-md-4 text-center pb-4">
                                <Link to={link}>
                                    <div class="specialities_img text-center">
                                        {/*<img src={Image}/>*/}
                                        <Lottie animationData={animationData} />
                                        <h3 class="specialities_title">{title}</h3>
                                    </div>
                                </Link>
                            </div>
    )
}









