import axios from "axios";

export  function  getSettings() {

    return  axios.get("settings",{
        params: {
            "module_name": ["HeroSection",'Counter','WhyMedicStaff','HiringIsVeryEasy','OurTestimonials']
        }
    })
}

export  function  getTestimonials() {
    return  axios.get("testimonials?include=user")
}

export const  homeTransformer = async () => {
    let [settings, testimonials] =  await axios.all([getSettings(), getTestimonials()])
    return {
        settings: settings.data.data,
        testimonials: testimonials.data.data
    };
};