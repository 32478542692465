import React, {useEffect} from 'react';
import {ContactForm} from "../Common/ContactForm";
import {Banner} from "./Banner";
import FlipCard from "./FlipCard";
import FooterImage from "../Common/FooterImage";
import {BannerContainer} from "../Common/BannerContainer";
import {useInfiniteQuery} from "react-query"
import {getSpecialities} from "../../adapters/SpecialitiesAdapter";
import {getNextPage} from "../../Helpers/ReactQueryHelper";
import Pagination from "../Common/Pagination";
import * as LeftData from '../../assets/animation/AbstractDotsBgLeft.json'
import * as rightData from '../../assets/animation/AbstractDotsBgRight.json'
import Lottie from "lottie-react";



export default function Specialities() {
    let [search, setSearch] = React.useState('')

        const {
        status,
        data,
        error,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useInfiniteQuery(
        ['projects'],
        async ({ pageParam = 1 }) => {
            const res = await getSpecialities(pageParam,search)
            return res.data
        },
        {
            getNextPageParam: getNextPage,
            cacheTime : 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,

        },
    )

    useEffect(() => {
        refetch()
    }, [search]);

    return (
       <>

           <BannerContainer style={{
               background: "#fff"
           }}>
               <div className="animation-left">
                   <Lottie animationData={LeftData} />
               </div>
               <div className="animation-right">
                   <Lottie animationData={rightData} />
               </div>
               <Banner search={search} setSearch={setSearch}/>
               <Pagination
                   data={data}
                   isFetching={isFetching}
                   fetchNextPage={fetchNextPage}
                   isFetchingNextPage={isFetchingNextPage}
                   hasNextPage={hasNextPage}
               >{(currentRecord) => {
                       return <FlipCard
                           title={currentRecord.title_en}
                           description={currentRecord.description_en}
                           image={currentRecord.image}
                           link={currentRecord.link}
                           key={currentRecord.id}/>
               }}
               </Pagination>

           </BannerContainer>
           <FooterImage/>
           <ContactForm/>
           </>
    )
}
