import React, { useEffect, useState } from 'react';
import { BannerContainer } from '../Common/BannerContainer';
import { ContactForm } from '../Common/ContactForm';
import FooterImage from '../Common/FooterImage';
import { Link, useLocation } from "react-router-dom";
import { getTermsAndPrivacy } from "../../adapters/ForumsAdapter";
import { useQuery } from "react-query";
import Lottie from "lottie-react";
import * as LeftData from "../../assets/animation/AbstractDotsBgLeft.json";
import * as rightData from "../../assets/animation/AbstractDotsBgRight.json";
import { useNavigate } from 'react-router-dom';


export default function Terms() {
    const { data: terms } = useQuery('terms-privacy', getTermsAndPrivacy,
        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });
    const [activeTab, setActiveTab] = useState(1);


    const location = useLocation();
    useEffect(() => {
        const hash = location.hash.replace("#", "");
        if (hash) {
            setActiveTab(hash);
        }
    }, [location]);

    useEffect(() => {
        const element = document.getElementById(activeTab);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }, [activeTab]);

    const navigate = useNavigate();

    const handleTermChange = (event) => {
        const selectedTermId = event.target.value;
        if (selectedTermId) {
            navigate(`#${selectedTermId}`);
        }
    };
    return (
        <>
            <BannerContainer style={{
                background: "#fff"
            }}>
                <div className="animation-left">
                    <Lottie animationData={LeftData} />
                </div>
                <div className="animation-right">
                    <Lottie animationData={rightData} />
                </div>
                <div class="row">
                    <div className="col-md-3">
                        <select className="mb-5 mobile-footer-img" id="bordered-tab" role="tablist" onChange={handleTermChange}>
                            <option value="terms-of-service" disabled>Terms of Service</option>
                            {terms && terms.data.data.map(term => (
                                <option key={term.id} value={term.id}>
                                    {term.title_en.substring(0, 30)}
                                </option>
                            ))}
                        </select>

                        <ul className="nav terms-list desktop-version" id="bordered-tab" role="tablist">
                            <li className="nav-item">
                                <a className="first-terms">Terms of Service</a>
                            </li>
                            {terms && terms?.data.data.map(term => (
                                <li className="nav-item">
                                    <Link className="nav-link" to={`#${term.id}`}  style={{ maxWidth: '200px', wordWrap: 'break-word' }}>
                                        {term.title_en.substring(0, 30)}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="col-md-9">
                        <div className="tab-content" id="bordered-tabContent">
                            {terms && terms?.data.data.map((term) => (
                                <div
                                    className={`tab-pane fade ${activeTab == term.id ? "show active" : ""
                                        }`}
                                    id={term.id}
                                    role="tabpanel"
                                    aria-labelledby={term.id}
                                >
                                    <h4>{term.title_en}</h4>
                                    <div dangerouslySetInnerHTML={{ __html: term.description_en }} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-12">

                    </div>
                </div>
            </BannerContainer>
            <FooterImage />
            <ContactForm />
        </>
    );
}



