import axios from 'axios';
import {getTokenFromLocalStorage, useAuth} from "./app/auth";
import React from "react";
import {OptVerification} from "./Components/NurseUser/General/optVerification";
import {useNavigate} from "react-router-dom";

export function AxiosInterceptor({children}) {
    let {offlinelogout,logout,setShowModal,setAgencyRatingData,setRepresentativeRatingData,setAvailabilityCount} = useAuth();
    axios.interceptors.response.use(response => {
        if(response?.data?.rate_agency_status?.is_rated === false){
            console.log(response?.data)
            setShowModal("agencyReview")
            setAgencyRatingData(response?.data?.rate_agency_status?.job_application)
        }
        if(response?.data?.rate_representative_status?.is_rated === false){
           
            setShowModal("representativeReview")
            setRepresentativeRatingData(response?.data?.rate_representative_status?.representative)
        }
        return response;
    }, error => {

        
        const {  config: { url } } = error.response;
        if (error.response.status === 401  && url === process.env.REACT_APP_API_URL+"logout"){
            offlinelogout()
        }else if (error.response.status === 401) {
            logout()
        }
        if (error.response.status === 422 && error.response.hasOwnProperty('data') &&  error.response.data.hasOwnProperty('is_verified')) {
            setShowModal("verification")
        }else if (error.response.status === 422 && error.response.hasOwnProperty('data') &&  error.response.data.hasOwnProperty('is_available')) {
            setAvailabilityCount(error.response.data.count)
            setShowModal("availability")
        }else if (error.response.status === 422 && error.response.hasOwnProperty('data') &&  error.response.data.hasOwnProperty('profile_redirect')) {
            window.location.href = "/profile";

        }

        return Promise.reject(error);

    });

    return <>{children}</>

}

export function AxiosSetup({children}) {
    let token =  getTokenFromLocalStorage();
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers['Authorization'] = `Bearer ${token}`
    axios.defaults.headers["Accept"] = 'application/json';
    axios.defaults.headers["Content-Type"] = 'application/json';
    let referral = getReferral()
    if(referral){
        axios.defaults.headers['X-Referral'] = referral;
    }
    return <>{children}</>

}


export function getReferral(){
    const referralCookie = document.cookie
        .split(';')
        .find(cookie => cookie.trim().startsWith('referral='));

    // If referral cookie exists, inject its value into X-Referral header
    if (referralCookie) {
        const referralCode = referralCookie.split('=')[1];
        return referralCode;
    }else{
        return false;
    }
}