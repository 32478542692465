import { Rating } from "../../../Common/TestimonialCard";
import { MapPin } from "../../../Common/MapPin";
import React, { useEffect } from "react";
import { docusignAcceptAndDecline } from "../../../../adapters/commonAdapter";
import Modal from "react-modal";
import { BigPlayButton, Player } from "video-react";
import { useQuery} from "react-query";
import { getSystemVideo } from "../../../../adapters/ForumsAdapter";
import { useAuth } from "../../../../app/auth";

export function AgencyCard({ markers, setMarker, online, alert, rating, offer, data }) {


    const [modalIsOpen, setIsOpen] = React.useState(false);
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }


    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
        window.location.reload()
    }
    const buttonStyle = {
        color: 'black',
      };
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            maxWidth: "800px",
            minWidth: "320px",

            transform: 'translate(-50%, -50%)',
            backgroundColor: "#fff",

        },
        overlay: {
            zIndex: 100,
            backgroundColor: 'rgba(70, 70, 70, 0.5)',

        }

    };
    const { isLoading, isSuccess, data: video } = useQuery('video', getSystemVideo,
    {
        cacheTime: 1000 * 60 * 60 * 24,
        staleTime: 1000 * 60 * 60 * 12,
    });



    let {currentUser}= useAuth()

    let hasOffer =  (currentUser?.acceptedOffer?.id)
    let statuses = {
        "Submited": [],
        "Under Consideration": ["Submitted"],
        "Interview Processing": ["Under Consideration", "Submitted"],
        "Approved": ["Interview Processing", "Under Consideration", "Submitted"],
        "Interview Day": ["Interview Processing", "Under Consideration", "Submitted"],
        "Offer Pending": ["Interview Day", "Approved", "Interview Processing", "Under Consideration", "Submitted"],
        "Offer Accepted": ["Interview Day", "Approved", "Interview Processing", "Under Consideration", "Submitted"],
        "Offer Declined": ["Interview Day", "Approved", "Interview Processing", "Under Consideration", "Submitted"],
    }
    function checkStatus(status, agency) {
        let currentStatus = !!agency?.active_job_application ? agency.active_job_application.status : null;

        if (currentStatus == "Interview Processing") {
            if (agency.active_job_application.meeting_data?.processing) {
                currentStatus = "Interview Day";
            }
        }
        if (status == currentStatus) {
            return 'pending';
        } else if (currentStatus) {
            return statuses[currentStatus].includes(status) ? "completed" : "uncompleted";
        } else {
            return "uncompleted";
        }


    }
    let stepsStatuses = [
        "Submitted",
        "Under Consideration",
        "Interview Processing",
        "Interview Day",
        "Offer Pending",
        "Offer Accepted",
        "Offer Declined",
    ]
    return (<>
        <div className="col-md-4 mb-5 mt-5">
            <div className={!!data?.active_job_application && (data?.active_job_application.status == "Offer Pending" ||  data?.active_job_application.status == "Offer Declined" || hasOffer) ? '' : "flip-card"}>
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <div
                            onMouseEnter={() => {
                                setMarker(
                                    markers.map(function (marker) {
                                        if (data.details.id == marker.id) {
                                            return { ...marker, current: true }
                                        } else {
                                            return { ...marker, current: false }
                                        }
                                    })
                                )

                            }}
                            onMouseLeave={() => {
                                setMarker(
                                    markers.map(function (marker) {
                                        return { ...marker, current: false }
                                    })
                                )
                            }}

                            className="comapny-box-front">
                            {!!alert && <span className="far fa-bell alert-icon"></span>}
                            <div className="company-bg">
                                <div className="status-chat">
                                    <h4 className="status-text">

                                        {online ? <>Online
                                            <span className="online"><i
                                                className="fa fa-circle"></i></span>
                                        </> :
                                            <>Offline
                                                <span className="offline"><i className="fa fa-circle"></i></span></>}
                                    </h4>
                                </div>
                                <img src={data.image??'img/company_logo.png'} />
                            </div>
                            <div className="company-inner">
                                <h4>
                                    {data.details.company_name}
                                </h4>
                                { <>
                                    <div className="sigma_service-body">
                                        <div className="sigma_rating">
                                            <Rating rating={rating} />
                                        </div>
                                    </div>
                                    <h6 className="small-grey-title pt-2">{rating} out of 5 stars</h6>

                                </>}
                                {!hasOffer ? <>  
                                {!!data?.active_job_application && data?.active_job_application.status == "Offer Pending" && <p className={'company-offer'}>Offer Pending</p>}
                                {!!data?.active_job_application && data?.active_job_application.status == "Offer Declined" && <p className={'company-offer'}>Offer Declined</p>}
                                </>
                                : <>  
                                {!!data?.active_job_application && data?.active_job_application.status == "Offer Accepted"  ? <p className={'company-offer'}>Offer Accepted</p>:(hasOffer ? <p className={'company-offer'}>disabled</p>:"") }
                                </>  }
                            </div>
                            {!!data?.active_job_application && data?.active_job_application.status == "Offer Pending" && !hasOffer && 
                                <div className="req-btns">
                                    <button onClick={() => {
                                        docusignAcceptAndDecline('Offer Declined', data?.id).then(() => window.location.reload())
                                    }} className="btn_req" style={buttonStyle}>Decline</button>
                                    <button onClick={() => {
                                        console.log(window.docuSignClick.Clickwrap)
                                        if (data?.active_job_application?.docusign_data?.created_time) {
                                            window.docuSignClick.Clickwrap.render({
                                                environment: data?.active_job_application?.docusign_data.environment,
                                                accountId: data?.active_job_application?.docusign_data.accountId,
                                                clickwrapId: data?.active_job_application?.docusign_data.clickwrapId,
                                                clientUserId: data?.active_job_application?.docusign_data.clientUserId,
                                                disableCloseButton: false, // Add this parameter to enable the close button
                                                visible: 'false',
                                                modal: {
                                                    allowClose: false,
                                                    escapeClose: false,
                                                    appendTo: '#ds-modal'
                                                },
                                                onAgreed: function () {
                                                    docusignAcceptAndDecline('Offer Accepted', data?.id).then(() => {

                                                        openModal();


                                                    });
                                                },

                                                onDeclined: function () {
                                                    // docusignAcceptAndDecline('Offer Declined', data?.id).then(() => window.location.reload());
                                                },


                                            }, '#ds-clickwrap')
                                        }
                                    }} className="btn_req" style={buttonStyle}>View</button>
                                </div>
                            }
                        </div>
                    </div>
                    {!!data?.active_job_application?.status != "Offer Pending" &&

                        <div className="flip-card-back">
                            <div class="flip-agency-white">
                                <ul className="flip-agency-steps">
                                    {stepsStatuses.map(function (value) {
                                        return <li className={checkStatus(value, data)}>
                                            <h4>
                                                {value} {checkStatus(value, data) == "pending" ? <i className="far fa-arrow-right"></i> : ""}
                                            </h4>
                                        </li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
        <Modal
            backdropColor="transparent"
            shouldCloseOnOverlayClick={false}
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <button onClick={closeModal} className="btn_close_modal"><img src="img/remove.svg" /></button>
            <h4 className="modal-text-red">Congratulations</h4>
            <h4 className="modal-text">Congratulations on Approving the Contract with <a href="#"> {data.details.company_name} </a></h4>
            <div className="container">
                <div className="col-md-12 text-center">
                    <Player
                        playsInline
                        poster="img/video-bg.png"
                        src={video?.data?.data?.SystemVideos?.congratulations_video ?? ""}
                    >
                        <BigPlayButton position="center" />
                    </Player>
                </div>
            </div>
        </Modal>
    </>)

}
