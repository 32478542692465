import React from "react";
import {TitleLeftBanner} from "./TitleLeftBanner";
import {BannerContainer} from "./BannerContainer";
import {ContactForm} from "./ContactForm";
import { useLocation } from 'react-router-dom';
import Lottie from "lottie-react";
import * as LeftData from "../../assets/animation/AbstractDotsBgLeft.json";

export function LoginContainer({children,image,formTitle,formDescription,formSpan,animationData}) {
    const location = useLocation();

    return (
        <>
            <div className="sigma_banner_inner" style={{
                backgroundImage: "url(img/RegisterBackground.png)",
                backgroundPosition: "inherit",
                backgroundSize: "cover",
                height : "100%",
                paddingBottom: "190px",
                paddingTop: "212px"
            }}>
                <div className="sigma_subheader_inner style-5">
                    <div className="container">
                        <div className="row">

                        <div className="col-lg-6 col-md-6 text-center">
                            {(location.pathname === '/agency-login') ?
                                <img src={image} style={{height:"540px"}} />
                                :
                                <Lottie animationData={animationData} style={{height:"540px"}}/>
                            }
                        </div>
                        <div className="col-md-4">
                            <TitleLeftBanner
                                title={formTitle}
                                subTitle={formSpan}
                                description={formDescription}
                            />
                            {children}
                        </div>

                    </div>
                </div>
                </div>
            </div>
            <ContactForm/>
        </>
    )
}

