import {Map} from "./Map";
import {AgencyListing} from "./AgencyListing";
import React, {useEffect, useState} from "react";
import { useInfiniteQuery } from "react-query";
import {getNextPage} from "../../../../Helpers/ReactQueryHelper";
import {getAgencies, Verify} from "../../../../adapters/nurseAdapter";
import {Dashboard} from "../../../UserCommon";
import Modal from "react-modal";
import 'magnific-popup/dist/jquery.magnific-popup.min'
import 'magnific-popup/dist/magnific-popup.css'
import {Rating} from "../../../../Helpers/formComponents";
import {tokenSchema} from "../../../../schemas/emailLoginSchema";
import {handleFormikSubmit} from "../../../../Helpers/formHelper";
import {Form, Formik} from "formik";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        // maxWidth: "400px",
        maxWidth: "1000px",
        minWidth: "600px",
        transform: 'translate(-50%, -50%)',
        backgroundColor:"#fff",

    },
    overlay: {
        zIndex: 10000,
        backgroundColor: 'rgba(70, 70, 70, 0.5)',

    }

};
Modal.setAppElement('#root2');

export function Main(){


    const {
        data,
        status,
        error,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch,

    } = useInfiniteQuery(
        ['agencies'],
        async ({ pageParam = 1 }) => {
            const res = await getAgencies(pageParam)
            return res.data
        },
        {
            getNextPageParam: getNextPage,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,

        },
    )
    useEffect(function (){
        let newMarkers ;
        let allMarkers =[];
        data.pages.forEach(function (value,key){
            newMarkers = [];
            newMarkers = data.pages[key].data.map(function (d,a){
                return {...d.details,current:false}
            })
            allMarkers = [...allMarkers ,...newMarkers ]
        })
        setMarker(allMarkers);
    },[data])
    let [markers,setMarker] = useState([])


    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(true);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    console.log(data)

    return (
        <Dashboard>
            <div className="col-md-9">
            <div className="sigma_subheader_inner style-5 showmore-class">
                <div className="container">
                    <div className="row justify-content-center">


                        <Map markers={markers}/>
                    </div>
                    
                    <AgencyListing markers={markers} setMarker={setMarker} data={data} isFetching={isFetching} fetchNextPage={fetchNextPage}
                                   isFetchingNextPage={isFetchingNextPage} hasNextPage={hasNextPage}/>
                </div>
            </div>
        </div>
        <br/>
                    {/* <Modal
                backdropColor="transparent"
                shouldCloseOnOverlayClick={false}
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <button onClick={closeModal} className="btn_close_modal"><img src="img/remove.svg" /></button>
                <h4 className="modal-review-title mt-3">Review</h4>
                <h4 className="modal-review-title mb-3">Agency Name</h4>
                <div className="container">
                    <div className="col-md-12 text-center">
                        <div className="review-popup-stars">
                            <div className="d-block">
                                <Formik
                                    validateOnBlur
                                    initialValues={{
                                        token: '',
                                    }}
                                    validationSchema={tokenSchema}
                                    onSubmit={handleFormikSubmit(Verify)}
                                >
                                    {({isValid, isSubmitting , status}) => (

                                        <Form method="post">

                                            <Rating name={"rating"}/>

                                        </Form>)}
                                </Formik>
                            </div>
                            <h6 className="small-grey-title pt-2">3 out of 5 stars.</h6>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <button className="btn_submit_dark">Submit</button>
                    </div>
                </div>
            </Modal> */}
        </Dashboard>
        )
}
