import React,{ useState, useEffect, createContext, useContext,
    // useRef,
} from 'react';
import jwt_decode from "jwt-decode";

import {
   // getUserData,
    requestLogout } from './_requests';
import {Navigate, Routes} from 'react-router-dom'
import {getUserData} from "../adapters/authAdapter";
const initAuthContextPropsState = {
    currentUser: undefined,
    setCurrentUser: () => { },

    availabilityCount: undefined,
    setAvailabilityCount: () => { },
    agencyRatingData: undefined,
    setAgencyRatingData: () => { },
    representativeRatingData: undefined,
    setRepresentativeRatingData: () => { },
    showModal: undefined,
    setShowModal: () => { },
    token: undefined,
    setToken: () => { },
    type: undefined,
    setType: () => { },
    logout: () => { },
    offlinelogout: () => { },
};
const AuthContext = createContext(initAuthContextPropsState);
const useAuth = () => {
    return useContext(AuthContext);
};
const AuthProvider = ({ children }) => {


    //store user data in local storage
    const [currentUser, setCurrentUser] = useState(
        JSON.parse(localStorage.getItem('user'))
    );
    //store token in local storage
    const [token, setToken] = useState(
        localStorage.getItem('token')
    );

    const [type, setType] = useState(null);

    const [showModal, setShowModal] = useState(false);
    const [agencyRatingData, setAgencyRatingData] = useState({});
    const [availabilityCount, setAvailabilityCount] = useState({});
    const [representativeRatingData, setRepresentativeRatingData] = useState({});

    //logout function
     const  logout = async() => {
        await requestLogout();
         offlinelogout()
    }

    const  offlinelogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        setCurrentUser(undefined);
        setToken(undefined);
        window.location.href ='/';
    }
    //check if user token expired or revoked

    const checkToken = () => {
        if (token) {
            const decoded = jwt_decode(token);
            const currentTime = Date.now() / 1000;
            if (decoded.exp < currentTime) {
                logout();
                return false;
            }else{
                return true
            }
        }
        return false
    }
    useEffect(() => {
        setType(currentUser?.type)
    }, [currentUser]);

    //check if user token working with every request
    useEffect(() => {
        if (token) {
            let check  = checkToken();
            if (check) {
                getUserData().then((res) => {
                    if (res.status === 200) {
                        setCurrentUser(res.data.data);
                    }
                });
            }
        }
    }, [token]);



    return <AuthContext.Provider value={{token,setToken,currentUser,type, setCurrentUser, showModal,setShowModal, agencyRatingData,setAgencyRatingData ,availabilityCount, setAvailabilityCount ,representativeRatingData, setRepresentativeRatingData,logout,offlinelogout}} >{children}</AuthContext.Provider>
};




function addUserDataToLocalStorage(user, token,redirectPage="/dashboard") {
    console.log(user);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
    if(user.hasOwnProperty('email_verification') && user.email_verification === 0){
        redirectPage = '/';
    }
    window.location.href =redirectPage;

}
function getTokenFromLocalStorage() {
    return localStorage.getItem('token');
}

export { AuthProvider, useAuth,addUserDataToLocalStorage,getTokenFromLocalStorage };
