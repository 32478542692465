import React, {useEffect, useState} from "react";
import {AvailabilityGeneral} from "../../General/AvailabilityGeneral";
import {Dashboard} from "../../../UserCommon";
import {AvailabilityModal} from "../../General/AvailabilityModal";

export function Availability(){


    return (
        <Dashboard>

            <div className="col-md-8 pt-lg-0 pt-5">
                <AvailabilityGeneral/>
            </div>

        </Dashboard>
    )
}
