import React, {useEffect} from 'react';
import { ContactForm } from "../Common/ContactForm";
import { BannerContainer } from "../Common/BannerContainer";
import { TitleOnlyBanner } from "../Common/TitleOnlyBanner";
import Lottie from "lottie-react";
import * as LeftData from "../../assets/animation/AbstractDotsBgLeft.json";
import * as rightData from "../../assets/animation/AbstractDotsBgRight.json";
import FooterImage from "../Common/FooterImage";
import {Rating} from "../Common/TestimonialCard";
import Modal from "react-modal";
import * as conf_layer1 from '../../assets/animation/Confeti_Layer1.json';
import * as conf_layer2 from '../../assets/animation/Confeti_Layer2.json';
import { motion } from "framer-motion";
import {useInfiniteQuery} from "react-query";
import {getNextPage} from "../../Helpers/ReactQueryHelper";
import {getRepresentatives} from "../../adapters/applicantSignUpAdapter";
import Pagination from "../Common/Pagination";
import {getRepresentativeRate} from "../../adapters/representativeAdapter";

export default function ChooseRepresentative({isSubmitting,status,setFieldValue,submitForm,getFieldProps}) {

    const {
        data,
        error,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useInfiniteQuery(
        ['representative',getFieldProps("country_id").value],
        async ({ pageParam = 1 }) => {
            const res = await getRepresentatives(pageParam,getFieldProps("country_id").value);
            return res.data
        },
        {
            getNextPageParam: getNextPage,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,

        },
    )
    let [rating, setRating] = React.useState(null);
    const openRatingModal = (id)=>{
        setIsOpen(true)
        getRepresentativeRate(id).then(res=>{
            console.log(res.data.data);
            setRating(res.data.data)
        })
    }
    const customReviewStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            // maxWidth: "400px",
            maxWidth: "600px",
            minWidth: "320px",
            borderColor:"#63BD7C",
            borderWidth:"3px",
            transform: 'translate(-50%, -50%)',
            backgroundColor:"#fff",
        },
        overlay: {
            zIndex: 10000,
            backgroundColor: 'rgba(70, 70, 70, 0.5)',

        }

    };
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }


    useEffect(() => {
        console.log(data);
    }
    , [data])

    return (
        <>
            <BannerContainer style={{
                background: "#f7f9fc"
            }}>

                <TitleOnlyBanner
                    title={'Choose Your Representative In '+getFieldProps("country_id_object").value.name}
                    description={'Our representatives are highly qualified to satisfy all your requirements.'}
                />
                <div className="animation-left">
                    <Lottie animationData={LeftData} />
                </div>
                <div className="animation-right">
                    <Lottie animationData={rightData} />
                </div>
                <div className="row justify-content-center mt-5">
                    {/*{SUCCESSEFULY CREATED ALERT}*/}
                    {
                        status == 200 &&
                        <div className="col-md-12">
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                 <strong>Success!</strong> Your representative has been chosen successfully.

                            </div>
                        </div>}
                    <Pagination
                        data={data}
                        isFetching={isFetching}
                        fetchNextPage={fetchNextPage}
                        isFetchingNextPage={isFetchingNextPage}
                        hasNextPage={hasNextPage}

                    >{(representative) => {
                        console.log(representative);
                        return <div className="col-md-4">
                                    <div className="choose-rep-box">
                                        <div className="choose-rep-image">
                                            <img src={representative.image} />
                                        </div>
                                        <h3 className="choose-rep-name">{representative.full_name}</h3>
                                        <p className="choose-rep-des">{representative.bio}</p>
                                        <motion.button
                                            className={ "choose_btn"}
                                            type={"button"}
                                            onClick={()=>{
                                                setFieldValue("representative_id",representative.id);
                                                submitForm();

                                            }}
                                            whileTap={{ scale: 0.7 }}
                                            onHoverStart={e => { }}
                                            onHoverEnd={e => { }}
                                            disabled={isSubmitting}
                                        >  {isSubmitting ? "Please wait..." : "Choose" }
                                        </motion.button>
                                        <>

                                              <div className="sigma_service-body">
                                                <div className="sigma_rating" onClick={()=>openRatingModal(representative.id)}>
                                                    <Rating rating={representative.rating_avg} />
                                                </div>
                                            </div>

                                            <h6 className="small-grey-title pt-2">{representative.rating_avg + " out of 5 stars." }{representative.rating_count} Review</h6>
                                        </>
                                        <ul className="choose-rep-list">
                                            <li>
                                                <img src="img/location-blue.svg" />
                                                <div className="d-block">
                                                    <h4>Location</h4>
                                                    <p>
                                                    {representative?.country?.name != getFieldProps("country_id_object").value.name ? (
                                                        <>
                                                            {representative?.country?.name} - {getFieldProps("country_id_object").value.name}
                                                        </>
                                                        ) : (
                                                        <>{representative?.country?.name}</>
                                                    )}

                                                    </p>
                                                </div>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <img src="img/time-blue.svg" />*/}
                                            {/*    <div className="d-block">*/}
                                            {/*        <h4>Local time</h4>*/}
                                            {/*        <p>12:40 PM</p>*/}
                                            {/*    </div>*/}
                                            {/*</li>*/}

                                            {representative?.services?.length > 0 && <li>
                                                <img src="img/file-bllue.svg" />
                                                <div className="d-block">
                                                    <h4>Services</h4>
                                                    {
                                                        representative?.services?.map((service,index)=>{
                                                        return <p key={index}>{service}</p>
                                                    })
                                                    }

                                                </div>
                                            </li>}
                                        </ul>
                                    </div>
                                </div>
                    }}</Pagination>
                </div>
                <Modal
                    backdropColor="transparent"
                    shouldCloseOnOverlayClick={false}
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    style={customReviewStyles}
                    contentLabel="Example Modal"
                >
                    <button onClick={closeModal} className="btn_close_modal"><img src="img/remove.svg" /></button>
                    <div className="rep-popup-box">
                        <div className="rep-popup-img">
                            <img src={rating?.representative?.image} />
                        </div>
                        <div className="d-block">
                            <h3 className="popup-rep-name">{rating?.representative?.name}</h3>
                            <p className="popup-rep-des">Pellentesque eu arcu rhoncus, faucibus orci non, sollicitudin felis. Curabitur in lacus condimentum felis porttitor faucibus vel vel metus. Nullam blandit tempus urna, vel vestibulum mi mollis aliquet.</p>
                        </div>
                    </div>
                    <ul className="rep-popup-list">
                        <li>
                            <div className="d-flex">
                                <h3>Communication</h3>
                                <div className="rep-popup-stars">
                                    <>
                                        <div className="sigma_service-body">
                                            <div className="sigma_rating">
                                                <h6 className="small-grey-title pt-2">
                                                    {
                                                    !!rating?.communication_rate  &&
                                                    rating?.communication_rate+" out of 5 stars."
                                                    }</h6>
                                                <Rating rating={rating?.communication_rate ? rating?.communication_rate : 0} />
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex">
                                <h3>Provided Services</h3>
                                <div className="rep-popup-stars">
                                    <>
                                        <div className="sigma_service-body">

                                            <div className="sigma_rating">
                                                <h6 className="small-grey-title pt-2">
                                                    {
                                                        !!rating?.provided_services_rate  &&
                                                        rating?.provided_services_rate+" out of 5 stars."
                                                    }</h6>
                                                <Rating rating={rating?.provided_services_rate ? rating?.provided_services_rate : 0} />
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex">
                                <h3>Help & Support</h3>
                                <div className="rep-popup-stars">
                                    <>
                                        <div className="sigma_service-body">
                                            <div className="sigma_rating">

                                            <h6 className="small-grey-title pt-2">
                                                {
                                                    !!rating?.help_support_rate  &&
                                                    rating?.help_support_rate+" out of 5 stars."
                                                }</h6>
                                            <Rating rating={rating?.help_support_rate ? rating?.help_support_rate : 0} />
                                            </div>

                                        </div>
                                    </>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex">
                                <h3>Commitments & Responsibilities</h3>
                                <div className="rep-popup-stars">
                                    <>
                                        <div className="sigma_service-body">
                                            <div className="sigma_rating">

                                            <h6 className="small-grey-title pt-2">
                                                {
                                                    !!rating?.number_of_reviews  &&
                                                    rating?.number_of_reviews+" out of 5 stars."
                                                }</h6>
                                            <Rating rating={rating?.number_of_reviews ? rating?.number_of_reviews : 0} />
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <>
                        <div className="sigma_service-body">
                            <div className="sigma_rating">


                                <Rating rating={rating?.total_rate ? rating?.total_rate : 0} />
                            </div>
                        </div>
                        <h6 className="small-grey-title pt-2">
                            {
                                !!rating?.total_rate  &&
                                rating?.total_rate+" out of 5 stars."
                            }
                            {" "+rating?.number_of_reviews+" Review(s)"}
                        </h6>
                        </>
                </Modal>

                {/*<Modal*/}
                {/*    backdropColor="transparent"*/}
                {/*    shouldCloseOnOverlayClick={false}*/}
                {/*    isOpen={modalIsOpen}*/}
                {/*    onAfterOpen={afterOpenModal}*/}
                {/*    style={customReviewStyles}*/}
                {/*    contentLabel="Example Modal"*/}
                {/*>*/}
                {/*    <button onClick={closeModal} className="btn_close_modal"><img src="img/remove.svg" /></button>*/}
                {/*    <h3 className="popup-review-title">Review Your Experience with Sofia Verzan</h3>*/}
                {/*    <ul className="rep-popup-list">*/}
                {/*        <li>*/}
                {/*            <div className="d-flex">*/}
                {/*                <h3>Communication</h3>*/}
                {/*                <div className="rep-popup-stars">*/}
                {/*                    <div className="d-flex">*/}
                {/*                        <h6 className="small-grey-title pt-2">3 out of 5 stars.</h6>*/}
                {/*                        <div className="rate">*/}
                {/*                            <input type="radio" id="star5" name="rate" value="5"/>*/}
                {/*                            <label htmlFor="star5" title="text">5 stars</label>*/}
                {/*                            <input type="radio" id="star4" name="rate" value="4"/>*/}
                {/*                            <label htmlFor="star4" title="text">4 stars</label>*/}
                {/*                            <input type="radio" id="star3" name="rate" value="3"/>*/}
                {/*                            <label htmlFor="star3" title="text">3 stars</label>*/}
                {/*                            <input type="radio" id="star2" name="rate" value="2"/>*/}
                {/*                            <label htmlFor="star2" title="text">2 stars</label>*/}
                {/*                            <input type="radio" id="star1" name="rate" value="1"/>*/}
                {/*                            <label htmlFor="star1" title="text">1 star</label>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            <div className="d-flex">*/}
                {/*                <h3>Provided Services</h3>*/}
                {/*                <div className="rep-popup-stars">*/}
                {/*                    <div className="d-flex">*/}
                {/*                        <h6 className="small-grey-title pt-2">3 out of 5 stars.</h6>*/}
                {/*                        <div className="rate">*/}
                {/*                            <input type="radio" id="star5" name="rate" value="5"/>*/}
                {/*                            <label htmlFor="star5" title="text">5 stars</label>*/}
                {/*                            <input type="radio" id="star4" name="rate" value="4"/>*/}
                {/*                            <label htmlFor="star4" title="text">4 stars</label>*/}
                {/*                            <input type="radio" id="star3" name="rate" value="3"/>*/}
                {/*                            <label htmlFor="star3" title="text">3 stars</label>*/}
                {/*                            <input type="radio" id="star2" name="rate" value="2"/>*/}
                {/*                            <label htmlFor="star2" title="text">2 stars</label>*/}
                {/*                            <input type="radio" id="star1" name="rate" value="1"/>*/}
                {/*                            <label htmlFor="star1" title="text">1 star</label>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            <div className="d-flex">*/}
                {/*                <h3>Help & Support</h3>*/}
                {/*                <div className="rep-popup-stars">*/}
                {/*                    <div className="d-flex">*/}
                {/*                        <h6 className="small-grey-title pt-2">3 out of 5 stars.</h6>*/}
                {/*                        <div className="rate">*/}
                {/*                            <input type="radio" id="star5" name="rate" value="5"/>*/}
                {/*                            <label htmlFor="star5" title="text">5 stars</label>*/}
                {/*                            <input type="radio" id="star4" name="rate" value="4"/>*/}
                {/*                            <label htmlFor="star4" title="text">4 stars</label>*/}
                {/*                            <input type="radio" id="star3" name="rate" value="3"/>*/}
                {/*                            <label htmlFor="star3" title="text">3 stars</label>*/}
                {/*                            <input type="radio" id="star2" name="rate" value="2"/>*/}
                {/*                            <label htmlFor="star2" title="text">2 stars</label>*/}
                {/*                            <input type="radio" id="star1" name="rate" value="1"/>*/}
                {/*                            <label htmlFor="star1" title="text">1 star</label>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            <div className="d-flex">*/}
                {/*                <h3>Commitments & Responsibilities</h3>*/}
                {/*                <div className="rep-popup-stars">*/}
                {/*                    <div className="d-flex">*/}
                {/*                        <h6 className="small-grey-title pt-2">3 out of 5 stars.</h6>*/}
                {/*                        <div className="rate">*/}
                {/*                            <input type="radio" id="star5" name="rate" value="5"/>*/}
                {/*                            <label htmlFor="star5" title="text">5 stars</label>*/}
                {/*                            <input type="radio" id="star4" name="rate" value="4"/>*/}
                {/*                            <label htmlFor="star4" title="text">4 stars</label>*/}
                {/*                            <input type="radio" id="star3" name="rate" value="3"/>*/}
                {/*                            <label htmlFor="star3" title="text">3 stars</label>*/}
                {/*                            <input type="radio" id="star2" name="rate" value="2"/>*/}
                {/*                            <label htmlFor="star2" title="text">2 stars</label>*/}
                {/*                            <input type="radio" id="star1" name="rate" value="1"/>*/}
                {/*                            <label htmlFor="star1" title="text">1 star</label>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </li>*/}
                {/*    </ul>*/}
                {/*    <div className="text-center">*/}
                {/*        <button className="btn_submit_dark">Submit</button>*/}
                {/*    </div>*/}
                {/*</Modal>*/}
            </BannerContainer>
            <FooterImage />
            <ContactForm />

        </>
    )
}
