import React, {useEffect, useState} from 'react';
import {nurseApplication} from "../../adapters/agencyAdapter";
import {useQuery} from "react-query";
import {getCurrentDate} from "../../adapters/nurseAdapter";
import {Dashboard} from "../UserCommon";
import AgencyDashboardCalendar from "./AgencyDashboardCalendar";
import {NurseUpperBar} from "./NurseUpperBar";
import AgencyDashboardCalendarTwo from "./AgencyDashboardCalendarTwo";
import AgencyDashboardCalendarThree from "./AgencyDashboardCalendarThree";
import AgencyDashboardCalendarFinish from "./AgencyDashboardCalendarFinish";
import {AgencyDashboardProvider, useAgencyDashboard} from "./AgencyDashboardProvider";


export default function AgencyDashboard() {

    const {reloadDashboard} = useAgencyDashboard()
    const {  refetch,isFetching,data } = useQuery(['current_application'], nurseApplication ,
        {
            cacheTime : 0,
            staleTime : 0,
            refetchOnWindowFocus: false, // default: true
            refetchOnMount:false,
        });
    const [application,setApplication]= useState(null)
    useEffect(function (){
        if(data){
            setApplication(data.data.data);
        }
    },[data])
    useEffect(function (){
        if(data) {
            refetch();
        }
    },[reloadDashboard])

    return (
        <>

                <Dashboard>
                   {!isFetching &&  !!application &&
                       <>
                           <div className="col-md-9 pt-lg-0 pt-5">
                               <div className="sigma_subheader_inner style-5">
                                   <div className="container">
                                       <div className="row">
                                           <div className="col-md-10">
                                               <NurseUpperBar applicant={application.candidate}/>
                                               {!application.meeting_data.has_meeting && <AgencyDashboardCalendar application={application}/>}

                                               {   application.meeting_data.has_meeting &&
                                                   <>
                                                   {application.status =="Interview Processing" &&
                                                        <>
                                                            <AgencyDashboardCalendarTwo application={application}/>
                                                        </>
                                                   }
                                                   {application.status =="Approved" &&
                                                       <>
                                                           <AgencyDashboardCalendarThree application={application}/>
                                                       </>
                                                   }
                                                   {(application.status =="Offer Pending" || application.status =="Offer Accepted") &&
                                                       <>
                                                           <AgencyDashboardCalendarFinish application={application}/>
                                                       </>
                                                   }
                                                   </>
                                               }
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </>
                   }
               </Dashboard>

        </>
    );
}



