import axios from "axios";
import {addUserDataToLocalStorage} from "../app/auth";

export  function  referralRegister(data) {
    return  axios.post("referral_register",data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(res => {
        res = res.hasOwnProperty('response') ? res.response :res

        if(res.status == 200) {

            addUserDataToLocalStorage(res.data.data, res.data.data.access_token);
        }
        return res

        //reload page
    })
}