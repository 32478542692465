import {useEffect, useState} from "react";
import $ from "jquery";
import {motion} from "framer-motion";
import React from "react";
import {BigModal} from "./bigModal";
import {AvailabilityGeneral} from "./AvailabilityGeneral";
import Modal from 'react-modal';
import {useAuth} from "../../../app/auth";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        maxWidth: "800px",
        minWidth: "320px",

        transform: 'translate(-50%, -50%)',
        backgroundColor:"#fff",

    },
    overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(70, 70, 70, 0.5)',

    }

};
Modal.setAppElement('#root2');


export const AvailabilityModal = () => {
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(true);
    const {availabilityCount}=useAuth();
    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    return (

        <Modal
            backdropColor="transparent"
            shouldCloseOnOverlayClick={false}
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            style={customStyles}
            contentLabel="Example Modal"
        >

            <AvailabilityGeneral count={availabilityCount} isModal={true}/>
        </Modal>
    )
}

