import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;
const SANCTUM_URL = API_URL + "/" + process.env.REACT_APP_SANCTUM_COOKIE_URL;
// console.log(process.env);
export const GET_USER = `${API_URL}/Profile`;
export const LOGIN_URL = `${API_URL}/login`;
export const LOGOUT_URL = `${API_URL}logout`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;
// Server should return AuthModel
export function login(email, password) {
    return axios.get(SANCTUM_URL).then(response => {
        return axios.post(LOGIN_URL, {
            email,
            password,
        });
    });
}
// Server should return AuthModel
export function register(email, firstname, lastname, password, password_confirmation) {
    return axios.post(REGISTER_URL, {
        email,
        first_name: firstname,
        last_name: lastname,
        password,
        password_confirmation,
    });
}
// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email) {
    return axios.post(REQUEST_PASSWORD_URL, {
        email,
    });
}
export function getUserData() {
    return axios.get(GET_USER);
}
export function requestLogout() {
    return axios.post(LOGOUT_URL);
}