import React from "react";

export default function FlipCard({FrontTitle,FrontImage,BackTitle, BackDescription, Link,LinkLabel,cardFlip}) {


    return (
        <div className="col-md-4 mb-5">
            <div className="flip-card">
                <div className={cardFlip=="1" ? "flip-card-inner" : ''}>
                    <div className="flip-card-front">
                        <div className="mirage-box-front">
                            <div className="mirage-bg">
                                <img src={FrontImage}/>
                            </div>
                            <div className="mirage-inner">
                                <h4>
                                    {FrontTitle}
                                </h4>
                                <a className="btn-dark-n-2" href={Link} target="_blank">{LinkLabel}</a>
                            </div>

                        </div>
                    </div>
                    <div className="flip-card-back">
                        <div className="mirage_back">
                            <h3 className="mirage_title_back">{BackTitle} </h3>
                            <p className="mirage_p_back">
                                <div dangerouslySetInnerHTML={{__html: BackDescription}} />


                            </p>
                            <a className="btn-dark-n mt-3"  href={Link} target="_blank">{LinkLabel}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
