import React from 'react';
import { ContactForm } from "../../Common/ContactForm";
import { BannerContainer } from "../../Common/BannerContainer";
import { TitleOnlyBanner } from "../../Common/TitleOnlyBanner";
import { useInfiniteQuery, useQuery } from "react-query";
import { getTestimonials, getTestimonialsSettings } from "../../../adapters/TestimonialsAdapter";
import { getNextPage } from "../../../Helpers/ReactQueryHelper";
import Pagination from "../../Common/Pagination";
import { TestimonialCard } from "../../Common/TestimonialCard";
import Lottie from "lottie-react";
import * as LeftData from "../../../assets/animation/AbstractDotsBgLeft.json";
import * as rightData from "../../../assets/animation/AbstractDotsBgRight.json";
import FooterImage from "../../Common/FooterImage";



export default function Guest() {
    const { data: setting } = useQuery('testimonials-setting', getTestimonialsSettings,
        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });

    const {
        status,
        data,
        error,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useInfiniteQuery(
        ['e-learnings'],
        async ({ pageParam = 1 }) => {
            const res = await getTestimonials(pageParam)
            return res.data
        },
        {
            getNextPageParam: getNextPage,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,

        },
    )

    return (
        <>
            <BannerContainer style={{
                background: "#fff"
            }}>
                <div className="animation-left">
                    <Lottie animationData={LeftData} />
                </div>
                <div className="animation-right">
                    <Lottie animationData={rightData} />
                </div>
                <TitleOnlyBanner
                    title={'Our Testimonials'}
                    description={setting?.data.data.OurTestimonials?.Description}
                />

                <div className="">
                    <Pagination
                        data={data}
                        isFetching={isFetching}
                        fetchNextPage={fetchNextPage}
                        isFetchingNextPage={isFetchingNextPage}
                        hasNextPage={hasNextPage}
                    >{(testimonial) => {
                        return <div className="col-lg-4 col-md-4 col-sm-6">
                            <TestimonialCard
                                rating={testimonial.rating_stars}
                                description={testimonial.review}
                                name={testimonial.user?.full_name}
                                image={testimonial.user?.image}
                                type={testimonial.user?.type}
                            />
                        </div>
                    }}
                    </Pagination>
                </div>
            </BannerContainer>
            <FooterImage />
            <ContactForm />

        </>
    )
}
