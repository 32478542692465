import React from "react";
import {BannerContainer} from "../../Common/BannerContainer";
import Banner from "../../Common/Banner";
import FlipCard from "../Common/FlipCard";
import FooterImage from "../../Common/FooterImage";
import {ContactForm} from "../../Common/ContactForm";
import {useInfiniteQuery, useQuery} from "react-query";
import {getSponsorship, getSponsorshipSettings} from "../../../adapters/ForumsAdapter";
import {getNextPage} from "../../../Helpers/ReactQueryHelper";
import Pagination from "../../Common/Pagination";
import * as animationData from '../../../assets/animation/Immigration.json';
import Lottie from "lottie-react";
import * as LeftData from "../../../assets/animation/AbstractDotsBgLeft.json";
import * as rightData from "../../../assets/animation/AbstractDotsBgRight.json";


export default function Sponsorship() {
    const {  data:setting } = useQuery('sponsorship-setting', getSponsorshipSettings ,
        {
            cacheTime : 1000 * 60 * 60 * 24,
            staleTime : 1000 * 60 * 60 * 12,
        });

    const {
        status,
        data,
        error,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useInfiniteQuery(
        ['PetitionGreencard'],
        async ({ pageParam = 1 }) => {
            const res = await getSponsorship(pageParam,"Petition_Greencard")
            return res.data
        },
        {
            getNextPageParam: getNextPage,
            cacheTime : 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,

        },
    )
    const {
        status:citizenshipStatus,
        data:citizenshipData,
        error:citizenshipError,
        isFetching:citizenshipIsFetching,
        isFetchingNextPage:citizenshipIsFetchingNextPage,
        fetchNextPage:citizenshipFetchNextPage,
        hasNextPage:citizenshipHasNextPage,

    } = useInfiniteQuery(
        ['Citizenship'],
        async ({ pageParam = 1 }) => {
            const res = await getSponsorship(pageParam,"Citizenship")
            return res.data
        },
        {
            getNextPageParam: getNextPage,
            cacheTime : 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,

        },
    )
    return (
        <>
            <BannerContainer style={{
                background: "#fff"
            }}>
                <div className="animation-left">
                    <Lottie animationData={LeftData} />
                </div>
                <div className="animation-right">
                    <Lottie animationData={rightData} />
                </div>
                <Banner
                    title={'sponsorship & Immigration'}
                    subTitle={'FORUM'}
                    paragraph={setting?.data.data.SponsershipImmigrationDetails?.description}
                    animationData={animationData}
                />
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <h3 className="inner-title">Petition & Greencard</h3>
                    </div>
                    <Pagination
                        data={data}
                        isFetching={isFetching}
                        fetchNextPage={fetchNextPage}
                        isFetchingNextPage={isFetchingNextPage}
                        hasNextPage={hasNextPage}
                    >{(currentRecord) => {
                        return <FlipCard
                            FrontTitle={currentRecord.title}
                            FrontImage={currentRecord.image}
                            Link={currentRecord.link}
                            LinkLabel={setting?.data.data.SponsershipImmigrationDetails?.button_title}
                            cardFlip={setting?.data.data.SponsershipImmigrationDetails?.card_flip}
                            BackTitle={currentRecord.title}
                            BackDescription={currentRecord.description}
                        />
                    }}
                    </Pagination>

                </div>
                <div className="row justify-content-center mt-5">
                    <div className="col-md-12">
                        <h3 className="inner-title">Citizenship</h3>
                    </div>
                    <Pagination
                        data={citizenshipData}
                        isFetching={citizenshipIsFetching}
                        fetchNextPage={citizenshipFetchNextPage}
                        isFetchingNextPage={citizenshipIsFetchingNextPage}
                        hasNextPage={citizenshipHasNextPage}
                    >{(currentRecord) => {
                        return <FlipCard
                            FrontTitle={currentRecord.title}
                            FrontImage={currentRecord.image}
                            Link={currentRecord.link}
                            LinkLabel={setting?.data.data.SponsershipImmigrationDetails?.button_title}
                            cardFlip={setting?.data.data.SponsershipImmigrationDetails?.card_flip}
                            BackTitle={currentRecord.title}
                            BackDescription={currentRecord.description}
                        />
                    }}
                    </Pagination>

                </div>
            </BannerContainer>
            <FooterImage/>
            <ContactForm/>
        </>
        )
}
