import React, { useEffect, useState } from "react";
import 'jquery-mapael';
import 'jquery-mapael/js/maps/world_countries';
import 'jquery-mapael/js/maps/usa_states';
import $ from "jquery";
export default function StateMapSearch({ statesData }) {
    // console.log(statesData);
    const [search, setSearch] = useState("");
    const [data, setData] = useState(statesData);

    useEffect(() => {
        let $ = require('jquery');

        $(".mapcontainer").mapael({
            map: {
                // Set the name of the map to display
                name: "usa_states",
                defaultArea: {
                    eventHandlers: {
                        "click": function (e, id, mapElem, textElem, elemOptions) {
                            console.log("click", id, "hehe")
                            let state = statesData.filter(function (data) {
                                console.log(data.code , id)
                                return data.code == id
                            });
                            if (state.length > 0) {
                                window.open(
                                    state[0].link, "_blank");

                            }
                        }
                    },
                    attrsHover: {
                        fill: "#ee4b5c"
                    }
                }
            }
        });
    }, [statesData]);

    useEffect(() => {
        setData(statesData)
        console.log(search)
    }, [statesData]);
    let filtered;
    useEffect(() => {
        if (statesData && search) {

            filtered = statesData.filter(function (state) {
                console.log(search, state.title_en, state.title_en.includes(search))
                return state.title_en.toLowerCase().includes(search.toLowerCase());
            })
            // console.log(filtered);
            setData(filtered);

        } else if (statesData) {
            setData(statesData);

        }

    }, [search]);
    const onSearchChange = (e, value) => {
        setSearch(e.target.value)
    }
    console.log(data)

    return (
        <>
        {data && <div className="row mt-5">
            <div className="col-md-6">
                <div className="mapcontainer">
                    <div className="map"></div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="search-box mb-4">
                    <form>
                        <input type="text" value={search} onChange={onSearchChange} name="search" placeholder="Find State" />
                        <button type="submit" className="search-btn">
                            <i className="fal fa-search m-0"></i>
                        </button>
                    </form>
                </div>
                <div className="row">

                    {data && data?.map((value, index) =>
                        <div className="col-md-4" key={index}>
                            <div className="list-states">
                                <a href={value.link} target="_blank">
                                    <img
                                        src={value.flag ?  value.flag: "img/logo-usa.svg" }
                                        style={{ width: "40px", height: "40px" }}
                                    />
                                     <h5>{value.title_en}</h5>
                                </a>
                            </div>
                        </div>

                    )}
                </div>
            </div>
        </div>
        }
            </>
    )
}