import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {CalendarSectionDone} from "./AgencyDashboardSections/CalendarSectionDone";
import {InterviewSectionDone} from "./AgencyDashboardSections/InterviewSectionDone";
import {ContractSection} from "./AgencyDashboardSections/ContractSection";


export default function AgencyDashboardCalendarThree({application}) {
    return (
        <>

            <CalendarSectionDone/>
            <InterviewSectionDone/>
            <ContractSection application={application}/>


        </>
    );
}



