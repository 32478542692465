import * as yup from 'yup';

export let clinicalSchema = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    // gender: yup.mixed().oneOf(['male', 'female']).nullable(),
    email : yup.string().email().required(),
    country_id : yup.string().required("Country is required").nullable(),
    country_code : yup.string().required("Country code is required"),
    mobile : yup.number().required(),
    looking_to_immigrate_date_of_birth: yup.date().nullable(),
    address : yup.string().required().nullable(),
    looking_to_immigrate_resume : yup.mixed().required("resume is required"),
});
