import {useAuth} from "../../../app/auth";
import React from "react";
import {useQuery} from "react-query";
import {Dashboard} from "../../UserCommon";
import { CandidateCard } from "./CandidateCard";
import Pagination from "../../Common/Pagination";
import {getCandadites} from "../../../adapters/representativeAdapter";
import {useInfiniteQuery} from "react-query"
import {getNextPage} from "../../../Helpers/ReactQueryHelper";
import {Link} from "react-router-dom";
export function RepresentativeDashboard(){
  
    let {currentUser} = useAuth();
    const {
        status,
        data,
        error,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useInfiniteQuery(
        ['representative-candidates'],
        async ({ pageParam = 1 }) => {
            const res = await getCandadites(pageParam)
            return res.data
        },
        {
            getNextPageParam: getNextPage,
            cacheTime : 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,

        },
    )
    return (
        <Dashboard>

            <div className="col-md-9">
            <div className="sigma_subheader_inner style-5">
                        <div className="container">
                            <div className="row mb-4">
                                <div className="col-md-9"></div>
                                <div className="col-md-3">
                                    <Link to="/add-candidate" className="btn_submit_dark mt-0 tex-center">add candidate <i className="fa fa-plus"></i></Link>
                                </div>
                            </div>
                            <div className="row">
                            <Pagination
                                data={data}
                                isFetching={isFetching}
                                fetchNextPage={fetchNextPage}
                                isFetchingNextPage={isFetchingNextPage}
                                hasNextPage={hasNextPage}
                            >{(currentRecord) => {
                                    return <CandidateCard
                                        name={currentRecord.text}
                                        token={currentRecord.token}
                                        image={currentRecord.image}
                                        key={currentRecord.id}/>
                            }}
                            </Pagination>
                           
                            </div>
                        </div>
                    </div>
            </div>

        </Dashboard>
    )
}