import axios from "axios";

export function getTestimonialsSettings() {

    return axios.get("settings", {
        params: {
            "module_name": ['OurTestimonials']
        }
    })
}

export function getTestimonials(pageParam) {
    return axios.get("testimonials?include=user&page=" + pageParam)
}
export function getRandomTestimonials() {
    return axios.get("random_testimonials?include=user")
}
export function hasTestimonials() {
    return axios.get('hasTestimonials')
}

export function setTestimonial(data) {
    return axios.post("testimonials",data)
}

