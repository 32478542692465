import axios from "axios";
import { addUserDataToLocalStorage, useAuth } from "../app/auth";



export function Login(data) {
    return axios.post("agency_login?include=details", data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(res => {
        res = res.hasOwnProperty('response') ? res.response : res

        if (res.status == 200) {
            addUserDataToLocalStorage(res.data.data, res.data.data.access_token);
        }
        return res
        //reload page
    });
}

export function getAvailabilityForAgency(startTime, timezone) {
    // console.log(startTime.toLocaleDateString());
    return axios.get("Agency/availability?date=" + startTime + "&timezone=" + timezone)

}
export function nurseApplication() {
    // console.log(startTime.toLocaleDateString());
    return axios.get("Agency/interview_schedule?include=candidate.country,candidate.details");

}

export function meetingData() {
    const searchParams = new URLSearchParams(window.location.search);
    const agency_id = searchParams.get('agency_id') ?? null;
    const user_id = searchParams.get('user_id') ?? null;
    return axios.get(`Agency/meeting_application?include=schedule_time,candidate&agency_id=${agency_id}&user_id=${user_id}`);
}

export function getFileShare(agency_id) {
    let qString = ""

    if (agency_id) {
        qString = "&agency_id=" + agency_id
    }

    // console.log(startTime.toLocaleDateString());
    return axios.get("fileShare?include=sender" + qString);

}
export function storeFileShare(data) {

    return axios.post("fileShare", data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export function FileShareAction(id, action) {

    return axios.delete("fileShare/" + id, { data: { action: action } });
}

export function FileShareDownload(id, action) {

    return axios.put("fileShare/" + id, { action: action });
}
export function agencyInbox(pageParam) {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // console.log(startTime.toLocaleDateString());
    return axios.get("ChatMessages?include=sender&&timezone=" + timezone + "&page=" + pageParam);

}
export function SendMessage(callback) {

    return function (data) {
        console.log(data)
        axios.post("sendMessage", data).then(callback);
    }
}
export function scheduleMeeting(data) {

    return axios.post("Agency/interview_schedule", data);
}
export function getLoginToken(data) {
    return axios.post("get_agency_token", data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export function uploadContract(callback) {
    return function (data) {

        axios.post('upload_contract', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(callback)

            ;
    }
}
export function changeApplicationStatus(data) {
    return axios.put("Agency/interview_schedule", data)
}
// export  function  getAvailability(startTime) {
//     // console.log(startTime.toLocaleDateString());
//     return axios.get("availability?date="+startTime)
//
// }
// export  function  getCurrentDate(data) {
//     return axios.get("user_dates")
//
// }
//
// export  function  Verify(data) {
//     return  axios.post("email_verification",data).then(res => {
//         //reload page
//         // console.log(res.response.status);
//         res = res.hasOwnProperty('response') ? res.response :res
//         if(res.status == 200){
//          window.location.reload();
//         }
//         return res;
//     });
// }
// export  function  insertAvailability(data) {
//     return  axios.post("availability",data);
// }
// export  function  updateProfile(data) {
//     return  axios.post("account_settings",data, {
//         headers: {
//             'Content-Type': 'multipart/form-data'
//         }
//     });
// }
//
// export function getAgencies(pageParam) {
//     return axios.get("agency_list?include=details&page=" + pageParam)
// }