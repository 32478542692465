import React from "react";
import { Link } from "react-router-dom";

export function Card({ Image, link }) {
    return (

        <div class="col-md-3 mb-5">
            <a href={link} target="_blank" rel="noreferrer" class="mirage-box">
                <img src={Image} />
            </a>
        </div>
    )
}









