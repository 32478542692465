import React from "react";
import {BeatLoader, ClipLoader} from 'react-spinners'
export default function Pagination({children,data,fetchNextPage,hasNextPage,isFetchingNextPage,isFetching ,className}) {

    if(isFetching && !isFetchingNextPage){
        return <div className="row mt-5 justify-content-center">
            <div className={'row'}>
                <div className="col text-center">

                <ClipLoader
                        strokeWidth="4"
                        animationDuration="2"
                        width="50"
                        visible={true}
                    />
                </div>
            </div>
        </div>
    }
    return <div className={className ?? "row mt-5 justify-content-center"}>
        {data?.pages.map((page) => {
            { return page.data.map((currentRecord) => {
                return  children(currentRecord)
            })
            }

        })}

        {hasNextPage &&
        <>
         <div className={'row mt-5 justify-content-center'}>
            <div className="col text-center">
                { !isFetchingNextPage ? <><h6 className={'show-more'}
                    // ref={ref}
                        onClick={() => fetchNextPage()}

                >
                    Show More
                        <span className="show-more-icon" ><i className="fa fa-chevron-down"></i></span>

                </h6>

                    </>
                    :<BeatLoader
                        strokeWidth="4"
                        animationDuration="2"
                        width="50"
                        visible={true}
                    />
                }
            </div>
        </div>
        </>

        }
        <div>
            {isFetching && !isFetchingNextPage
                ? 'Background Updating...'
                : null}
        </div>
    </div>

}
