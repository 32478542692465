import React ,{useEffect,useState}from "react";
import {BannerContainer} from "../../Common/BannerContainer";
import Banner from "../../Common/Banner";
import FlipCardPopUp from "../Common/FlipCardPopUp";
import FooterImage from "../../Common/FooterImage";
import {ContactForm} from "../../Common/ContactForm" ;
import {getLawyers, getAskLawyerSettings,insertAskLawyer} from "../../../adapters/ForumsAdapter";
import {useInfiniteQuery, useQuery} from "react-query";
import {getNextPage} from "../../../Helpers/ReactQueryHelper";
import Pagination from "../../Common/Pagination";
import * as animationData from '../../../assets/animation/Lowyer.json';
import 'magnific-popup/dist/jquery.magnific-popup.min'
import 'magnific-popup/dist/magnific-popup.css'
import { handleFormikSubmit} from "../../../Helpers/formHelper";
import { Formik,Form } from 'formik';
import {motion} from "framer-motion";
import {askLawyerSchema} from "../../../schemas/askLawyerSchema";
import {CountryCode, Input} from "../../../Helpers/formComponents";
import {getCountries} from "../../../adapters/commonAdapter";
import Lottie from "lottie-react";
import * as LeftData from "../../../assets/animation/AbstractDotsBgLeft.json";
import * as rightData from "../../../assets/animation/AbstractDotsBgRight.json";
import Modal from "react-modal";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        maxWidth: "800px",
        minWidth: "320px",

        transform: 'translate(-50%, -50%)',
        backgroundColor:"#fff",

    },
    overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(70, 70, 70, 0.5)',

    }

};
Modal.setAppElement('#root2');
export default function AskALawyer() {

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [lawyerId, setLawyerId] = React.useState(false);

    const {  data:setting } = useQuery('lawyers-setting', getAskLawyerSettings ,
        {
            cacheTime : 1000 * 60 * 60 * 24,
            staleTime : 1000 * 60 * 60 * 12,
        });
    const { isFetching2, data:countries } = useQuery('country', getCountries ,
    {
        cacheTime : 1000 * 60 * 60 * 24,
        staleTime : 1000 * 60 * 60 * 12,
    });

    const {
        status,
        data,
        error,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useInfiniteQuery(
        ['lawyers'],
        async ({ pageParam = 1 }) => {
            const res = await getLawyers(pageParam)
            return res.data
        },
        {
            getNextPageParam: getNextPage,
            cacheTime : 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,

        },
    )

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    console.log(setting?.data.data.AskLawyerDetails)
    return (

        <>
            <BannerContainer style={{
                background: "#fff"
            }}>
                <div className="animation-left">
                    <Lottie animationData={LeftData} />
                </div>
                <div className="animation-right">
                    <Lottie animationData={rightData} />
                </div>
                <Banner
                    title={'Ask A Lawyer'}
                    subTitle={'FORUM'}
                    paragraph={setting?.data.data.AskLawyerDetails?.description}
                    animationData={animationData}
                />
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <h3 className="inner-title text-center">Get answers to<br/>
                            your questions quickly</h3>
                    </div>
                    <Pagination
                        data={data}
                        isFetching={isFetching}
                        fetchNextPage={fetchNextPage}
                        isFetchingNextPage={isFetchingNextPage}
                        hasNextPage={hasNextPage}
                    >{(currentRecord) => {
                        return <FlipCardPopUp
                            FrontTitle={currentRecord.title}
                            FrontImage={currentRecord.image}
                            onClick={()=>{
                                setIsOpen(true);
                                setLawyerId(currentRecord.id)
                            }}
                            Link="#ask-popup"
                            LinkLabel="Ask A Lawyer"
                            cardFlip={setting?.data.data.AskLawyerDetails?.card_flip}
                            BackTitle={currentRecord.title}
                            BackDescription={currentRecord.description}
                        />
                    }}
                    </Pagination>

                </div>

            </BannerContainer>
            <FooterImage/>
            <ContactForm/>
            <Modal
                backdropColor="transparent"
                shouldCloseOnOverlayClick={true}
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {/*<div id="ask-popup" className="white-popup-2 mfp-hide">*/}
                <button title="" type="button" className="mfp-close" onClick={()=>setIsOpen(false)}>×</button>
                <h4 className="popup-title">Ask a Lawyer</h4>
                <div className="">
                    <p className="paragraph-text">
                    <div dangerouslySetInnerHTML={{ __html: setting?.data?.data?.AskLawyerDetails?.description }} />
                    </p>

                    {
                (() => {
                    if(!isFetching) {
                        return (
                    <Formik
                        validateOnBlur
                        initialValues={{
                            name: '',
                            surname: '',
                            email: '',
                            country_code: '',
                            subject: '',
                            contact_number: '',
                            lawyer_id: lawyerId,

                        }}
                        validationSchema={askLawyerSchema}
                        onSubmit={handleFormikSubmit(insertAskLawyer)}
                        >
                        {({isValid, isSubmitting , status}) => (
                            <Form method="post">
                                <div className="form-block n-border">
                                    <div className="row">
                                        <div className="col-lg-6">

                                            <Input type="text"   name="name" placeholder="Name (Official)"  />
                                        </div>
                                        <div className="col-lg-6">
                                            <Input type="text"    name="surname" placeholder="Surname (Official) "  />
                                        </div>
                                        <div className="col-lg-12">
                                            <Input  type="email"   name="email" placeholder="E-Mail"  />
                                        </div>

                                        <div className="col-lg-4">
                                            <CountryCode options={countries?.data?.data } name={"country_code"}/>
                                        </div>
                                        <div className="col-lg-8">
                                            <Input  type="number"  name="contact_number" placeholder="Contact Number"   />
                                        </div>
                                        <div className="col-lg-12">
                                            <Input  type="text" name="subject" placeholder="Subject"  />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <motion.button
                                        className={status == 200 ? "btn_submit_dark btn btn-success" :"btn_submit_dark"}
                                        type={"submit"}
                                        whileTap={{ scale: 0.7 }}
                                        onHoverStart={e => {}}
                                        onHoverEnd={e => {}}
                                        disabled={isSubmitting}
                                    >  {status == 200 ? "Success" : (isSubmitting ? "Please wait..." : setting?.data?.data?.AskLawyerDetails?.button_title)}
                                    </motion.button>
                                </div>
                            </Form>
                        )}
                        </Formik>
                         )
                        }
                    })()
                }
                {/*</div>*/}
            </div>
            </Modal>
        </>
    )
}
