import React, { useRef, useEffect, useState } from "react";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    InfoWindow,
    Marker,
} from "react-google-maps";


const MapContainer = (props) => {
    const google = window.google;
    const Places = new google.maps.places.PlacesService(
        document.createElement("div")
    );
    const bounds = new window.google.maps.LatLngBounds();
    const [markers, setMarkers] = useState([]);
    const [selectedPlace, setSelectedPlace] = useState(null);
    // let selectedPlace = null
    useEffect(() => {
        const autocomplete = new google.maps.places.Autocomplete(
            props.autocompleteRef.current,
            {
                types: ["(cities)"],
                fields: ["geometry"],
            }
        );
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (!place.geometry || !place.geometry.location) {
                // User entered the name of a Place that was not suggested and
                // pressed the Enter key, or the Place Details request failed.
                window.alert("No details available for input: '" + place.name + "'");
                return;
            }
            // If the place has a geometry, then present it on a map.
            if (place.geometry && place.geometry.location) {
                console.log(place);
                props.mapRef.current.panTo(place.geometry.location);
                console.log(place.geometry.location);
                search();
            }
        });
    }, [props.mapRef]);
    useEffect(() => {
        if (markers.length > 0) {
            markers.forEach((marker) => {
                bounds.extend(marker.position);
            });
            props.mapRef.current.fitBounds(bounds);
        }
    }, [markers, props.mapRef]);

    // Search for hotels in the selected city, within the viewport of the map.
    function search() {
        const search = {
            bounds: props.mapRef.current.getBounds(),
            types: [props.param.name],
        };

        Places.nearbySearch(search, (results, status, pagination) => {
            console.log(results);

            if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                // Create a marker for each hotel found, and
                // assign a letter of the alphabetic to each marker icon.
                setMarkers(
                    results.map((result) => {
                        let rating = [];
                        if (result.rating) {

                            let ceil = Math.ceil(result.rating);
                            for (let i = 1; i <= ceil; i++) {

                                if (i == ceil && result.rating != ceil) {
                                    rating.push(false)
                                } else {
                                    rating.push(true)
                                }
                            }

                        }





                        return new google.maps.Marker({
                            map: props.mapRef.current,
                            position: result.geometry.location,
                            name: result.name,
                            longitude: result.geometry.location.lng(),
                            location: result.location,
                            place_id: result.place_id,
                            icon: result.icon,
                            rating: rating,
                            photos: result.photos,
                            vicinity: result.vicinity,
                            formatted_phone_number: result.formatted_phone_number,
                            url: "https://www.google.com/maps/place/?q=place_id:" + result.place_id,
                        });
                    })
                );
            }
        });




    }

    return (
        <>
            <GoogleMap
                options={{ streetViewControl: false, zoomControl: true }}
                defaultZoom={5}
                defaultCenter={{ lat: 37.1, lng: -95.7 }}
                ref={props.mapRef}
                onGoogleApiLoaded={({ map }) => map.fitBounds(bounds)}
            >
                {markers.map((marker) => (
                    <div key={marker.place_id}>
                        <Marker
                            position={marker.position}
                            data={marker.title}
                            onClick={() => {
                                setSelectedPlace(marker.place_id);
                                console.log(selectedPlace === marker.place_id);
                            }}
                        >
                            {selectedPlace === marker.place_id && (
                                <InfoWindow
                                    marker={marker}
                                    showingInfoWindow={'true'}
                                    onCloseClick={() => {
                                        setSelectedPlace(null);
                                        console.log(selectedPlace === marker.place_id);
                                    }}
                                    position={marker.position}
                                >
                                    <div>
                                        <div className="card" style={{
                                            width: "18rem"
                                        }}>
                                            {marker.photos && marker.photos.length > 0 ? <img height={"120px"} className={"card-img-top"} src={marker.photos[0].getUrl()} /> : ''}

                                            <div className="card-body">
                                                <h6 className="card-title">

                                                    {marker.icon && <img width={"30px"} className={"pe-2"} src={marker.icon} />}
                                                    <small><b>{marker?.name}</b></small>
                                                </h6>
                                                <p className="card-subtitle mb-2 text-muted"><small>{marker?.vicinity}</small></p>
                                                <div className="sigma_rating">
                                                    {marker.rating && marker.rating.map(function (value) {
                                                        return value ? <i className={"fas fa-star active"}></i> : <i className={"fas fa-star-half active"}></i>
                                                    })
                                                    }
                                                </div>
                                                <br />
                                                {/*{marker.rating && <h6 className="card-subtitle mb-2 text-muted" >Rating : {marker.rating}</h6>}*/}

                                                <a href={marker?.url} target={"_blank"} className="btn btn-primary btn-block text-center">Show in maps</a>
                                            </div>
                                        </div>
                                        {/*{<b><a target={"_blank"} href={marker?.url}>{marker?.name} </a></b>}*/}
                                        {/*<p>{marker?.vicinity}</p>*/}
                                        {/*{marker?.formatted_phone_number && <p>{ marker?.formatted_phone_number}</p>}*/}
                                        {/*{marker.photos && marker.photos.length >0  ?  <img className={"img-thumbnail"} src={marker.photos[0].getUrl()} /> : ''}*/}
                                    </div>
                                </InfoWindow>
                            )}
                        </Marker>
                    </div>
                ))}
            </GoogleMap>
        </>
    );
};

const MapWrapped = withScriptjs(withGoogleMap(MapContainer));

export default function Map(name) {
    const mapRef = useRef(null);
    const autocompleteRef = useRef(null);

    return (
        <>
            <div className="col-md-6 offset-3 mt-5">
                <input
                    ref={autocompleteRef}
                    type="text"
                    placeholder="Search for a location"
                />
            </div>
            <div className="col-md-12 mt-4">
                <div className="hotel-map">
                    <div style={{ height: "70vh" }}>
                        <MapWrapped
                            mapRef={mapRef}
                            autocompleteRef={autocompleteRef}
                            param={name}
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCR4bXy0QjJnAcGE4t65W20UJDcVhGXt7w&libraries=places`}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `100%` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
