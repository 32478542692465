import React from "react";
import {Link} from "react-router-dom";
import { saveAs } from 'file-saver';

export function NurseUpperBar({applicant=null}){
    const saveResume = () => {

        const myArray = applicant?.details?.resume?.split('/');
        const lastElement = myArray.pop();

        saveAs.saveAs(
            applicant?.details?.resume,
            lastElement
        );
    };

    console.log(applicant);
    return  <div className="bg-grey">
                <div className="d-flex">
                    <div className="user-img-list">
                        <img src={applicant.image} />
                    </div>
                    <div className="upperbar-name">
                        <h3 className="user-name">{applicant.full_name}</h3>
                        <h4 className="welcome-text">{applicant.id}</h4>
                    </div>
                    <a className="download_btn"  onClick={saveResume} href={"javascript:;"}>Download Attachment
                        <i className="far fa-long-arrow-down"></i>
                    </a>
                </div>
            </div>
}