import {Dashboard} from "../../../UserCommon";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import React, {useEffect, useRef, useState} from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import {useInfiniteQuery, useQuery} from "react-query";
import {getChats, nurseInbox, SendMessage} from "../../../../adapters/nurseAdapter";
import {getNextPage} from "../../../../Helpers/ReactQueryHelper";
import {messageSchema} from "../../../../schemas/agencySchema";
import {handleFormikSubmit} from "../../../../Helpers/formHelper";
import {Field, Form, Formik} from "formik";
import {Link} from "react-router-dom";


function TabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={false}
            id={`vertical-tabpanel-0`}
            aria-labelledby={`vertical-tab-0`}
            {...other}
        >

                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


export function Inbox(){

    const [value, setValue] = React.useState(0);
    const [chatId,setChatId]= useState(null);
    const [search,setSearch] = useState("");
    const [filteredData,setfilteredData] = useState([]);
    const [userData,setUserData] = useState(null);
    const {  refetch,isFetching,data } = useQuery(['chats'], getChats ,
        {
            cacheTime : 0,
            staleTime : 0,
            refetchOnWindowFocus: false, // default: true
            refetchOnMount:false,
            // suspense:false,
        });
    const {
        status,
        data:inbox,
        error,
        isFetching:inboxIsFetching,
        isFetchingNextPage,
        fetchNextPage,
        reset,
        hasNextPage,
        refetch:inboxRefetch,
    } = useInfiniteQuery(
        ['chatss',chatId],
        async ({ pageParam = 1 }) => {

            const res = await nurseInbox(pageParam,chatId)
            refetch()
            return res.data
        },
        {
            getNextPageParam: getNextPage,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: !!chatId,
            suspense : false

        },
    )

    const [refetchMessages,setRefetchMessages]= useState(false)
    const divRef = useRef(null);
    const [isAtTop, setIsAtTop] = useState(false);
    const handleScroll = () => {

        if (divRef.current) {
            setIsAtTop(divRef.current.scrollTop === 0);
        }
    };
    const handleSearch = (e) => {
        setSearch(e.target.value)
    };
    const loadMore = () => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    };
    useEffect(() => {
        if (isAtTop) {
            loadMore();
        }
    }, [isAtTop]);
    useEffect(() => {

        if (inbox && inbox.pages.length == 1 && divRef.current) {

            divRef.current.scrollTop = divRef.current.scrollHeight;
        }


    }, [inbox,divRef.current]);
    useEffect(() => {
        if (divRef.current) {
            divRef.current.addEventListener("scroll", handleScroll);
        }
    }, [divRef.current]);
    useEffect(function (){
        inboxRefetch()
    },[refetchMessages])



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        if(data){
            setfilteredData(data?.data?.data)
        }
    }, [data]);
    let filtered ;
    useEffect(() => {
        if(data?.data?.data && search){
            filtered = data?.data?.data?.filter(function (val){
                return val.agency.details.company_name.toLowerCase().includes(search.toLowerCase()) ;
            })
            setfilteredData(filtered);

        }else if(data?.data?.data){
            setfilteredData(data?.data?.data);
        }

    }, [search]);
    return (
        <Dashboard>
            <div className="col-md-9">
                <div className="sigma_subheader_inner style-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>Inbox</h2>
                                <p className="paragraph-text">
                                    Stay organized and never miss an important message. All your conversations are displayed here.
                                </p>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3">
                                <div className="inbox-leftside">
                                    <div className="search-inbox-leftside">
                                        <form>
                                            <i className="far fa-search"></i>
                                            <input type="text" onChange={handleSearch} placeholder="Choose One"/>
                                        </form>
                                    </div>
                                    <ul className="inbox-list"
                                        value={value}
                                            onChange={handleChange}>
                                        { filteredData && filteredData?.map(function(aa){


                                            return <>
                                                <li className={chatId==aa.id ? "active":""}
                                                    onClick={()=>{
                                                        setChatId(aa.id)
                                                        setUserData(aa)
                                                    }}
                                                >
                                                    <div className="inbox-users-box">
                                                        <div className="inbox-users-img">
                                                            <Badge badgeContent={aa?.messages_not_readed_by_nurse_count} color="primary">
                                                                <Avatar alt="company icon" src={aa?.agency?.image}/>
                                                            </Badge>
                                                            {/*<img src="img/teams1.png"/>*/}
                                                        </div>
                                                        <h3 className="imbox-user-name">{aa?.agency?.details?.company_name}</h3>
                                                    </div>
                                                </li>
                                            </>
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-9">
                                {!!chatId && <div value={value} index={chatId}>
                                    <div className="msg-container p-0-mobile">
                                            <div className="candidate-name mobile-footer-img">
                                                <div className="d-flex justify-content-start">
                                                    <div className="user-msg">
                                                        <img src={userData?.agency?.image}/>
                                                    </div>


                                                    <h3 className="cad-name">{userData?.agency?.details?.company_name}</h3>
                                                </div>
                                            </div>
                                                <div className="msg_trans">
                                                    <div className="msg-inner" ref={divRef}>
                                                        { inbox?.pages &&
                                                            inbox?.pages.slice(0).reverse().map((page) => {
                                                                return page.data.slice(0).reverse().map((currentRecord) => {
                                                                    return  <>
                                                                        {!currentRecord.is_current_user ? <div className="d-flex justify-content-start">
                                                                                <div className="user-msg">
                                                                                    <img src={currentRecord.sender.image} alt="C"/>
                                                                                </div>
                                                                                <div className="receive-msg">
                                                                                    <p>   {currentRecord.message} </p>
                                                                                    <span className="msg-time">
                                                                                              {currentRecord.formated_date}
                                                                                        </span>
                                                                                </div>
                                                                            </div>:
                                                                            <div className="d-flex justify-content-end">
                                                                                <div className="sender-msg">
                                                                            <span className="msg-sender-time">
                                                                                {currentRecord.formated_date}
                                                                                {/*Dec 7 ,5:27 PM*/}
                                                                            </span>
                                                                                    <p> {currentRecord.message}</p>
                                                                                </div>
                                                                                <div className="user-msg">
                                                                                    <img src={currentRecord.sender.image} alt="nurse icon"/>
                                                                                </div>
                                                                            </div>}
                                                                    </>
                                                                })
                                                            })}
                                                    </div>
                                                </div>
                                                <div className="msg-footer">
                                                    <div className="container">
                                                        <Formik

                                                            validateOnBlur
                                                            initialValues={{
                                                                message: '',
                                                            }}
                                                            validationSchema={messageSchema}
                                                            onSubmit={(values,{resetForm})=>{
                                                                resetForm();
                                                                handleFormikSubmit(SendMessage(function(){
                                                                    setRefetchMessages(!refetchMessages)

                                                                })({...values,chat_id:chatId}))
                                                            }
                                                            }
                                                        >
                                                            {({isValid, isSubmitting , status}) => (

                                                                <Form method="post">
                                                                    <div className={"row"} >
                                                                        <div className="col-md-1 p-0 text-center">
                                                                        <span className="btn_upload_msg">
                                                                            <Link to={'/file-sharing'} >

                                                                            <img src="img/attachment.svg"/>
                                                                            </Link>
                                                                        </span>
                                                                        </div>
                                                                        <div className="col-md-8 p-0">
                                                                            <Field  type="text" name={'message'} placeholder="Write your message"/>
                                                                            {/*<Input errorShow={false} type="text" name={'message'} placeholder="Write your message"/>*/}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <button className="btn_send_msg">
                                                                                Send
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            )}
                                                        </Formik>
                                                    </div>
                                                </div>
                                            </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dashboard>
    )
}