import React from "react";
import {BannerContainer} from "../../Common/BannerContainer";
import Banner from "../../Common/Banner";
import FlipCard from "../Common/FlipCard";
import FooterImage from "../../Common/FooterImage";
import {ContactForm} from "../../Common/ContactForm";
import {useInfiniteQuery, useQuery} from "react-query";
import {getELearning, getELearningSettings} from "../../../adapters/ForumsAdapter";
import {getNextPage} from "../../../Helpers/ReactQueryHelper";
import Pagination from "../../Common/Pagination";
import * as animationData from '../../../assets/animation/Elearning.json';
import Lottie from "lottie-react";
import * as LeftData from "../../../assets/animation/AbstractDotsBgLeft.json";
import * as rightData from "../../../assets/animation/AbstractDotsBgRight.json";


export default function ELearning({}) {
    const {  data:setting } = useQuery('e-learning-setting', getELearningSettings ,
        {
            cacheTime : 1000 * 60 * 60 * 24,
            staleTime : 1000 * 60 * 60 * 12,
        });

    const {
        status,
        data,
        error,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useInfiniteQuery(
        ['e-learnings'],
        async ({ pageParam = 1 }) => {
            const res = await getELearning(pageParam)
            return res.data
        },
        {
            getNextPageParam: getNextPage,
            cacheTime : 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,

        },
    )
    return (
        <>
            <BannerContainer style={{
                background: "#fff"
            }}>
                <div className="animation-left">
                    <Lottie animationData={LeftData} />
                </div>
                <div className="animation-right">
                    <Lottie animationData={rightData} />
                </div>
                <Banner
                    title={'E-Learning'}
                    subTitle={'FORUM'}
                    paragraph={setting?.data.data.ELearningDetails?.description}
                    animationData={animationData}
                />
                <div className="row justify-content-center">
                    <Pagination
                        data={data}
                        isFetching={isFetching}
                        fetchNextPage={fetchNextPage}
                        isFetchingNextPage={isFetchingNextPage}
                        hasNextPage={hasNextPage}
                    >{(currentRecord) => {
                        console.log(currentRecord)
                        return <FlipCard
                            FrontTitle={currentRecord.title}
                            FrontImage={currentRecord.image}
                            Link={currentRecord.link}
                            LinkLabel={setting?.data.data.ELearningDetails?.button_title}
                            cardFlip={setting?.data.data.ELearningDetails?.card_flip}
                            BackTitle={currentRecord.title}
                            BackDescription={currentRecord.description}
                        />
                    }}
                    </Pagination>


                </div>
            </BannerContainer>
            <FooterImage/>
            <ContactForm/>
        </>
        )
}
