import React from "react";

export function TitleLeftBanner({title,description,subTitle,descriptionTwo}){
    return (
        <>
            <h2>{title}</h2>
            <p className="subtitile">{subTitle}</p>
            <p className="paragraph-text" style={{marginBottom: "0px"}}>
                {description}
            </p>
            <p className="paragraph-text" style={{marginTop: "2px"}}>
                {descriptionTwo}
            </p>
        </>
    )
}
