import React, {Suspense, useEffect} from 'react'
import { Outlet} from 'react-router-dom'
import {Footer, Header, ToTop} from './layout/core'

import Splash from "./layout/core/Splash";
import {useAuth} from "./app/auth";
import {OptVerification} from "./Components/NurseUser/General/optVerification";
import {AvailabilityModal} from "./Components/NurseUser/General/AvailabilityModal";
import {AgencyReview} from "./Components/NurseUser/General/AgencyReview";
import {RepresentativeReview} from "./Components/NurseUser/General/RepresentativeReview";
const App = () => {
    let {showModal} = useAuth();
    
  return (


          <Suspense fallback={<Splash/>}>
              {showModal && showModal == "verification" && <OptVerification/> }
              {showModal  && showModal == "agencyReview"&& <AgencyReview/> }
                {showModal  && showModal == "representativeReview"&& <RepresentativeReview/> }
          
                  <>
                      <Header/>
                      <Outlet />
                      <Footer/>
                      <ToTop/>
                  </>
          </Suspense>
  )
}


export {App}
