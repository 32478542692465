import React from "react";

export default function FlipCard({title,description,image,link}) {

    const trimmedDescription = description.slice(0, 255) + (description.length > 255 ? "..." : "");
    return (
        <div className="col-md-4 mb-5">
            <div className="flip-card">
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <div className="specialities_front">
                            <img src={image}/>
                            <h3 className="specialities_title">{title}</h3>
                        </div>
                    </div>
                    <div className="flip-card-back">
                        <div className="specialities_back">
                            <h3 className="specialities_title_back">{title}</h3>
                            <p className="specialities_p_back">
                                {trimmedDescription}
                            </p>
                            <a className="btn-dark-n mt-3" href={link} target="_blank">visit website</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}