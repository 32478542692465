// import {nurseUpdateProfileSchema} from "../../../../schemas/";
import {handleFormikSubmit} from "../../../../Helpers/formHelper";
import {updateProfile} from "../../../../adapters/nurseAdapter";
import {Form, Formik} from "formik";
import {useAuth} from "../../../../app/auth";
import {
    Countries,
    CountryCode, Date,
    File,
    FormikCheckBox,
    Gender,
    Input,
    Password, States
} from "../../../../Helpers/formComponents";
import React from "react";
import {useQuery} from "react-query";
import {getCountries} from "../../../../adapters/commonAdapter";
import {motion} from "framer-motion";
import {Dashboard} from "../../../UserCommon";
import {getStatesLicensure} from "../../../../adapters/ForumsAdapter";

export function AccountSettings(){
    const { isFetching, data:countries } = useQuery('country', getCountries ,
        {
            cacheTime : 1000 * 60 * 60 * 24,
            staleTime : 1000 * 60 * 60 * 12,
        });
    let {currentUser} = useAuth()

    const { data:states_data } = useQuery('states-data', getStatesLicensure ,
        {
            cacheTime : 1000 * 60 * 60 * 24,
            staleTime : 1000 * 60 * 60 * 12,
        });
    return (
        <Dashboard>

            <div className="col-md-9">
                <div className="sigma_subheader_inner style-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Account Settings</h2>
                                <p className="paragraph-text">
                                    Manage and update your account information and notification preferences. Keep this information up-to-date to ensure seamless account activity.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">

                                {currentUser && !isFetching && <Formik
                                    validateOnBlur
                                    initialValues={{
                                        first_name: currentUser.first_name ?? "",
                                        last_name: currentUser.last_name ?? "",
                                        email: currentUser.email ?? "",
                                        gender: currentUser.gender ?? "",
                                        country_code: currentUser.country_code ?? "",
                                        mobile: currentUser.mobile ?? "",
                                        password: '',
                                        current_password: '',
                                        country_id: currentUser.country_id ?? "",
                                        offer_mail: currentUser.offer_mail ? 1:0,
                                        newsletter: currentUser.newsletter ? 1:0,
                                        date_of_birth: currentUser?.details?.date_of_birth ?? "",
                                        specialty: currentUser?.details?.specialty ?? "",
                                        years_of_experience: currentUser?.details?.years_of_experience ?? "",
                                        rn_license_number: currentUser?.details?.rn_license_number ?? "",
                                        issuing_state_id: currentUser?.details?.issuing_state_id ?? "",
                                        expiry_date: currentUser?.details?.expiry_date ?? "",
                                        image: null,
                                    }}
                                    // validationSchema={nurseUpdateProfileSchema}
                                    onSubmit={handleFormikSubmit(updateProfile)}
                                >
                                    {({isValid, isSubmitting , status}) => {
                                        return (
                                            <Form method="post" className="form-rep">
                                                <h4 className="past-text">Personal information</h4>
                                                <div className="form-block n-border mb-4">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <Input type="text"   name="first_name" placeholder="First Name (Official)" disabled />

                                                        </div>
                                                        <div className="col-lg-6">
                                                            <Input type="text"    name="last_name" placeholder="Last Name (Official) " disabled />
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <Input  type="email"   name="email" placeholder="E-Mail" disabled />

                                                        </div>
                                                        <div className="col-lg-6">
                                                            <Gender  name="gender" />
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <CountryCode options={countries?.data?.data } name={"country_code"}/>

                                                        </div>
                                                        <div className="col-lg-3">
                                                            <Input  type="tel"  name="mobile" placeholder="Contact Number"   />
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <File name={"image"} label={"Profile photo"}/>

                                                        </div>
                                                        <div className="col-lg-6">

                                                            <Date  label="Date of birth (Optional)" name={'date_of_birth'}/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <h4 className="past-text">Securıty <span>(Change password)</span></h4>
                                                <div className="form-block n-border mb-4">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <Password  name="current_password" placeholder="Current Password"   />

                                                        </div>
                                                        <div className="col-lg-6">
                                                            <Password  name="password" placeholder="New Password"   />

                                                        </div>
                                                    </div>
                                                </div>

                                                <h4 className="past-text">Address information</h4>
                                                <div className="form-block n-border mb-4">
                                                    <div className="row">

                                                        <div className="col-lg-6">
                                                            <Countries options={countries?.data?.data } name={"country_id"} />
                                                        </div>

                                                    </div>
                                                </div>
                                                <h4 className="past-text">License & experience</h4>

                                                <div className="row">

                                                    <div className="col-lg-6">
                                                        <Input type={"text"} name={"specialty"}  placeholder="Specialty"/>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <Input type={"text"} name={"years_of_experience"}  placeholder="Years of Experience"/>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <Input type={"text"} name={'rn_license_number'} placeholder="RN License Number"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <States name={'issuing_state_id'} label={"Issuing State"} placeholder={"Issuing State"} options={states_data?.data?.data } />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <Date name={"expiry_date"} label={"Expiry Date"}/>
                                                    </div>
                                                </div>
                                                <h4 className="past-text">permissions and notices</h4>
                                                <div className="form-block n-border mb-4">
                                                    <div className="row">
                                                        <div className="col-lg-6">

                                                            <FormikCheckBox  name={'offer_mail'} label='I would like to receive emails regarding offers.'/>

                                                        </div>
                                                        <div className="col-lg-6"></div>
                                                        <div className="col-lg-6">
                                                            <FormikCheckBox  className={"d-flex align-items-center mt-4"} name={'newsletter'} label='I would like to receive newsletter.'/>
                                                        </div>
                                                        <div className="col-lg-6"></div>
                                                        <div className="col-md-6">
                                                            <motion.button
                                                                className={status == 200 ? "btn_submit_dark btn btn-success" :"btn_submit_dark"}
                                                                type={"submit"}
                                                                whileTap={{ scale: 0.7 }}
                                                                onHoverStart={e => {}}
                                                                onHoverEnd={e => {}}
                                                                disabled={isSubmitting}
                                                            >  {status == 200 ? "Changes saved successfully" : (isSubmitting ? "Please wait..." : "SAVE ACCOUNT SETTINGS")}
                                                            </motion.button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>)
                                    }
                                    }
                                        </Formik>}

                                        </div>
                        </div>
                    </div>
                </div>
            </div>

        </Dashboard>
    )
}