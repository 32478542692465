import React from 'react';
import { ContactForm } from "../Common/ContactForm";
import { Link } from "react-router-dom";
import * as animationData from "../../assets/animation/Apply_3.json";
import { LoginContainer } from "../Common/LoginContainer";
import { Form, Formik } from "formik";
import { emailLoginSchema } from "../../schemas/emailLoginSchema";
import { handleFormikSubmit } from "../../Helpers/formHelper";
import { Login } from "../../adapters/nurseAdapter";
import { AcceptTermsCondition, Countries, CountryCode, FormikRecaptcha, Input, Password, Recaptcha } from "../../Helpers/formComponents";
import { motion } from "framer-motion";
import * as SigninAnimation from '../../assets/animation/SignIn.json';



export default function NurseLogin() {


    return (
        <>
            <LoginContainer
                // image={'img/login-img.png'}
                animationData={SigninAnimation}
                formTitle={'Welcome back,'}
                formDescription={'Please log in to keep up with nursing opportunities and access your profile dashboard.'}
            >
                <Formik
                    validateOnBlur
                    initialValues={{
                        first_name: '',
                        last_name: '',
                        password: '',
                        email: '',
                        country_id: '',
                        country_code: '',
                        mobile: '',
                    }}
                    validationSchema={emailLoginSchema}
                    onSubmit={handleFormikSubmit(Login)}
                >
                    {({ isValid, isSubmitting, status }) => (
                        <Form method="post">
                            <div className="form-block n-border">
                                <div className="row">

                                    <div className="col-lg-12">
                                        <Input type="email" name="email" placeholder="E-Mail" />
                                    </div>


                                    <div className="col-lg-12">
                                        <Password name="password" placeholder="Password" />
                                    </div>

                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-2">
                                <div>
                                    <input type="checkbox" id="checkbox" name="checkbox" />
                                    <label className="mb-0 checkbox-label" htmlFor="checkbox">Remember for 30 days</label>
                                </div>

                                <div>
                                    <Link to="/forget-password" className="forget_text">Forget Password</Link>
                                </div>
                            </div>
                            <FormikRecaptcha />

                            <div className="col-md-12">
                                <motion.button
                                    className={status == 200 ? "btn_submit_dark btn btn-success" : "btn_submit_dark"}
                                    type={"submit"}
                                    whileTap={{ scale: 0.7 }}
                                    onHoverStart={e => { }}
                                    onHoverEnd={e => { }}
                                    disabled={isSubmitting}
                                >  {status == 200 ? "Success" : (isSubmitting ? "Please wait..." : "Submit")}
                                </motion.button>
                            </div>
                        </Form>
                    )}
                </Formik>

                <div className="">

                    <h3 className="account-text">I have an account, <Link to="/nurses-with-rn-signup"><b>Sign
                        up</b></Link></h3>
                </div>
            </LoginContainer>
            {/* <ContactForm /> */}

        </>
    )
}
