import {emailLoginSchema} from "../../schemas/emailLoginSchema";
import {handleFormikSubmit} from "../../Helpers/formHelper";
import {Login} from "../../adapters/nurseAdapter";
import {Form, Formik} from "formik";
import {LoginContainer} from "../Common/LoginContainer";
import {Input} from "../../Helpers/formComponents";
import {sendOtp} from "../../adapters/authAdapter";
import {sendOtpSchema} from "../../schemas/forgetPasswordSchema";
import {motion} from "framer-motion";
import React from "react";
import {useNavigate} from "react-router-dom";
import * as SigninAnimation from '../../assets/animation/SignIn.json';


export function ForgetPassword(){
    const navigate = useNavigate();
    const sendFunc = sendOtp(
        (res,data)=>{
            res = res.hasOwnProperty('response') ? res.response :res
            if(res.status == 200) {
                navigate(`/reset-password-form?email=${data.email}`);
            }
            return res
        }
    )

    return (

    <LoginContainer
           // image={'img/login-img.png'}
            animationData={SigninAnimation}
            formTitle={'Forgot Password ?'}
            formDescription={'Please enter your email address and we\'ll send you a link to reset your password.'}
        >
            <Formik
                validateOnBlur
                initialValues={{
                    email: '',
                }}
                validationSchema={sendOtpSchema}
                onSubmit={handleFormikSubmit(sendFunc)


            }
            >
                {({isValid, isSubmitting , status}) => (
                    <Form method="post">
                                <div className="form-block n-border">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <Input type="email" name="email" placeholder="E-mail"/>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <motion.button
                                        className={status == 200 ? "btn_submit_dark btn btn-success" :"btn_submit_dark"}
                                        type={"submit"}
                                        whileTap={{ scale: 0.7 }}
                                        onHoverStart={e => {}}
                                        onHoverEnd={e => {}}
                                        disabled={isSubmitting}
                                    >  {status == 200 ? "Success" : (isSubmitting ? "Please wait..." : "Reset Password")}
                                    </motion.button>
                                </div>
                    </Form>
                    )}
            </Formik>
    </LoginContainer>
)
}
