
import React from "react";
import ScriptTag from 'react-script-tag';

export default function Payment() {
    const options = {
        // passing the client secret obtained from the server
        clientSecret: 'sk_test_51GmLs2EKEYXoqpDUelvkUvjs4zRPbfTFdAx0Lm0Rpw3vtz06dqYP3rB1z8mn5uOx3f8sWmZ31xeDVc8weguOixxc00hYQGqwO1',
    };



    return (
        <>


        </>
        )
}

