import {useEffect, useState} from "react";
import {
    getAvailability,
    getCurrentDate,
    insertAvailability,
} from "../../../adapters/nurseAdapter";
import {motion} from "framer-motion";
import React from "react";
import {useMutation, useQuery} from "react-query";
import {formMutationOptions, onErrorDefaultAlert, onSuccessDefaultAlert} from "../../../Helpers/ReactQueryHelper";
import {BigModal} from "./bigModal";
import {useAuth} from "../../../app/auth";
import {Link} from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {getAvailabilityForAgency, scheduleMeeting} from "../../../adapters/agencyAdapter";
import TimezoneSelect from "react-timezone-select";
import moment from "moment-timezone";
import {useAgencyDashboard} from "../../AgencyDashboard/AgencyDashboardProvider";


export const AvailabilityGeneral = ({children,showSubmit=true,showForm=true,isModal=false,count=null}) => {
    const [startTime,setStartTime] = useState(new Date())
    const [isPrevious,setIsPrevious] = useState(false)
    const [availableDates,setAvailableDates] = useState([])
    const [reservedDates,setReservedDates] = useState([])
    const [selectedDate,setSelectedDate] = useState(null)
    const [availableDatesIds,setAvailableDatesIds] = useState([])

    const [message, setMessage] = useState({"text" : "", "class" : "text-danger"})
    const {currentUser}= useAuth()
    const [timezone,setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
    const {reloadDashboard,setReloadDashboard} = useAgencyDashboard()

    const mutation = useMutation(data => {
        if(currentUser.type=="Agency"){


            return scheduleMeeting({...data,timezone})

        }else{
            return insertAvailability({...data,timezone}).then((res)=>{
                if(isModal){
                    window.location.reload();
                }
                return res
            })

        }
    },{
        onSuccess: (data) => {
            if(currentUser.type=="Agency") {
                setReloadDashboard(!reloadDashboard);
            }
            window.location.href = "/dashboard";
        },
        onError: (error) => {
            setMessage(onErrorDefaultAlert(error))
        }
    })

    const {  isFetching,data:dates } = useQuery(['availability',getFormattedDateTime(startTime).date,timezone], ()=>currentUser.type =="Agency" ? getAvailabilityForAgency(getFormattedDateTime(startTime).date,timezone) : getAvailability(getFormattedDateTime(startTime).date,timezone) ,
        {
            cacheTime : 0,
            staleTime : 0,
            suspense: false,
            // refetchInterval:10000
            refetchOnWindowFocus: false, // default: true
            refetchOnMount:false

        });

    const {  isFetching:dateFetch,data:current_date } = useQuery(['current_date',timezone], ()=>getCurrentDate(timezone) ,
        {
            cacheTime : 0,
            staleTime : 0,
            refetchOnWindowFocus: false, // default: true
            refetchOnMount:false,
            suspense: false,
        });

    function getDateNow(current_date){
        if(current_date !== undefined){

            let stringDate =current_date.data.data.current_date;

            let dateParts = stringDate.split(" ")[0].split("-");
            let timeParts = stringDate.split(" ")[1].split(":");
            let jsDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], timeParts[0], timeParts[1], timeParts[2]);
            return jsDate;
        }
        return new Date();
    }

    function getTimeToDate(days,time,startTime){


        let timeToDate = addDaysToPacificTime(days,startTime);
        // console.log(timeToDate);
        // console.log(timeToDate);
        const [hoursString,minutesString] = time.split(":");
        let hours = parseInt(hoursString);
        let minutes = parseInt(minutesString);
        const isPM = time.toLowerCase().indexOf("pm") > -1;

        if (isPM && hours !== 12) {
            hours += 12;
        } else if (!isPM && hours === 12) {
            hours = 0;
        }

        timeToDate.setHours(hours);
        timeToDate.setMinutes(minutes);
        timeToDate.setSeconds(0);

        return timeToDate;
    }
    function getTimezoneOffset(){
        let now = new Date();
        let utcOffset = now.getTimezoneOffset();
        let tzOffset = moment.tz(timezone).utcOffset();
        return Math.abs(((tzOffset - utcOffset)%60)).toString().padStart(2, "0");

    }
    function timeArray(){
        let diff =getTimezoneOffset();
        const timesArray = [];
// Loop through the hours of the day
        for (let hour = 0; hour < 24; hour++) {
            // Convert the hour to 12-hour format with AM/PM suffix
            const hourString = (hour % 12 === 0 ? 12 : hour % 12) + ':'+diff+' '+ (hour < 12 ? 'am' : 'pm');

            // Add the time string to the array
            timesArray.push(hourString);
        }
        return timesArray;
    }
    function checkDays(days,time,startTime){
        let timeToDate = getTimeToDate(days,time,startTime);

        return {show :timeToDate.getTime() >= getDateNow(current_date).getTime() ,available: availableDates.includes(timeToDate.getTime()),reserved:reservedDates.includes(timeToDate.getTime()),selected:currentUser.type=="Agency" && selectedDate == timeToDate.getTime()? true:false};


    }
    function getFormattedDateTime(date){
        var month = (date.getMonth() + 1).toString().padStart(2, "0"); //months from 1-12
        var day = date.getDate().toString().padStart(2, "0");
        var year = date.getFullYear().toString().padStart(2, "0");

        const  formattedDate = year + "-" + month + "-" + day;

        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");
        const formattedTime = `${hours}:${minutes}:${seconds}`; // "00:00:00"
        return {
            date:formattedDate,
            time : formattedTime
        }
    }

    useEffect(function (){

        // setStartTime()

        setStartTime(getDateNow(current_date));

    },[current_date])
    useEffect(function (){
        setIsPrevious(startTime.toDateString()  !== getDateNow(current_date).toDateString() );
    },[startTime])
    useEffect(function (){

    },[availableDates])
    useEffect(function (){
        if(dates != undefined){
            let dateArr = dates.data.data
            let dateIdsObj ={};
            dateArr = dateArr.map(function (value){
                let dateParts = value.date.split("-");
                let timeParts = value.time.split(":");
                let jsDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], timeParts[0], timeParts[1], timeParts[2]);
                dateIdsObj ={
                    ...dateIdsObj,
                    [new Date(jsDate).getTime()] :  value.id
                }

                return {'date' : new Date(jsDate).getTime(),'reserved':!! value.agency_id}
            })
            setAvailableDatesIds(dateIdsObj)
            setAvailableDates(
                dateArr.map(function (value){
                    return value.date
                })
            )
            setReservedDates(
                dateArr.map(function (value){
                    return value.reserved ? value.date:null;
                }).filter(function (el) {
                    return el != null;
                })
            )




        }
    },[dates])
    let days = 5;

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setTimezone(event.target.value);
    };
    return (<>
        {showForm && <div className="sigma_subheader_inner style-5">
            <div className="row">
                <div className="col-md-12">
                    {currentUser.type != "Agency" &&
                        <>
                            <h2>Set Avaliable Time</h2>
                            <p className="mb-0 mt-0">Customize your availability by selecting at least 15 time slots from our convenient timetable. Make scheduling easy by setting your availability in advance and letting us know the best times to coordinate with you.</p>
                            {mutation.isSuccess ? <div className="alert alert-success" role="alert">
                                Updated Successfully
                            </div> : null}
                            <p className="paragraph-text mb-0 mt-0">
                                {count > 0 && <>{`Please add at least ${count} times`} </> }
                            </p>
                        </>
                    }

                    <div className={"horizontal-line-dash"}></div>
                    <div className="d-flex flex-row mobile-cal">

                    <div className="d-flex">
                        <div className={isPrevious ? 'clickable-text p-2 flex-column':'non-clickable-text p-2 flex-column'}
                             onClick={()=>{
                                 if(isPrevious){
                                     setStartTime(subtractDaysToPacificTime(days,startTime))
                                 }
                             }}

                        ><i className="fa fa-chevron-left pe-1"></i> Before <span className={'light-text'}>{
                            startTime.toLocaleDateString("en-US",
                                {

                                    month: "short",
                                    day: "numeric",
                                },)}
                                    </span></div>
                        <div className={'left-horizontal-border flex-column'}></div>
                        <div className={'clickable-text p-2 flex-column'}
                             onClick={()=>{
                                 setStartTime(addDaysToPacificTime(days,startTime))

                             }}
                        >After <span className={'light-text'}>
                                        {
                                            addDaysToPacificTime(days,startTime).toLocaleDateString("en-US",
                                                {

                                                    month: "short",
                                                    day: "numeric",
                                                })
                                        }

                                    </span><i className="fa fa-chevron-right ps-1"></i></div>
                    </div>
                        <div className={'light-text p-2 pacific-box'}>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>

                                <Select
                                    value={timezone}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value="">
                                        <i className='fa fa-globe pe-1'></i> {timezone} ({pacificTime(startTime)})
                                    </MenuItem>
                                    {moment.tz.names().map(function(value){
                                        return <MenuItem value={value}>
                                            <i className='fa fa-globe pe-1'></i> {value == 'Israel'? 'Jerusalem' : value} ({moment.tz(value).format('h:mm A')} )
                                        </MenuItem>
                                    })}
                                </Select>
                            </FormControl>

                        </div>
                    </div>
                    <div className={"horizontal-line-dash"}></div>

                    <table className="table table-borderless mb-0">

                        <tbody>
                        <tr className={'row'}>


                            {

                                numberToArray(days).map(function (value){
                                    return <td scope="row" className={'col-xs-15 text-center'}><div className={'light-text'}>
                                        {
                                            addDaysToPacificTime(value,startTime).toLocaleDateString("en-US",
                                                {

                                                    weekday: "short",
                                                })
                                        }
                                    </div><div >
                                        {
                                            addDaysToPacificTime(value,startTime).toLocaleDateString("en-US",
                                                {

                                                    month: "numeric",
                                                    day: "numeric",
                                                })
                                        }</div></td>



                                })
                            }



                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className={"horizontal-line-dash"}></div>

                <div className="col-md-12 scrollable-div">

                    {mutation.isLoading ? (
                        'Updating Available time...'
                    ) : <table className="table table-borderless table-scrollable table-condensed">


                        <tbody>
                        {!dateFetch && !isFetching && timeArray().map(function (time){

                            return <tr className={'row'}>
                                {numberToArray(days).map(function (value) {
                                    let check = checkDays(value,time,startTime);

                                    let className ;
                                    if(currentUser.type == "Agency"){
                                        if(check.selected){
                                            className = "availability-time availability-time-available"
                                        }else if (!check.available){
                                            className = "availability-time availability-time-reserved"
                                        }else if (check.reserved){
                                            className = 'availability-time availability-time-reserved';
                                        }else{
                                            className = 'availability-time';
                                        }
                                    }else{
                                        if(check.available && check.reserved){
                                            className = "availability-time availability-time-reserved"
                                        }else if(check.available && !check.reserved){
                                            className =  "availability-time availability-time-available"
                                        }else{
                                            className = "availability-time"
                                        }

                                    }
                                    return <>
                                        {
                                            check.show ? <td scope="row" className={'col-xs-15'}><span
                                                className={className}
                                                onClick={()=>{
                                                    if(!check.reserved){

                                                        let currentDate = getTimeToDate(value,time,startTime).getTime();

                                                        let isInclude = availableDates.includes(currentDate)
                                                        if(isInclude && currentUser.type != "Agency"){
                                                            setAvailableDates(
                                                                availableDates.filter(function (date){
                                                                    return currentDate !== date

                                                                })
                                                            )
                                                        }else if(isInclude && currentUser.type == "Agency"){
                                                            setSelectedDate(currentDate)

                                                        }else if(currentUser.type != "Agency"){
                                                            setAvailableDates(
                                                                [...availableDates,currentDate]
                                                            )
                                                        }
                                                    }

                                                }}

                                            >{time}</span></td>:<td scope="row" className={'col-xs-15'}></td>
                                        }
                                    </>
                                })}

                            </tr>
                        })}


                        </tbody>


                    </table>}

                </div>
            </div>
        </div>}

        {showSubmit &&  <div className="col-md-12">
                    <motion.button
                        onClick={() => {
                            let formData
                        if(currentUser.type  == "Agency" ){

                             formData = {
                                 scheduled_time_id:availableDatesIds[selectedDate]
                            }

                        }else{
                             formData = {
                                availability : availableDates.map(function(val){

                                    return getFormattedDateTime(new Date(val));
                                }),

                                start_date:getFormattedDateTime(startTime).date,

                            }
                        }
                            mutation.mutate(formData)


                        }
                        }
                        className={"btn_submit_dark"}
                        type={"submit"}
                        whileTap={{ scale: 0.7 }}
                        onHoverStart={e => {}}
                        onHoverEnd={e => {}}
                        disabled={mutation.isLoading}

                    >  { (mutation.isLoading ? "Please wait..." : currentUser.type == "Agency" ? "Confirm" : "CONFIRM AVAILABILITY")}
                    </motion.button>


                </div>}
            {!!children && children(selectedDate,timezone)}

        </>

    )
}





function pacificTime(startTime){
    const formattedTime = startTime.toLocaleTimeString("en-US", {  hour: "numeric", minute: "numeric", hour12: true });
    return formattedTime;
}
function addDaysToPacificTime(days,date){
    let result = new Date(date.getTime());
    result.setDate(result.getDate() + days );
    return result;
}
function subtractDaysToPacificTime(days,date){
    let result = new Date(date.getTime());
    result.setDate(result.getDate() - days );
    return result;
}

function numberToArray(days){
    let numArr = [];
    for(let i=0;i <days;i++){
        numArr.push(i)
    }
    return numArr;
}
