
import React from 'react';
import { getFooter } from "../../adapters/commonAdapter";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getTermsAndPrivacy } from '../../adapters/ForumsAdapter';

export function Footer() {
    const { data: footer_setting } = useQuery('footer_setting', getFooter,
        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });
        const { data: terms_privacy } = useQuery('terms_privacy', getTermsAndPrivacy,
        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });
        console.log(terms_privacy?.data?.data)
    return (
        <>
            <footer className="sigma_footer style-5 pb-0">
                <div className="sigma_footer-middle">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="sigma_footer-widget">
                                    <div className="sigma_footer-logo mb-4">
                                        <img src="img/logo.png" alt="logo" />
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-9">
                                            <p className="mb-0">
                                                <div dangerouslySetInnerHTML={{ __html: footer_setting?.data.data.Footer.Footer_Description }} />
                                            </p>
                                        </div>
                                    </div>
                                    <ul className="sigma_social-icons mt-4 justify-content-start">
                                        {footer_setting?.data.data.Footer.Facebook &&
                                            <li>
                                                <a href={footer_setting?.data.data.Footer.Facebook} target="_blank"><i className="fab fa-facebook"></i></a>
                                            </li>
                                        }
                                        {footer_setting?.data.data.Footer.Twitter &&
                                            <li>
                                                <a href={footer_setting?.data.data.Footer.Twitter} target="_blank"><i className="fab fa-twitter"></i></a>
                                            </li>
                                        }
                                        {footer_setting?.data.data.Footer.Instagram &&
                                            <li>
                                                <a href={footer_setting?.data.data.Footer.Instagram} target="_blank"><i className="fab fa-instagram"></i></a>
                                            </li>
                                        }
                                        {footer_setting?.data.data.Footer.LinkedIn &&
                                            <li>
                                                <a href={footer_setting?.data.data.Footer.LinkedIn} target="_blank"><i className="fab fa-linkedin"></i></a>
                                            </li>
                                        }
                                        {footer_setting?.data.data.Footer.Whatsapp &&
                                            <li>
                                                <a href={footer_setting?.data.data.Footer.Whatsapp} target="_blank"><i className="fab fa-whatsapp"></i></a>
                                            </li>
                                        }
                                        {footer_setting?.data.data.Footer.Youtube &&
                                            <li>
                                                <a href={footer_setting?.data.data.Footer.Youtube} target="_blank"><i className="fab fa-youtube"></i></a>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="sigma_footer-widget">
                                            <h5 className="widget-title">Main</h5>
                                            <ul className="sigma_footer-links">
                                                <li>
                                                    <Link to={'/'}>Home</Link>
                                                </li>
                                                <li>
                                                    <Link to="/specialities">Specialitles</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/testimonials'}>Testimonials</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/representative-signup'}>Become a Representative</Link>

                                                </li>
                                                <li>
                                                    <Link to="/referral-signup" >Refer a friend</Link>
                                                </li>
                                                <li>
                                                    <Link to="/applicant-signup">Apply here</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="sigma_footer-widget">
                                            <h5 className="widget-title">Forum</h5>
                                            <ul className="sigma_footer-links">
                                                <li>
                                                    <Link to="/e-learning">E-Learning</Link>
                                                </li>
                                                <li>
                                                    <Link to="/sponsorship-immigration">Sponsorship & Immigration</Link>
                                                </li>
                                                <li>
                                                    <Link to="/ask-a-lawyer">Ask a lawyer</Link>
                                                </li>
                                                <li>
                                                    <Link to="/live-work-In-usa">Live & Work in USA</Link>
                                                </li>
                                                <li>
                                                    <Link to="/states">States & Licensure</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="sigma_footer-widget">
                                            <h5 className="widget-title">Company</h5>
                                            <ul className="sigma_footer-links">
                                                <li>
                                                    <Link to="/about-us">About Us</Link>
                                                </li>
                                                <li>
                                                    <Link to="/contact">Contact Us</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="sigma_footer-widget">
                                            <h5 className="widget-title">Account</h5>
                                            <ul className="sigma_footer-links">
                                                <li>
                                                    <Link to="/signup-types">
                                                        Sign up
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/login">
                                                        Log in
                                                    </Link>
                                                </li>

                                                {/* <li>
                                                    <a href="subscribe.html">Subscribe</a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="sigma_footer-widget">
                                            <h5 className="widget-title">Legal</h5>
                                            <ul className="sigma_footer-links">
                                                { terms_privacy?.data?.data.map((legal)=>{
                                                      return <li>
                                                        <Link to={"/terms#"+legal.id} style={{ maxWidth: '200px', wordWrap: 'break-word' }}>
                                                            {legal.title_en.substring(0, 30)} </Link>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="sigma_footer-bottom align-items-center justify-content-between">
                        <div className="sigma_footer-copyright mt-0 mb-3 mb-sm-0">
                            <p className="mb-0 text-center">
                                © 2023 Medic Staff, Inc. All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>

            </footer>



        </>
    );
}

