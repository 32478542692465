import React from 'react';
import {BannerContainer} from "../Common/BannerContainer";
import {ClipLoader} from "react-spinners";
import {handleFormikSubmit} from "../../Helpers/formHelper";
import {Formik,Form} from "formik";
import {useQuery} from "react-query";
import {getCountries} from "../../adapters/commonAdapter";
import Lottie from "lottie-react";
import * as LeftData from "../../assets/animation/AbstractDotsBgLeft.json";
import * as rightData from "../../assets/animation/AbstractDotsBgRight.json";

import {
    AcceptTermsCondition,
    AllowedToSponsor,
    Countries,
    CountryCode,
    File,
    Input
} from "../../Helpers/formComponents";
import {agencySignUpAdapter} from "../../adapters/agencySignUpAdapter";
import {agencySchema} from "../../schemas/agencySchema";
import {motion} from "framer-motion";
import {AgencyForm} from "./AgencyForm";

export default function AgencySignUp() {

    const { isFetching, data:countries } = useQuery('country', getCountries ,
        {
            cacheTime : 1000 * 60 * 60 * 24,
            staleTime : 1000 * 60 * 60 * 12,
        });

    return (
        <>
            <BannerContainer style={{
                background: "#f7f9fc"
            }}>
                <div className="animation-left">
                    <Lottie animationData={LeftData} />
                </div>
                <div className="animation-right">
                    <Lottie animationData={rightData} />
                </div>
                <div className="row">
                    <div className="col-md-6 offset-3">
                        <div className="sigma_subheader_inner style-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2>Agency Profile Form</h2>
                                        <p className="paragraph-text">
                                            Donec in est eu dui venenatis dapibus. Aenean non turpis suscipit, consequat
                                            dolor a,venenatis.
                                            ean non turpis suscipit, consequat dolor a,venenatis.
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <AgencyForm
                                            initialValues={{
                                                first_name: '',
                                                last_name: '',
                                                // company_id_no: '',
                                                email: '',
                                                country_code: '',
                                                mobile: '',
                                                company_name: '',
                                                company_country_id: '',
                                                company_address: '',
                                                contact_number: '',
                                                image: null,
                                            }}
                                            validationSchema={agencySchema}
                                            onSubmit={handleFormikSubmit(agencySignUpAdapter)}
                                            isFetching={isFetching}
                                            countries={countries}
                                        />


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BannerContainer>
        </>
    );
}



