import * as yup from 'yup';

export let referralSchema = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email : yup.string().email().required(),
    password : yup.string().required(),
    country_id : yup.string().required("Country is required").nullable(),
    country_code : yup.string().required("Country code is required"),
    mobile : yup.number().required(),
});
