import React, {useState} from "react";
import {ClipLoader} from "react-spinners";
import LoadingGIF from './../loading.gif';
import {useAuth} from "../../app/auth";
import {Navigate} from "react-router-dom";

export default function Splash() {


    const [isImageLoaded, setIsImageLoaded] = useState(false);

    return <div className="preloader splash-screen"  id="splash-screen" >
                <img alt='img' src={LoadingGIF} onLoad={() => setIsImageLoaded(true)}
                    />
            </div>
}