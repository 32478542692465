import React, { useEffect, useState } from 'react';
import { useInfiniteQuery, useQuery } from "react-query";
import { getTestimonials } from "../../adapters/TestimonialsAdapter";
import { getNextPage } from "../../Helpers/ReactQueryHelper";
import Pagination from "../Common/Pagination";

import { getOurTeam, getOurTeamSettings } from '../../adapters/ForumsAdapter';
import { TitleOnlyBanner } from '../Common/TitleOnlyBanner';

export default function OurTeam() {
    const { data: setting } = useQuery('our_team-setting', getOurTeamSettings,
        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });

    const {
        status,
        data,
        error,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useInfiniteQuery(
        ['e-learnings'],
        async ({ pageParam = 1 }) => {
            const res = await getOurTeam(pageParam)
            return res.data
        },
        {
            getNextPageParam: getNextPage,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,

        },
    )
    console.log(data)
    return (
        <div className='container'>

            <TitleOnlyBanner
                title={'Our Teams'}
                description={setting?.data.data.OurTeamsDetails
                    ?.Description_OurTeamsDetails}
            />
            <div>
                <Pagination
                    data={data}
                    isFetching={isFetching}
                    fetchNextPage={fetchNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                    hasNextPage={hasNextPage}
                >{(our_team) => {
                    return <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="sigma_team style-15">
                            <div class="sigma_team-thumb">
                                <img src={our_team.image} alt="team"></img>
                                <div className="after"></div>
                            </div>
                            <div class="sigma_team-body">
                                <h5>
                                    {our_team.employee_name}
                                </h5>
                                <div class="sigma_team-categories">
                                    {our_team.job_title}

                                </div>
                                <ul class="sigma_social-icons">
                                    {our_team.facebook &&
                                        <li>
                                            <a href={our_team.facebook} rel="noreferrer" target="_blank"><i class="fab fa-facebook-f"></i></a>
                                        </li>
                                    }

                                    {our_team.twitter &&
                                        <li>
                                            <a href={our_team.twitter} rel="noreferrer" target="_blank"><i class="fab fa-twitter"></i></a>
                                        </li>
                                    }
                                    {our_team.instagram &&
                                        <li>
                                            <a href={our_team.instagram} rel="noreferrer" target="_blank"><i class="fab fa-instagram"></i></a>
                                        </li>
                                    }
                                    {our_team.linkedin &&
                                        <li>
                                            <a href={our_team.linkedin} rel="noreferrer" target="_blank"><i class="fab fa-linkedin"></i></a>
                                        </li>
                                    }
                                    {our_team.whatsapp &&

                                        <li>
                                            <a href={our_team.whatsapp} rel="noreferrer" target="_blank"><i class="fab fa-whatsapp"></i></a>
                                        </li>
                                    }
                                    {our_team.youtube &&
                                        <li>
                                            <a href={our_team.youtube} rel="noreferrer" target="_blank"><i class="fab fa-youtube"></i></a>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                }}
                </Pagination>
            </div>
        </div>
    );
}




// <div class="sigma_banner_inner pt-0" style="background: #fff !important;">
//     <div class="sigma_subheader_inner style-5">
//         <div class="container">
//             <div class="row">
//                 <h3 class="sigma_testimonial_title">Our Teams</h3>
//                 <div class="col-md-6 offset-3">
//                     <p class="main-color-dark text-center">
//                         Donec in est eu dui venenatis dapibus.
//                         Aenean non turpis suscipit, consequat
//                         dolor a,venenatis dapiban non turpis suscipit.
//                     </p>
//                 </div>
//             </div>
//             <div class="row mt-5">
//                 <div class="col-lg-4 col-md-4 col-sm-6">
//                     <div class="sigma_team style-15">
//                         <div class="sigma_team-thumb">
//                             <img src="assets/img/teams1.png" alt="team">
//                         </div>
//                         <div class="sigma_team-body">
//                             <h5>
//                                 Mega Nelson
//                             </h5>
//                             <div class="sigma_team-categories">
//                                 Physiotherapist
//                             </div>
//                             <ul class="sigma_social-icons">
//                                 <li>
//                                     <a href="#"><i class="fab fa-facebook-f"></i></a>
//                                 </li>
//                                 <li>
//                                     <a href="#"><i class="fab fa-twitter"></i></a>
//                                 </li>
//                                 <li>
//                                     <a href="#"><i class="fab fa-instagram"></i></a>
//                                 </li>
//                                 <li>
//                                     <a href="#"><i class="fab fa-linkedin"></i></a>
//                                 </li>
//                                 <li>
//                                     <a href="#"><i class="fab fa-whatsapp"></i></a>
//                                 </li>
//                                 <li>
//                                     <a href="#"><i class="fab fa-youtube"></i></a>
//                                 </li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//                 <div class="col-lg-4 col-md-4 col-sm-6">
//                     <div class="sigma_team style-15">
//                         <div class="sigma_team-thumb">
//                             <img src="assets/img/teams2.png" alt="team">
//                         </div>
//                         <div class="sigma_team-body">
//                             <h5>
//                                 Mega Nelson
//                             </h5>
//                             <div class="sigma_team-categories">
//                                 Physiotherapist
//                             </div>
//                             <ul class="sigma_social-icons">
//                                 <li>
//                                     <a href="#"><i class="fab fa-facebook-f"></i></a>
//                                 </li>
//                                 <li>
//                                     <a href="#"><i class="fab fa-twitter"></i></a>
//                                 </li>
//                                 <li>
//                                     <a href="#"><i class="fab fa-instagram"></i></a>
//                                 </li>
//                                 <li>
//                                     <a href="#"><i class="fab fa-linkedin"></i></a>
//                                 </li>
//                                 <li>
//                                     <a href="#"><i class="fab fa-whatsapp"></i></a>
//                                 </li>
//                                 <li>
//                                     <a href="#"><i class="fab fa-youtube"></i></a>
//                                 </li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//                 <div class="col-lg-4 col-md-4 col-sm-6">
//                     <div class="sigma_team style-15">
//                         <div class="sigma_team-thumb">
//                             <img src="assets/img/teams3.png" alt="team">
//                         </div>
//                         <div class="sigma_team-body">
//                             <h5>
//                                 Mega Nelson
//                             </h5>
//                             <div class="sigma_team-categories">
//                                 Physiotherapist
//                             </div>
//                             <ul class="sigma_social-icons">
//                                 <li>
//                                     <a href="#"><i class="fab fa-facebook-f"></i></a>
//                                 </li>
//                                 <li>
//                                     <a href="#"><i class="fab fa-twitter"></i></a>
//                                 </li>
//                                 <li>
//                                     <a href="#"><i class="fab fa-instagram"></i></a>
//                                 </li>
//                                 <li>
//                                     <a href="#"><i class="fab fa-linkedin"></i></a>
//                                 </li>
//                                 <li>
//                                     <a href="#"><i class="fab fa-whatsapp"></i></a>
//                                 </li>
//                                 <li>
//                                     <a href="#"><i class="fab fa-youtube"></i></a>
//                                 </li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>