import React from "react";
import Lottie from "lottie-react";
import * as animationData from '../../assets/animation/DesktopFooterAbstract.json';
import * as animationMobileData from '../../assets/animation/MobileFooterAbstract.json';

export default function FooterImage() {

    return  (
        <div>
            <Lottie animationData={animationData} className="desktop-img" />
            <Lottie animationData={animationMobileData} className="mobile-footer-img" />
            {/*<img className="footer-abstract" src="/img/footer-abstract.png"/>*/}
        </div>
    )
}
