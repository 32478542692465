import React, { useEffect, useState } from 'react';
import { ContactForm } from "../Common/ContactForm";
import { BannerContainer } from "../Common/BannerContainer";
import FooterImage from "../Common/FooterImage"
import Lottie from "lottie-react";
import * as LeftData from "../../assets/animation/AbstractDotsBgLeft.json";
import * as rightData from "../../assets/animation/AbstractDotsBgRight.json";
import { JitsiMeeting } from '@jitsi/react-sdk'
import { useQuery } from 'react-query';
import { meetingData } from '../../adapters/agencyAdapter';
import { useNavigate, Redirect, Route } from 'react-router-dom';
import { useAuth } from "../../app/auth";
import moment from "moment-timezone";

export default function Meeting() {
    const { refetch, isFetching, data } = useQuery(['current_application'], meetingData,
        {
            cacheTime: 0,
            staleTime: 0,
            refetchOnWindowFocus: false, // default: true
            refetchOnMount: false,
        });
    /////////////////////////////////////////////////////////////////////////////////////////////
    let { currentUser } = useAuth()
    const navigate = useNavigate();
    const [isMeetingAvailable, setIsMeetingAvailable] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');
    const [isMeetingOpen, setIsMeetingOpen] = useState(true);
    /////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (data.data.data.schedule_time) {
            let stillUtc = moment.utc(data.data?.data?.schedule_time?.date + " " + data.data.data?.schedule_time?.time).toDate();
            let timeBefore = moment(stillUtc).local().subtract(15, 'minutes');
            let oneHourAfter = moment(stillUtc).local().add(1, 'hours');


            if (moment().isBetween(timeBefore, oneHourAfter)) {
                setIsMeetingAvailable(true);
                const millisecondsToWait = oneHourAfter.diff(moment());
                const timeoutId = setTimeout(() => {
                    navigate('/dashboard'); // replace '/other-page' with the actual path to the other page
                }, millisecondsToWait);
                return () => clearTimeout(timeoutId);
            } else {
                const fetchedHtmlContent = '<h1> There is no available meeting right now  </h1>';
                // Set the HTML content to the state
                setHtmlContent(fetchedHtmlContent);
            }
        }
    }, [data]);

    return (

        <>
            <BannerContainer style={{
                background: "#fff"
            }}>
                <div className="animation-left">
                    <Lottie animationData={LeftData} />
                </div>
                <div className="animation-right">
                    <Lottie animationData={rightData} />
                </div>

                {isMeetingAvailable && currentUser?.full_name && data && data?.data?.data?.meeting_data ?
                    < JitsiMeeting
                        // domain={YOUR_DOMAIN}
                        roomName={data.data.data.meeting_data.roomName}
                        config={{
                            enableClosePage: false,
                        }}


                        configOverwrite={{
                            startWithAudioMuted: true,
                            disableModeratorIndicator: true,
                            startScreenSharing: true,
                            enableEmailInStats: false
                        }}

                        interfaceConfigOverwrite={{
                            DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                            SHOW_CHROME_EXTENSION_BANNER: false,
                            TOOLBAR_BUTTONS: [
                                'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen', 'fodeviceselection',
                                'profile', 'chat', 'recording', 'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
                                'videoquality', 'filmstrip', 'feedback', 'stats', 'shortcuts', 'tileview', 'videobackgroundblur',
                                'download', 'help', 'mute-everyone', 'security'
                            ]
                            // , 'hangup', 'invite' i did remove these buttons

                        }}
                        userInfo={{
                            displayName: currentUser?.full_name
                        }}
                        onApiReady={(externalApi) => {
                            // here you can attach custom event listeners to the Jitsi Meet External API
                            // you can also store it locally to execute commands

                        }}
                        getIFrameRef={(iframeRef) => { iframeRef.style.height = '600px'; }}

                    /> :
                    <div style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: htmlContent }} />

                }
            </BannerContainer>
            <FooterImage />
            <ContactForm />

        </>
    )
}
