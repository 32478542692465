
import React, { useEffect } from 'react';
import {Link, useLocation} from "react-router-dom";
import Flight from "../../Components/Booking/Flight";
import { useAuth } from "../../app/auth";

// import $ from 'jquery';

export function Header() {

    const location = useLocation();
    const navArray = ['/dashboard', '/Referral', '/get-paid', '/account-settings','/file-sharing','/inbox']
    const { currentUser, logout, type } = useAuth();

    useEffect(() => {
        $("body").removeClass('aside-open');
        $("body").removeClass('aside-right-open');
      }, [location.pathname]);
      
    let $ = require('jquery')
    let asideTrigger = () => {
        $("body").toggleClass('aside-open');
    }
    let asideTriggerRight = () => {
        $("body").toggleClass('aside-right-open');
    }
    let asideMenuChildren = (e) => {
        var submenu = $(e.target).next(".sub-menu");
        e.preventDefault();
        submenu.slideToggle(200);
    }

    return (
        <>

            <div className="sigma_aside-overlay aside-trigger-right" onClick={asideTriggerRight}>
            </div>
            <aside className="sigma_aside">
                <div className="sigma_close aside-trigger" onClick={asideTrigger}>
                    <span></span>
                    <span></span>
                </div>
                <div className="sigma_mobile" style={{ backgroundImage: 'url(img/mobile-img.png)' }}>
                    {currentUser ?
                        <>
                            <div className="menu-user-mobile">
                                <div className="user-img-menu-mobile">
                                    <div className="bg-user-mobile">
                                        <img src="/img/StethoscopeHeadset.svg" />
                                    </div>
                                    <img src={currentUser.image} />
                                </div>
                                <div className="name-user-mobile">
                                    <h4 className="welcome-menu-mobile">Welcome,</h4>
                                    <h3 className="user-menu-mobile">{type == "Agency" ? currentUser.company_name : currentUser.full_name}</h3>
                                    {/*<h4 className="welcome-menu-mobile">{currentUser.id}</h4>*/}
                                </div>
                                <div className="bg-box-mobile">
                                    <img src="/img/stetescope.svg" />
                                </div>
                            </div>
                        </>

                        :
                        <ul>
                            <li>
                                <Link to="/signup-types">
                                    <img alt='img' src="/img/signup-icon.svg" />
                                    Sign up
                                </Link>
                            </li>
                            <li>
                                <Link to="/login">
                                    <img alt='img' src="/img/login-icon.svg" />
                                    Log in
                                </Link>
                            </li>
                        </ul>
                    }
                </div>
                <ul className="navbar-nav">
                    <li className="menu-item">
                        <Link to={'/'}>Home</Link>
                        {/*<a href="index">home</Link>*/}
                    </li>
                    <li className="menu-item">
                        <Link to="/specialities">Specialitles</Link>
                    </li>
                    <li className="menu-item">
                        <Link to={'/testimonials'}>Testimonials</Link>
                    </li>
                    <li className="menu-item  menu-item-has-children">
                        <Link to="/#" onClick={asideMenuChildren}>Forum</Link>
                        <ul className="sub-menu" >
                            <li>
                                <Link to="/e-learning">E-Learning</Link>
                            </li>
                            <li>
                                <Link to="/sponsorship-immigration">Sponsorship & Immigration</Link>
                            </li>
                            <li>
                                <Link to="/ask-a-lawyer">Ask a lawyer</Link>
                            </li>
                            <li>
                                <Link to="/live-work-In-usa">Live & Work in USA</Link>
                            </li>
                            <li>
                                <Link to="/states">States & Licensure</Link>

                            </li>
                        </ul>
                    </li>
                    <li className="menu-item menu-item-has-children">
                        <Link to="/bookings" onClick={asideMenuChildren}>
                            Bookings
                        </Link>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/hotels">
                                    Hotels
                                </Link>
                            </li>
                            <li>
                                <Link to="/flight">
                                    Flight
                                </Link>
                            </li>
                            <li>
                                <Link to="/transport">
                                    Transport
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="menu-item">
                        <Link to="/about-us">About Us</Link>
                    </li>
                    <li className="menu-item">
                        <Link to="/contact">Contact us</Link>
                    </li>
                    {currentUser ?
                        <>
                            <li className="profile-menu">
                                <span>PROFILE</span>
                            </li>
                            <li>
                                <Link to="/dashboard"> Dashboard</Link>
                            </li>

                            {
                                type == "Nurse with NCLEX-RN" && <>



                                    <li>
                                        <Link to="/reminders"> Reminders</Link>
                                    </li>
                                    <li>

                                        <Link to="/availability"> Availability </Link>
                                    </li>


                                </>
                            }

                            {(type == "Nurse with NCLEX-RN" || type == "Agency") && <>
                                <li>
                                    <Link to="/inbox">Inbox </Link>


                                </li>
                                <li>
                                    <Link to="/file-sharing">File Sharing </Link>
                                </li>
                            </>
                            }
                            <li>
                                <Link to="/Referral">Referral </Link>
                            </li>
                            {
                                type !== "Nurse with NCLEX-RN" && type !== "Agency" &&
                                <>
                                    <li>
                                        <Link to="/get-paid">Get Paid </Link>
                                    </li>
                                </>
                            }


                            <li>

                                <Link to="/account-settings">Account Settings </Link>
                            </li>
                        </>
                        :""
                    }
                </ul>
                {/* <ul className="apply-mobile">
                    <li>
                        <Link to="/referral-signup" className="sigma_btn_white btn-sm">
                            Refer a friend
                        </Link>
                    </li>
                    <li>
                        <Link to="/applicant-signup" className="sigma_btn btn-sm">
                            Apply here
                        </Link>
                    </li>
                </ul> */}
                {currentUser && !navArray.includes(location.pathname)?(
                    <>
                        <ul className="navbar-nav">
                            {
                                type == "Nurse with NCLEX-RN" &&
                                <>
                                    <li className="menu-item">
                                        <Link to="/dashboard">
                                            Dashboard
                                        </Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="/reminders">
                                            Reminders
                                        </Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="/availability">
                                            Availability
                                        </Link>
                                    </li>
                                </>
                            }
                            {
                                type == "Agency" &&
                                <>
                                    <li className="menu-item">
                                        <Link to="/dashboard">
                                            Dashboard
                                        </Link>
                                    </li>

                                </>
                            }
                            {(type == "Nurse with NCLEX-RN" || type == "Agency") && <>
                                <li className="menu-item">
                                    <Link to="/inbox">
                                        Inbox
                                    </Link>
                                </li>
                                <li className="menu-item">
                                    <Link to="/file-sharing">
                                        File Sharing
                                    </Link>
                                </li>
                            </>
                            }
                            {type == 'Representative' &&
                                <li className="menu-item">
                                    <Link to="/dashboard">
                                        Dashboard
                                    </Link>
                                </li>
                            }
                              {( type !== "Agency") && <>
                              
                            <li className="menu-item">
                                <Link to="/Referral">
                                    Referral
                                </Link>


                            </li>
                            {
                                type !== "Nurse with NCLEX-RN" && type !== "Agency" &&
                                <>
                                    <li className="menu-item">
                                        <Link to="/get-paid">
                                            Get Paid
                                        </Link>
                                    </li>
                                </>
                            }
                            </>
                             }
                            <li className="menu-item">
                                <Link to="/account-settings">
                                    Account Settings
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="#"
                                    onClick={logout}>
                                    Log Out
                                </Link>
                            </li>
                        </ul>
                        <ul class="apply-mobile">
                            <li>
                                <Link to="/Referral" className="sigma_btn_white btn-sm">
                                    Refer a friend
                                </Link>
                            </li>
                        </ul>
                    </>
                ):currentUser ? (
                    <ul class="apply-mobile">
                    <li>
                        <Link to="/Referral" className="sigma_btn_white btn-sm">
                            Refer a friend
                        </Link>
                    </li>
                </ul>
                ):
                <>
                        <ul class="apply-mobile">

                            <li>
                                <Link to="/referral-signup" className="sigma_btn_white btn-sm">
                                    Refer a friend
                                </Link>
                            </li>
                            <li>
                                <Link to="/applicant-signup" className="sigma_btn btn-sm">
                                    Apply here
                                </Link>
                            </li>
                        </ul>
                    </>
                }
            </aside>

            <div className="sigma_aside-overlay aside-trigger" onClick={asideTrigger}>
            </div>
            <header className="sigma_header header-absolute style-5 other can-sticky">
                <div className="sigma_header-middle">
                    <div className="container">
                        <div className="navbar">
                            <div className="sigma_logo-wrapper">
                                <Link className="sigma_logo" to="/">

                                    <img src="/img/logo.png" alt="logo" />
                                </Link>
                            </div>
                            <ul className="navbar-nav">
                                <li className="menu-item">
                                    <Link to={'/'}>Home</Link>
                                </li>
                                <li className="menu-item">
                                    <Link to="/specialities">Specialitles</Link>
                                </li>
                                <li className="menu-item">
                                    <Link to={'/testimonials'}>Testimonials</Link>
                                </li>
                                <li className="menu-item menu-item-has-children">
                                    <Link to="/#" onClick={asideMenuChildren}>Forums
                                        <span className="menu-icon"><i className="fa fa-chevron-down"></i></span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/e-learning">E-Learning</Link>
                                        </li>
                                        <li>
                                            <Link to="/sponsorship-immigration">Sponsorship & Immigration</Link>
                                        </li>
                                        <li>
                                            <Link to="/ask-a-lawyer">Ask a lawyer</Link>
                                        </li>
                                        <li>
                                            <Link to="/live-work-In-usa">Live & Work in USA</Link>
                                        </li>
                                        <li>
                                            <Link to="/states">States & Licensure</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="menu-item menu-item-has-children">
                                    <Link to="/bookings">
                                        Bookings <span className="menu-icon"><i className="fa fa-chevron-down"></i></span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/hotels">
                                                Hotels
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/flight">
                                                Flight
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/transport">
                                                Transport
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="menu-item">
                                    <Link to="/about-us">About Us</Link>
                                </li>
                                <li className="menu-item">
                                    <Link to="/contact">Contact Us</Link>
                                </li>


                            </ul>


                            {currentUser ?
                                <>
                                    <div className="navbar">

                                        <ul className="navbar-nav">

                                            <li className="menu-item menu-item-has-children">
                                                <div className="menu-user">
                                                    <div className="name-user">
                                                        <h4 className="welcome-menu">Welcome,</h4>
                                                        <h3 className="user-menu">{type == "Agency" ? currentUser.company_name : currentUser.full_name}</h3>
                                                        {/*<h4 className="welcome-menu">{currentUser.id}</h4>*/}
                                                    </div>
                                                    <div>
                                                        <div className="user-img-menu">
                                                            <img src={currentUser.image} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <ul className="sub-menu">

                                                    {
                                                        type == "Nurse with NCLEX-RN" &&
                                                        <>
                                                            <li>
                                                                <Link to="/dashboard">
                                                                    Dashboard
                                                                </Link>
                                                            </li>
                                                            {/* <li>
                                                                <Link to="/reminders">
                                                                    Reminders
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/availability">
                                                                    Availability
                                                                </Link>
                                                            </li> */}
                                                        </>
                                                    }
                                                    {
                                                        type == "Agency" &&
                                                        <>
                                                            <li>
                                                                <Link to="/dashboard">
                                                                    Dashboard
                                                                </Link>
                                                            </li>

                                                        </>
                                                    }
                                                    {( type == "Agency") && <>
                                                        <li>
                                                            <Link to="/inbox">
                                                                Inbox
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/file-sharing">
                                                                File Sharing
                                                            </Link>
                                                        </li>
                                                    </>
                                                    }
                                                    {type == 'Representative' &&
                                                        <li>
                                                            <Link to="/dashboard">
                                                                Dashboard
                                                            </Link>
                                                        </li>
                                                    }
                                                     {( type !== "Agency") && <>
                                                    <li>
                                                        <Link to="/Referral">
                                                            Referral
                                                        </Link>


                                                    </li>
                                                    </> }
                                                      {
                                                        type !== "Nurse with NCLEX-RN" && type !== "Agency" &&
                                                        <>
                                                           <li>
                                                        <Link to="/get-paid">
                                                            Get Paid
                                                        </Link>
                                                    </li>
                                                        </>}
                                                 
                                                    <li>
                                                        <Link to="/account-settings">
                                                            Account Settings
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#"
                                                            onClick={logout}>
                                                            Log Out
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="sigma_header-controls style-2">
                                        <ul className="sigma_header-controls-inner">
                                            <li>
                                                <Link to="/referral-signup" className="sigma_btn_white btn-sm">
                                                    Refer a friend
                                                </Link>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <Link to="/applicant-signup" className="sigma_btn btn-sm">*/}
                                            {/*        Apply here*/}
                                            {/*    </Link>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </div>
                                </>
                                :
                                <div className="sigma_header-controls style-2">
                                    <ul className="sigma_header-controls-inner">

                                        <li>
                                            <Link className="sigma_header-control-signup bg-transparent border-0" to="/signup-types">
                                                Sign up
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="sigma_header-control-signup bg-transparent border-0" to="/login">
                                                Log in
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/referral-signup" className="sigma_btn_white btn-sm">
                                                Refer a friend
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/applicant-signup" className="sigma_btn btn-sm">
                                                Apply here
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            }

                            <div className="aside-toggle aside-trigger" onClick={asideTrigger}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </>
    );
}

