import React from "react";
import {BannerContainer} from "../../Common/BannerContainer";
import FooterImage from "../../Common/FooterImage";
import {ContactForm} from "../../Common/ContactForm";
import {TitleOnlyBanner} from "../../Common/TitleOnlyBanner";
import StateMapSearch from "../Common/StateMapSearch";
import {getLiveWorkInUSASettings, getLiveWorkInUSA} from "../../../adapters/ForumsAdapter";
import { useQuery} from "react-query";
import Lottie from "lottie-react";
import * as LeftData from "../../../assets/animation/AbstractDotsBgLeft.json";
import * as rightData from "../../../assets/animation/AbstractDotsBgRight.json";

export default function LiveUsa() {
    const {  data:setting } = useQuery('live-usa-setting', getLiveWorkInUSASettings ,
        {
            cacheTime : 1000 * 60 * 60 * 24,
            staleTime : 1000 * 60 * 60 * 12,
        });

    const { data } = useQuery('usa-data', getLiveWorkInUSA ,
        {
            cacheTime : 1000 * 60 * 60 * 24,
            staleTime : 1000 * 60 * 60 * 12,
        });
    return (
        <>
            <BannerContainer style={{
                background: "#fff"
            }}>
                <div className="animation-left">
                    <Lottie animationData={LeftData} />
                </div>
                <div className="animation-right">
                    <Lottie animationData={rightData} />
                </div>
                <TitleOnlyBanner
                    title={'Live & Work In USA'}
                    description={setting?.data.data.LiveWorkInUSA?.description}
                />

                <StateMapSearch statesData={data?.data.data}/>
            </BannerContainer>
            <FooterImage/>
            <ContactForm/>
        </>
    )
}
