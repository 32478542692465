import React from "react";
import { useAuth } from "../../app/auth";
import { Link, useLocation } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from "@mui/styles";

export function DashboardList() {

    const location = useLocation();


    let { currentUser, logout, type } = useAuth()

    const [status, setStatus] = React.useState('');
    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    const useStyles = makeStyles(() => ({
        MuiAccordionroot: {
            "&.MuiPaper-root:before": {
                backgroundColor: "transparent !important",
                boxShadow: "none",
                border: "none",
            }
        }
    }));
    const classes = useStyles();

    return (
        <div className="col-md-3">
            {/*Start Mobile Menu */}
            <div className="dashboard-list mobile-footer-img">
                <Accordion

                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.MuiAccordionroot}
                    >
                        <div className="d-flex">
                            <div className="user-img-menu-mobile">
                                <img src={currentUser.image} />
                            </div>
                            <div className="name-user-mobile">
                                <h4 className="welcome-menu-mobile">Welcome,</h4>
                                <h3 className="user-menu-mobile">{type == "Agency" ? currentUser.company_name : currentUser.full_name}</h3>
                                <h4 className="welcome-menu-mobile">{currentUser.id}</h4>
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <hr className="border-white" />
                        <ul className="dashboard-list-ul">
                            <li>
                                <Link to="/dashboard" className={location.pathname.includes('dashboard') ? "active" : ''}><img
                                    src={location.pathname.includes('dashboard') ? "/img/dashboard-dark.svg" : "/img/dashboard.svg"} />Dashboard</Link>
                            </li>

                            {
                                type == "Nurse with NCLEX-RN" && <>



                                    <li>
                                        <Link to="/reminders" className={location.pathname.includes('reminders') ? "active" : ''}><img
                                            src={location.pathname.includes('reminders') ? "/img/calendar.svg" : "/img/calendar_white.svg"} />Reminders</Link>
                                    </li>
                                    <li>

                                        <Link to="/availability" className={location.pathname.includes('availability') ? "active" : ''}><img
                                            src={location.pathname.includes('availability') ? "/img/time_black.svg" : "/img/time.svg"} />Availability </Link>
                                    </li>


                                </>
                            }

                            {(type == "Nurse with NCLEX-RN" || type == "Agency") && <>
                                <li  className={location.pathname.includes('inbox') ? "active" : ''}>
                                    <Link to="/inbox"><img
                                        src={location.pathname.includes('inbox') ? "/img/mail-dark.svg" : "/img/mail.svg"} />Inbox </Link>

                                    <button className="button-online">
                                        Online
                                        <i className="fa fa-circle online"></i>

                                        {/*Offline*/}
                                        {/*<i className="fa fa-circle offline"></i>*/}
                                    </button>
                                </li>
                                <li>
                                    <Link to="/file-sharing" className={location.pathname.includes('file-sharing') ? "active" : ''}><img
                                        src={location.pathname.includes('file-sharing') ? "/img/file-dark.svg" : "/img/file.svg"} />File Sharing </Link>
                                </li>
                            </>
                            }
                            {( type !== "Agency") && <>
                            <li>
                                <Link to="/Referral" className={location.pathname.includes('Referral') ? "active" : ''}><img
                                    src={location.pathname.includes('Referral') ? "/img/user-add-black.svg" : "/img/user-add.svg"} />Referral </Link>
                            </li>
                            <li>
                                <Link to="/get-paid" className={location.pathname.includes('get-paid') ? "active" : ''}><img
                                    src={location.pathname.includes('get-paid') ? "/img/getpaid-black.svg" : "/img/getpaid.svg"} />Get Paid </Link>
                            </li>
                            </>
                            }



                            <li>

                                <Link to="/account-settings" className={location.pathname.includes('account-settings') ? "active" : ''}><img
                                    src={location.pathname.includes('account-settings') ? "/img/settings-dark.svg" : "/img/chat-alt.svg"} />Account Settings </Link>
                            </li>
                        </ul>
                        <hr className="border-white" />
                        <a href="javascript:;" onClick={logout} className="logout-btn"><img src="/img/logout.svg" /> Log out</a>
                    </AccordionDetails>
                </Accordion>
            </div>
            {/*End Mobile Menu */}

            {/*Start Desktop Menu */}
            <div className="dashboard-list desktop-img">
                <div className="row">
                    <div className="col-md-4">
                        <div className="user-img-list">
                            <img src={currentUser.image} />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <h4 className="welcome-text">Welcome,</h4>
                        <h3 className="user-name">{type == "Agency" ?currentUser.company_name : currentUser.full_name}</h3>
                        <h4 className="welcome-text">{currentUser.id}</h4>
                    </div>
                </div>
                <hr className="border-white" />
                <ul className="dashboard-list-ul">
                    <li>
                        <Link to="/dashboard" className={location.pathname.includes('dashboard') ? "active" : ''}><img
                            src={location.pathname.includes('dashboard') ? "/img/dashboard-dark.svg" : "/img/dashboard.svg"} />Dashboard</Link>
                    </li>

                    {
                        type == "Nurse with NCLEX-RN" && <>



                            <li>
                                <Link to="/reminders" className={location.pathname.includes('reminders') ? "active" : ''}><img
                                    src={location.pathname.includes('reminders') ? "/img/calendar.svg" : "/img/calendar_white.svg"} />Reminders</Link>
                            </li>
                            <li>

                                <Link to="/availability" className={location.pathname.includes('availability') ? "active" : ''}><img
                                    src={location.pathname.includes('availability') ? "/img/time_black.svg" : "/img/time.svg"} />Availability </Link>
                            </li>


                        </>
                    }

                    {(type == "Nurse with NCLEX-RN" || type == "Agency") && <>
                        <li className={location.pathname.includes('inbox') ? "active" : ''}>
                            <Link to="/inbox">
                                <Badge badgeContent={4} color="success">
                                    <img src={location.pathname.includes('inbox') ? "/img/mail-dark.svg" : "/img/mail.svg"} />
                                </Badge>
                                Inbox
                            </Link>
                            <button className="button-online">
                                Online
                                <i className="fa fa-circle online"></i>

                                {/*Offline*/}
                                {/*<i className="fa fa-circle offline"></i>*/}
                            </button>
                        </li>
                        <li>
                            <Link to="/file-sharing" className={location.pathname.includes('file-sharing') ? "active" : ''}><img
                                src={location.pathname.includes('file-sharing') ? "/img/file-dark.svg" : "/img/file.svg"} />File Sharing </Link>
                        </li>
                    </>
                    }
                    {( type !== "Agency") && <>

                    <li>
                        <Link to="/Referral" className={location.pathname.includes('Referral') ? "active" : ''}><img
                            src={location.pathname.includes('Referral') ? "/img/user-add-black.svg" : "/img/user-add.svg"} />Referral </Link>
                    </li>
                    <li>
                        <Link to="/get-paid" className={location.pathname.includes('get-paid') ? "active" : ''}><img
                            src={location.pathname.includes('get-paid') ? "/img/getpaid-black.svg" : "/img/getpaid.svg"} />Get Paid </Link>
                    </li>
                    </>
                    }

                    <li>

                        <Link to="/account-settings" className={location.pathname.includes('account-settings') ? "active" : ''}><img
                            src={location.pathname.includes('account-settings') ? "/img/settings-dark.svg" : "/img/chat-alt.svg"} />Account Settings </Link>
                    </li>
                </ul>
                <hr className="border-white" />
                <a href="javascript:;" onClick={logout} className="logout-btn"><img src="/img/logout.svg" /> Log out</a>
            </div>
            {/*End Desktop Menu */}
        </div>

    )
}
