import React, {useState,useEffect} from 'react';
import { handleFormikSubmit} from "../../Helpers/formHelper";
import { useQuery} from "react-query";
import {getCountries} from "../../adapters/commonAdapter";
import {ClipLoader} from "react-spinners";
import {
    AcceptTermsCondition,
    Countries,
    CountryCode,
    Input,
    Password
} from "../../Helpers/formComponents";
import { Formik,Form } from 'formik';
import {motion} from "framer-motion";
import {SignUpContainerVideo} from "../Common/SignUpContainerVideo";
import {referralSchema} from "../../schemas/referralSchema";
import {referralRegister} from "../../adapters/referralAdapter";
import {Link} from "react-router-dom";
import { getSystemVideo } from '../../adapters/ForumsAdapter';



export default function ReferralSignUp() {

    const { isFetching, data:countries } = useQuery('country', getCountries ,
        {
            cacheTime : 1000 * 60 * 60 * 24,
            staleTime : 1000 * 60 * 60 * 12,
        });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

        const { isLoading, isSuccess, data: video } = useQuery('video', getSystemVideo,
        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });
    return (
        <>
            <SignUpContainerVideo
                // image={''}
                // animationData={animationData}
                formTitle={'Referal Form'}
                formDescription={'Mauris eu nisl vitae odio blandit bibendum.' +
                    'Class aptent taciti sociosqu ad litora torquent per conubia nostra, ' +
                    'per inceptos himenaeos. Nam faucibus lectus sit amet.'}
                video = {video?.data?.data?.SystemVideos?.referral_video}
            >
                {isFetching  ?
                    <div className="row mt-5">
                        <div className={'row'}>
                            <div className="col text-center">

                                <ClipLoader
                                    strokeWidth="4"
                                    animationDuration="2"
                                    width="50"
                                    visible={true}
                                />
                            </div>
                        </div>
                    </div>
                    :
                    <Formik
                        validateOnBlur
                        initialValues={{
                            first_name: '',
                            last_name: '',
                            password: '',
                            email: '',
                            country_id: '',
                            country_code: '',
                            mobile: '',
                        }}
                        validationSchema={referralSchema}
                        onSubmit={handleFormikSubmit(referralRegister)}
                    >
                        {({isValid, isSubmitting , status}) => (
                            <Form method="post">
                                <div className="form-block n-border">
                                    <div className="row">
                                        <div className="col-lg-6">

                                            <Input type="text"   name="first_name" placeholder="First Name (Official)"  />
                                        </div>
                                        <div className="col-lg-6">
                                            <Input type="text"    name="last_name" placeholder="Last Name (Official) "  />
                                        </div>

                                        <div className="col-lg-12">
                                            <Countries options={countries?.data?.data } name={"country_id"} />
                                        </div>
                                        <div className="col-lg-12">
                                            <Input  type="email"   name="email" placeholder="E-Mail"  />
                                        </div>

                                        <div className="col-lg-5">
                                            <CountryCode options={countries?.data?.data } name={"country_code"}/>
                                        </div>
                                        <div className="col-lg-7">
                                            <Input  type="tel"  name="mobile" placeholder="Contact Number"   />
                                        </div>
                                        <div className="col-lg-12">
                                            <Password  name="password" placeholder="Password"   />
                                        </div>

                                    </div>
                                </div>
                                <AcceptTermsCondition/>

                                <div className="col-md-12">
                                    <motion.button
                                        className={status == 200 ? "btn_submit_dark btn btn-success" :"btn_submit_dark"}
                                        type={"submit"}
                                        whileTap={{ scale: 0.7 }}
                                        onHoverStart={e => {}}
                                        onHoverEnd={e => {}}
                                        disabled={isSubmitting}
                                    >  {status == 200 ? "Success" : (isSubmitting ? "Please wait..." : "Submit")}
                                    </motion.button>
                                </div>
                            </Form>
                        )}
                    </Formik>

                }

                <div className="pt-3">

                    <h3 className="account-text">I have an account, <Link to="/nurse-login"><b>LOGIN</b></Link></h3>
                </div>
            </SignUpContainerVideo>
        </>
    );
}
