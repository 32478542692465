import React, { useEffect } from "react"
import { AcceptTermsCondition, AlertFormik, Boolean, File, Files, FormikRecaptcha, Input, Recaptcha } from "../../../Helpers/formComponents";
import { useQuery } from "react-query";
import { getStatesLicensure } from "../../../adapters/ForumsAdapter";
import { motion } from "framer-motion";
import { useFormikContext } from "formik";
import {getReferral} from "../../../axiosSetup";



export function Step3({ setStep, isSubmitting, status }) {
    const { getFieldProps } = useFormikContext();
    let referral = getReferral()

    useEffect(function () {
        window.scrollTo({ top: 130, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <>

            <div className="form-block n-border">
                <div className="row">
                    <div className="col-lg-12">
                        <Input type={"text"} name={"specialty"} placeholder="Specialty" />
                    </div>
                    <div className="col-lg-12">
                        <Input type={"text"} name={"years_of_experience"} placeholder="Years of Experience" />
                    </div>
                    <div className="col-lg-12">
                        <File name={"resume"} label={"Resume"} />
                    </div>
                    <div className="col-lg-12">
                        <Files name={"document"} label={"NCLEX Proof"} />
                    </div>
                    {!referral &&
                        <>
                            <div className="col-lg-12 mb-4">
                                <Boolean label={"Are you referred by a friend ?"} name={"referred_by_friend"} />
                            </div>
                            {getFieldProps("referred_by_friend").value == 1 &&
                                <div className="col-lg-12">
                                    <Input type={"text"} name={"unique_referral_number"}
                                        placeholder="Please Enter the Unique Referral Number" />
                                </div>
                            }
                        </>

                    }
                </div>
            </div>
            <AcceptTermsCondition />
            <FormikRecaptcha name={'g-recaptcha-response'} />
            <div className="col-md-12">
                <motion.button
                    className={status == 200 ? "btn_submit_dark btn btn-success" : status == 422 ? "btn_submit_dark btn-danger" : "btn_submit_dark"}
                    type={"submit"}
                    whileTap={{ scale: 0.7 }}
                    onHoverStart={e => { }}
                    onHoverEnd={e => { }}
                    disabled={isSubmitting}
                >  {status == 200 ? "Success" : (isSubmitting ? "Please wait..." : status == 422 ? "Please fix the form errors" : "Submit")}
                </motion.button>
            </div>
        </>

    )
}