import React from "react";
// import Lottie from "lottie-react";
// import * as animationData1 from '../../../assets/animation/HomepageStep1.json';
// import * as animationData2 from '../../../assets/animation/HomepageStep2.json';
// import * as animationData3 from '../../../assets/animation/HomepageStep3.json';
import VectorStepOne from '../../../assets/img/component75.png';
import VectorStepTwo from '../../../assets/img/component76.png';
import VectorStepThree from '../../../assets/img/component77.png';
export function SectionTwo ({data}){
    return (

        <div className="section container">
            <h1 className="main-title-white text-ceter">
                Hiring is very easy with <br/> these <span className="main-color-dark">three</span> steps.
            </h1>
            <div className="text-center">
                <img alt='img' src="img/vector27.svg"/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 text-center">
                        <div className="apply-section-img">
                            {/*<Lottie animationData={animationData1} className="apply-img" />*/}
                            <img alt='img' src={VectorStepOne} className="apply-img"/>
                            <p className="apply-number">1</p>
                        </div>
                        <h3 className="apply-title">{data.title_1}</h3>
                        <img alt='img' src="img/vector28.svg"/>
                        <p className="paragraph-text apply-text">{data.description_1}</p>
                    </div>
                    <div className="col-md-4 text-center">
                        <div className="apply-section-img">
                            {/*<Lottie animationData={animationData2} className="apply-img" />*/}
                            <img alt='img' src={VectorStepTwo} className="apply-img"/>
                            <p className="apply-number">2</p>
                        </div>
                        <h3 className="apply-title">{data.title_2}</h3>
                        <img alt='img' src="img/vector28.svg"/>
                        <p className="paragraph-text apply-text">{data.description_2}</p>
                    </div>
                    <div className="col-md-4 text-center">
                        <div className="apply-section-img">
                            <img alt='img' src={VectorStepThree} className="apply-img"/>
                            {/*<Lottie animationData={animationData3} className="apply-img" />*/}
                            <p className="apply-number">3</p>
                        </div>
                        <h3 className="apply-title">{data.title_3}</h3>
                        <img alt='img' src="img/vector28.svg"/>
                        <p className="paragraph-text apply-text">{data.description_3}</p>
                    </div>
                </div>
            </div>
        </div>

    );
}
