import React, { useState } from 'react';
import { SignUpContainer } from "../Common/SignUpContainer";
import { handleFormikSubmit } from "../../Helpers/formHelper";
import { useQuery } from "react-query";
import { getCountries } from "../../adapters/commonAdapter";
import { lookingToImmigrateRegister, nurseApplyingRegister } from "../../adapters/applicantSignUpAdapter";
import { ClipLoader } from "react-spinners";
import {
    AcceptTermsCondition,
    Countries,
    CountryCode,
    Date,
    File,
    FormikRecaptcha,
    Gender,
    Input,
    Password,
    Recaptcha
} from "../../Helpers/formComponents";
import { Formik, Form } from 'formik';
import { clinicalSchema } from "../../schemas/clinicalSchema";
import { motion } from "framer-motion";
import * as animationData from '../../assets/animation/Apply_3.json';
import {useNavigate} from "react-router-dom";


export default function NclexSignUp() {



    const { isFetching, data: countries } = useQuery('country', getCountries,
        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });
    const navigate = useNavigate();

    const redirectMessage = () => {
        navigate('/', { state: { message: "Your form has been submitted and one of the registered companies will contact you soon." } });
    }
    return (
        <>
            <SignUpContainer
                // image={'img/IMG-nurse-3-1.png'}
                animationData={animationData}
                formTitle={'Apply Form'}
                formDescription={'We ensure your privacy and guarantee that the information you enter in the registration form is kept safe and secure with us.'}
            >
                {isFetching ?
                    <div className="row mt-5">
                        <div className={'row'}>
                            <div className="col text-center">

                                <ClipLoader
                                    strokeWidth="4"
                                    animationDuration="2"
                                    width="50"
                                    visible={true}
                                />
                            </div>
                        </div>
                    </div>
                    :
                    <Formik
                        validateOnBlur
                        initialValues={{
                            first_name: '',
                            last_name: '',
                            looking_to_immigrate_date_of_birth: '',
                            gender: '',
                            email: '',
                            country_id: '',
                            country_code: '',
                            mobile: '',
                            address: '',
                            looking_to_immigrate_resume: null,
                        }}
                        validationSchema={clinicalSchema}
                        onSubmit={handleFormikSubmit(lookingToImmigrateRegister)}
                    >
                        {({ isValid, isSubmitting, status }) => (
                            <Form method="post">
                                <div className="form-block n-border">
                                    <div className="row">
                                        <div className="col-lg-6">

                                            <Input type="text" name="first_name" placeholder="First Name (Official)" />
                                        </div>
                                        <div className="col-lg-6">
                                            <Input type="text" name="last_name" placeholder="Last Name (Official) " />
                                        </div>
                                        <div className="col-lg-12">

                                            <Date label="Date of birth (Optional)" name={'looking_to_immigrate_date_of_birth'} />
                                        </div>
                                        <div className="col-lg-12">
                                            <Gender name="gender" />
                                        </div>
                                        <div className="col-lg-12">
                                            <Input type="email" name="email" placeholder="E-Mail" />
                                        </div>
                                        <div className="col-lg-12">
                                            <Countries options={countries?.data?.data} name={"country_id"} />
                                        </div>
                                        <div className="col-lg-5">
                                            <CountryCode options={countries?.data?.data} name={"country_code"} />
                                        </div>
                                        <div className="col-lg-7">
                                            <Input type="tel" name="mobile" placeholder="Contact Number" />
                                        </div>
                                        <div className="col-lg-12">
                                            <Input type="text" name="address" placeholder="Address" />
                                        </div>

                                        <div className="col-lg-12">
                                            <File name="looking_to_immigrate_resume" label={'Upload Resume'} />
                                        </div>
                                    </div>
                                </div>
                                <AcceptTermsCondition />
                                <FormikRecaptcha name={'g-recaptcha-response'} />

                                <div className="col-md-12">
                                    <motion.button
                                        className={status == 200 ? "btn_submit_dark btn btn-success" : "btn_submit_dark"}
                                        type={"submit"}
                                        whileTap={{ scale: 0.7 }}
                                        onHoverStart={e => { }}
                                        onHoverEnd={e => { }}
                                        disabled={isSubmitting}
                                    >
                                        {status == 200 ? "Your form has been submitted." : (isSubmitting ? "Please wait..." : "Submit")}
                                        {status == 200 && redirectMessage()}
                                    </motion.button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                }

            </SignUpContainer>
        </>
    );
}



