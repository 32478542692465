import * as yup from 'yup';

export let emailLoginSchema = yup.object().shape({
    email : yup.string().email().required(),
    password : yup.string().required(),
});


export let tokenSchema = yup.object().shape({
    token : yup.string().required(),
});
