import React from "react";
import {docusignConnect} from "../../../adapters/commonAdapter";
import {Form, Formik} from "formik";
import {handleFormikSubmit} from "../../../Helpers/formHelper";
import {motion} from "framer-motion";
import {File} from "../../../Helpers/formComponents";
import {uploadContract} from "../../../adapters/agencyAdapter";
import {useAgencyDashboard} from "../AgencyDashboardProvider";


export function ContractSection ({application}){

    function connectDocusign(){
        docusignConnect()
    }
    const {reloadDashboard,setReloadDashboard} = useAgencyDashboard()


    return <div className="calendar-tab border-top-dashed">
        <div className="d-flex">
            <div className="calendar-list active">
                        <span>
                            3
                        </span>
            </div>
            <div className="calendar-flex">
                <h3 className="tab-title">Contract</h3>
                <p>
                    Upload and share the contract with the applicant after the interview is done for a seamless hiring process.
                </p>
                    <div className="row mt-4">

                        { !application.docusign_status &&
                        <div className="col-md-6">
                            <button type={'button'} onClick={connectDocusign} className="btn_submit_dark mt-0">Connect docusign</button>
                        </div>}
                        { application.docusign_status &&
                            <>

                                { <Formik
                                    validateOnBlur
                                    initialValues={{
                                        contract: null,
                                    }}
                                    // validationSchema={nurseUpdateProfileSchema}
                                    onSubmit={handleFormikSubmit(
                                        uploadContract(function(){
                                            setReloadDashboard(!reloadDashboard);
                                        })
                                    )}
                                >
                                    {({isValid, isSubmitting , status}) => {

                                        return (
                                            <Form method="post" className="form-rep">
                                                    <div className="row">
                                                        <div className="col-md-7">
                                                            <File label={'Upload the contract'} name={'contract'} />
                                                            <p className="note-upload">Contract Upload to be PDF only &amp; File Explore PDF</p>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <motion.button
                                                                className={status == 200 ? "btn_submit_dark btn btn-success mt-0 p-2-5" :"btn_submit_dark mt-0 p-2-5"}
                                                                type={"submit"}
                                                                whileTap={{ scale: 0.7 }}
                                                                onHoverStart={e => {}}
                                                                onHoverEnd={e => {}}
                                                                disabled={isSubmitting}
                                                            >  {status == 200 ? "Success" : (isSubmitting ? "Please wait..." : "Send")}
                                                            </motion.button>
                                                        </div>
                                                    </div>
                                            </Form>)
                                    }}
                                </Formik>}


                            </>
                        }

                    </div>
            </div>
        </div>
    </div>

}