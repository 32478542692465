import {handleFormikSubmit} from "../../../Helpers/formHelper";
import {addCandidate} from "../../../adapters/representativeAdapter";

import {Form, Formik} from "formik";
import {useAuth} from "../../../app/auth";
import {
    Countries,
    CountryCode,
    File,
    FormikCheckBox,
    Gender,
    Input,
    Date,

    Password
} from "../../../Helpers/formComponents";
import React from "react";
import {useQuery} from "react-query";
import {getCountries} from "../../../adapters/commonAdapter";
import {motion} from "framer-motion";
import {Dashboard} from "../../UserCommon";
import {applicantSchema} from "../../../schemas/applicantSchema";

export function AddRepresentativeCandidates(){
    const { isFetching, data:countries } = useQuery('country', getCountries ,
        {
            cacheTime : 1000 * 60 * 60 * 24,
            staleTime : 1000 * 60 * 60 * 12,
        });
    let {currentUser} = useAuth()
    return (
        <Dashboard>

            <div className="col-md-9">
                <div className="sigma_subheader_inner style-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Add Candidate</h2>
                                <p className="paragraph-text">
                                    Donec in est eu dui venenatis dapibus. Aenean non turpis suscipit, consequat dolor
                                    a,venenatis.
                                    ean non turpis suscipit, consequat dolor a,venenatis.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">

                            { !isFetching && <Formik
                                    validateOnBlur
                                    initialValues={{
                                        first_name: "",
                                        last_name: "",
                                        email: "",
                                        gender: "",
                                        country_code: "",
                                        mobile: "",
                                        country_id: null,
                                        image: null,
                                        address: null ,
                                        looking_to_immigrate_date_of_birth:null,
                                        resume:null

                                    }}
                                    validationSchema={applicantSchema}
                                    onSubmit={handleFormikSubmit(addCandidate,()=>{},()=>{
                                        window.location.href = "/dashboard";
                                    })}
                                >
                                    {({isValid, isSubmitting , status}) => {
                                        return (
                                            <Form method="post" className="form-rep">
                                                <h4 className="past-text">Personal information</h4>
                                                <div className="form-block n-border mb-4">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <Input type="text"   name="first_name" placeholder="First Name (Official)"  />

                                                        </div>
                                                        <div className="col-lg-6">
                                                            <Input type="text"    name="last_name" placeholder="Last Name (Official) "  />
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <Input  type="email"   name="email" placeholder="E-Mail"  />

                                                        </div>
                                                        <div className="col-lg-6">
                                                            <Gender  name="gender" />
                                                        </div>
                                                       <div className="col-lg-6"></div>
                                                        <div className="col-lg-3">
                                                            <CountryCode options={countries?.data?.data } name={"country_code"}/>

                                                        </div>
                                                        <div className="col-lg-3">
                                                            <Input  type="tel"  name="mobile" placeholder="Contact Number"   />
                                                        </div>
                                                        <div className="col-lg-6"></div>

                                                        <div className="col-lg-6">
                                                            <File name={"image"} label={"Profile photo"}/>

                                                        </div>
                                                    </div>
                                                </div>

                                                <h4 className="past-text">Address information</h4>
                                                <div className="form-block n-border mb-4">
                                                    <div className="row">

                                                        <div className="col-lg-6">
                                                            <Countries options={countries?.data?.data } name={"country_id"} />
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <Input  type="text" name="address" placeholder="Address"  />
                                                        </div>
                                                    </div>
                                                </div>

                                                <h4 className="past-text">Other </h4>
                                                <div className="form-block n-border mb-4">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <Date label="Date of birth (Optional)" name={'looking_to_immigrate_date_of_birth'}/>
                                                        </div>
                                                        <div className="col-lg-6"></div>

                                                        <div className="col-lg-6">
                                                            <File   name="resume"  label={'Upload Resume'}  />
                                                        </div>
                                                        <div className="col-lg-6"></div>

                                                    </div>
                                                </div>
                                                <div className="form-block n-border mb-4">
                                                    <div className="row">
                                                       
                                                        <div className="col-md-6">
                                                            <motion.button
                                                                className={status == 200 ? "btn_submit_dark btn btn-success" :"btn_submit_dark"}
                                                                type={"submit"}
                                                                whileTap={{ scale: 0.7 }}
                                                                onHoverStart={e => {}}
                                                                onHoverEnd={e => {}}
                                                                disabled={isSubmitting}
                                                            >  {status == 200 ? "Success" : (isSubmitting ? "Please wait..." : "Create")}
                                                            </motion.button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>)
                                    }}
                                        </Formik>}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Dashboard>
    )
}