import React from 'react';
import {BannerContainer} from "../Common/BannerContainer";
import {TitleLeftBanner} from "../Common/TitleLeftBanner";
import {Types} from "../SignUpTypes/Types";
import {ContactForm} from "../Common/ContactForm";
import * as AngencySignUp from '../../assets/animation/Thumbnail_AgencySignUp.json';
import * as applicantSignUp from '../../assets/animation/Thumbnail_NurseswithNCLEX-RN.json';
import * as representativeSingUp from '../../assets/animation/Thumbnail_Representative_Sign_Up.json';

export default function Login() {
    return (
        <>
            <BannerContainer
                style={{
                    backgroundImage: "url(img/RegisterBackground.png)",
                    backgroundPosition: "inherit",
                    backgroundSize: "cover",
                    paddingBottom: "190px",
                    paddingTop: "212px"
                }}>
                <div className="row">
                    <div className="col-md-6">
                        <TitleLeftBanner
                            title={'Log In'}
                            description={'Donec in est eu dui venenatis dapibus.\n Aenean non turpis suscipit, consequat\n'
                                + 'dolor a,venenatis dapiban non turpis suscipit.'}
                        />
                    </div>
                </div>
                <div className="row">
                    <Types
                        link={'/agency-login'}
                        image={'img/agency-img.png'}
                        // animationData={AngencySignUp}
                        title={'Agency'}
                    />
                    <Types
                        link={'/nurse-login'}
                        image={'img/applicant-img.png'}
                        // animationData={applicantSignUp}
                        title={'Applicant'}
                    />
                    <Types
                        link={'/representative-login'}
                        image={'img/representative-img.png'}
                        // animationData={representativeSingUp}
                        title={'Representative'}
                    />

                </div>
            </BannerContainer>
            <ContactForm/>
        </>
    );
}



