import React, {useEffect} from "react"
import {
    AcceptTermsCondition,
    AlertFormik,
    Boolean,
    Date,
    Input,
    PassNclex,
    States
} from "../../../Helpers/formComponents";
import {useQuery} from "react-query";
import {getStatesLicensure} from "../../../adapters/ForumsAdapter";
import {useFormikContext} from "formik";
import {Link} from "react-router-dom";



export function Step2({setStep,states_data,submitForm}){

    const {getFieldProps} = useFormikContext();

    useEffect(function (){
        window.scrollTo({top: 130, left: 0, behavior: 'smooth'});
    },[]);
    const [status, setStatus] = React.useState(0) // 0: no show, 1: show yes, 2: show no.
    const radioHandler = (status) => {
        setStatus(status);
    };

    return (
        <>

            <div className="form-block n-border">
                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <div className="form-block">
                            <Boolean label={"Pass The NCLEX-RN Test ?"} name={"passed"}/>
                        </div>
                    </div>

                    {getFieldProps("passed").value==1 &&
                    <>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <Input type={"text"} name={'rn_license_number'} placeholder="RN License Number"/>
                            </div>

                        </div>
                        <div className="col-lg-12">
                            <States name={'issuing_state_id'} label={"Issuing State"} placeholder={"Issuing State"} options={states_data?.data?.data } />

                        </div>
                        <div className="col-lg-12">
                            <Date name={"expiry_date"} label={"Expiry Date"}/>

                        </div>
                    </>
                    }
                    {getFieldProps("passed").value==0 ? <div className="d-flex align-items-center mt-2">
                        <label className="mb-0 checkbox-label">
                            <img src="img/ex.svg" className="mr-3" />
                            You are not eligible to apply using this form, Start your nursing career in the USA by <Link to="/nclex-signup">CLICK HERE</Link>.
                        </label>
                    </div>:''}
                </div>
            </div>
            <AcceptTermsCondition/>
            <div className="col-md-12">
                <button type={"button"} className={"btn_submit_dark "+(getFieldProps("passed").value==1 ?"":"disabled") } onClick={()=>submitForm()}>Continue</button>
            </div>
        </>

    )
}