import React, {useEffect, useState} from 'react';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {Link} from "react-router-dom";
import {Dashboard} from "../UserCommon";
import {AvailabilityGeneral} from "../NurseUser/General/AvailabilityGeneral";
import {ContractSectionDisabled} from "./AgencyDashboardSections/ContractSectionDisabled";
import {InterviewSectionDisabled} from "./AgencyDashboardSections/InterviewSectionDisabled";
import moment from "moment-timezone";


export default function
    AgencyDashboardCalendar({application}) {

    const [time, setTime] = useState();
    const [meetingDate, setMeetingDate] = useState(null);
    const [currentTimezone, setCurrentTimezone] = useState(null);
    const [formattedDate, setFormatedDate] = useState(null);
    const [error, setError] = useState(null);
    useEffect(function (){
        if(meetingDate){

            const timestamp = meetingDate;
            // console.log(currentTimezone);
            const momentObj = moment(timestamp);
            const formated = momentObj.tz(currentTimezone).format('dddd, MMMM D, h:mm a z');
            setFormatedDate(formated);
        }

    },[meetingDate])
    return (

        <>
                <div className="calendar-tab">
                    <div className="d-flex pb-4">
                        <div className="calendar-list active">
                            <span>
                                1
                            </span>
                        </div>
                        <div className="calendar-flex">
                            <h3 className="tab-title">Calendar</h3>
                            <p>
                                Manage your schedule by selecting available time slots from the applicant's timetable and organizing meetings easily.
                            </p>
                        </div>
                    </div>

                    <form>
                        <div className="row">
                            <div className="col-md-12">
                                { !!meetingDate &&<ul className="list-calendar">
                                   <li>
                                       <h4 className="list-calendar-title">
                                            <img src="img/calendar-dark.svg" />
                                           Meeting with {application.candidate.full_name}
                                       </h4>
                                   </li>
                                   <li>
                                       <h4 className="list-calendar-title red">
                                            <img src="img/clock-red.svg" />
                                           {formattedDate}
                                       </h4>
                                   </li>
                                   <li>
                                       <h4 className="list-calendar-title">
                                            <img src="img/time-dark.svg" />
                                           60 minutes
                                       </h4>
                                   </li>
                                   <li>
                                       <h4 className="list-calendar-title">
                                            <img src="img/pin-dark.svg" />
                                           {application.candidate.country.name}
                                       </h4>
                                   </li>
                               </ul>}
                                <AvailabilityGeneral showForm={!meetingDate} showSubmit={!!meetingDate} >
                                    {(selectedDate,timezone)=>{
                                       return <>
                                            {!meetingDate && <a href={"javascript:;"} onClick={()=>{
                                            if(selectedDate){
                                                setError(null)
                                                setMeetingDate(selectedDate)
                                                setCurrentTimezone(timezone)
                                            }else{
                                                setError("Please choose the meeting time")
                                            }

                                            }} className="btn_submit_dark">
                                                Schedule
                                                </a>}
                                           {!!meetingDate && <a href={"javascript:;"} onClick={()=>{
                                               if(selectedDate){
                                                   setError(null)
                                                   setMeetingDate(null)
                                               }

                                           }} className="btn-white-dark p-2">
                                               Cancel
                                           </a>}

                                            </>
                                    }
                                    }
                                </AvailabilityGeneral>
                                {!!error && <span className={"text-danger"} >{error}</span>}



                            </div>
                        </div>
                    </form>
                </div>
                <InterviewSectionDisabled/>
                <ContractSectionDisabled/>
        </>
    );
}



