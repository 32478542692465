import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {AuthProvider, getTokenFromLocalStorage} from "./app/auth";
import {AppRoutes} from "./routing/AppRoutes";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
import "./assets/fonts/font-awesome.min.css";
import "./assets/fonts/flaticon/flaticon.css";
import {MetronicSplashScreenProvider} from "./layout/core";
import {ReactQueryDevtools} from "react-query/devtools";
import { QueryClient, QueryClientProvider } from 'react-query'
import {AxiosInterceptor, AxiosSetup} from "./axiosSetup";
import axios from "axios";
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            suspense: true,
        },
    },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
const x = 1;
let token =  getTokenFromLocalStorage();
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers['Authorization'] = `Bearer ${token}`
axios.defaults.headers["Accept"] = 'application/json';
axios.defaults.headers["Content-Type"] = 'application/json';

root.render(
    <>


        <QueryClientProvider client={queryClient}>
            <AxiosSetup>
                <AuthProvider>
                    <AxiosInterceptor>
                        <AppRoutes  />
                    </AxiosInterceptor>
                </AuthProvider>
                <ReactQueryDevtools/>
            </AxiosSetup>
        </QueryClientProvider>

    </>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
