import {emailLoginSchema} from "../../schemas/emailLoginSchema";
import {handleFormikSubmit} from "../../Helpers/formHelper";
import {Login} from "../../adapters/nurseAdapter";
import {Form, Formik} from "formik";
import {LoginContainer} from "../Common/LoginContainer";
import {Input, Password} from "../../Helpers/formComponents";
import {sendChangePassword, sendOtp} from "../../adapters/authAdapter";
import {changePassword} from "../../schemas/forgetPasswordSchema";
import {motion} from "framer-motion";
import React from "react";
import {useNavigate} from "react-router-dom";
import { useLocation } from "react-router-dom";


export function ResetPasswordForm(){
    const location = useLocation();
    const email = new URLSearchParams(location.search).get("email");
    const navigate = useNavigate();
    const sendFunc = sendChangePassword(
        (res)=>{
            res = res.hasOwnProperty('response') ? res.response :res
            if(res.status == 200) {
                navigate("/nurse-login");
            }
            return res
        }
    )

    return (

    <LoginContainer
            image={'img/login-img.png'}
            // animationData={animationData}
            formTitle={'Forgot Password ?'}
            formDescription={'Please enter the OTP information we have sent to you and your new password.'}
        >
            <Formik
                validateOnBlur
                initialValues={{
                    email: email,
                    otp: '',
                    password: '',
                }}
                validationSchema={changePassword}
                onSubmit={handleFormikSubmit(sendFunc)


            }
            >
                {({isValid, isSubmitting , status}) => (
                    <Form method="post">
                                <div className="form-block n-border">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <Password  name="password" placeholder="Password"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <Input type="text" name="otp" placeholder="OTP Token"/>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <motion.button
                                        className={status == 200 ? "btn_submit_dark btn btn-success" :"btn_submit_dark"}
                                        type={"submit"}
                                        whileTap={{ scale: 0.7 }}
                                        onHoverStart={e => {}}
                                        onHoverEnd={e => {}}
                                        disabled={isSubmitting}
                                    >  {status == 200 ? "Success" : (isSubmitting ? "Please wait..." : "VERIFY")}
                                    </motion.button>
                                </div>
                    </Form>
                    )}
            </Formik>
    </LoginContainer>
)
}