import React from 'react';
import { ContactForm } from "../../Common/ContactForm";
import { BannerContainer } from "../../Common/BannerContainer";
import { TitleOnlyBanner } from "../../Common/TitleOnlyBanner";
import { Card } from "./Card";
import { CardContainer } from "./CardContainer";
import FooterImage from "../../Common/FooterImage"
import { useQuery } from "react-query";
import { getBookingSettings } from "../../../adapters/ForumsAdapter";
import * as animationDataFlight from "../../../assets/animation/Fly_Landing.json";
import * as animationDataHotel from "../../../assets/animation/Hotel_Landingpage.json";
import * as animationDataTransport from "../../../assets/animation/Transport_Landing.json";
import Lottie from "lottie-react";
import * as LeftData from "../../../assets/animation/AbstractDotsBgLeft.json";
import * as rightData from "../../../assets/animation/AbstractDotsBgRight.json";

export default function Booking() {
    const { data: setting } = useQuery('booking-setting', getBookingSettings,
        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });

    return (

        <>
            <BannerContainer style={{
                background: "#fff"
            }}>
                <div className="animation-left">
                    <Lottie animationData={LeftData} />
                </div>
                <div className="animation-right">
                    <Lottie animationData={rightData} />
                </div>
                <TitleOnlyBanner
                    title={'Booking'}
                    description={setting?.data.data.BookingData?.booking_desc_1}

                // {'Donec in est eu dui venenatis dapibus.\n Aenean non turpis suscipit, consequat\n'
                //     + 'dolor a,venenatis dapiban non turpis suscipit.'}
                />

                <CardContainer className="img-bookins">
                    <Card
                        title={'HOTELS'}
                        animationData={animationDataHotel}
                        link={'/hotels'}
                        Image={'img/hotel-img.png'}
                    />
                    <Card
                        title={'FLIGHT'}
                        animationData={animationDataFlight}
                        link={'/flight'}
                        Image={'img/fly-img.png'}
                    />
                    <Card
                        title={'TRANSPORT'}
                        animationData={animationDataTransport}
                        link={'/transport'}
                        Image={'img/transfer-img.png'}
                    />

                </CardContainer>

            </BannerContainer>
            <FooterImage />
            <ContactForm />

        </>
    )
}
