import { handleFormikSubmit } from "../../../Helpers/formHelper";
import { Field, Form, Formik } from "formik";
import { useAuth } from "../../../app/auth";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { getReferalData, inviteFriend, getCountries } from "../../../adapters/commonAdapter";
import { motion } from "framer-motion";
import { Dashboard } from "../../UserCommon";
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import { inviteSchema } from "../../../schemas/inviteSchema";

export function Referral() {
    const { isFetching, data } = useQuery('referalData',
        // getCountries ,
        getReferalData,

        {
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 12,
        });
    let { currentUser } = useAuth()
    let referralLink = process.env.REACT_APP_WEB_URL + "/refer/" + currentUser.referral_code;
    const [isSubmittingData, setIsSubmitting] = useState(false);
    return (
        <Dashboard>

            <div className="col-md-9">
                <div className="sigma_subheader_inner style-5">
                    <div className="container">

                        <div className="row">
                            <div className="col-md-6">
                                <h2>Referrals</h2>
                                <p className="paragraph-text">
                                    Refer and earn bonuses with our referral program by helping nurses find the best opportunities according to their needs.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="white-box-dash">
                                    <h4 className="grey-text-cap">TOTAL REWARDS EARNED</h4>
                                    <h2 className="number-title">$ {data?.data?.data?.total_earned}</h2>
                                    <div className="border-green">&nbsp;</div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="white-box-dash">
                                    <h4 className="grey-text-cap">Pending</h4>
                                    <h2 className="number-title">$ {data?.data?.data?.pending}+</h2>
                                    <div className="border-orange">&nbsp;</div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="white-box-dash">
                                    <h4 className="grey-text-cap">total referal</h4>
                                    <h2 className="number-title"><img src="/img/iocon-users.svg" /> {data?.data?.data?.total_referral}</h2>
                                    <div className="border-grey">&nbsp;</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="more-info">
                                    <img src="/img/Frame22137.svg" />
                                    <h3>Send Invitation</h3>
                                    <p>Invite friends, colleagues, or family members who are nurses by either sending an invitation directly to their email addresses or by sharing your referral link.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="more-info">
                                    <img src="/img/Frame22138.svg" />
                                    <h3>Registration</h3>
                                    <p>The referred person can click on the link, and during the signup process, they need to enter the referral code shared by the user who referred them.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="more-info">
                                    <img src="/img/Frame22136.svg" />
                                    <h3>Earn More</h3>
                                    <p>Once your referred person successfully accepts a contract offer, you will receive your referral bonus. You can refer as many nurses as you want and earn unlimited bonuses.</p>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="box-white-n mt-5">
                                    <h3 className="title-w">Invite your friends</h3>
                                    <p className="text-p">Enter the person's email you want to refer to, and an invitation will be sent to them.</p>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-block n-border">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <Formik
                                                                validateOnBlur
                                                                initialValues={{
                                                                    email: '',

                                                                }}
                                                                onSubmit={handleFormikSubmit(inviteFriend, () => { }, () => {
                                                                    setIsSubmitting(true)
                                                                    setTimeout(() => {
                                                                        setIsSubmitting(false);
                                                                    }, 5000);
                                                                })}
                                                                validationSchema={inviteSchema}

                                                            >
                                                                {({ isValid, isSubmitting, status, setStatus,setSubmitting ,errors, touched }) => (
                                                                    <Form method="post">
                                                                        <Field type="text" name="email" placeholder="Email address"  />
                                                                        
                                                                        <motion.button
                                                                            className={status == 200 ? (isSubmittingData ? "token_submit p-31 btn btn-success" : "token_submit p-31") : "token_submit p-31"}
                                                                            type={"submit"}
                                                                            whileTap={{ scale: 0.7 }}
                                                                            onHoverStart={e => { }}
                                                                            onHoverEnd={e => { }}
                                                                            disabled={isSubmitting}

                                                                        >  {status == 200 ? (isSubmittingData ? "Success" :
                                                                            (function () {
                                                                                setStatus('')
                                                                                setSubmitting(false)
                                                                                return "SEND"
                                                                            })()) : (isSubmitting ? "Please wait..." : "SEND")}
                                                                        </motion.button>
                                                                        {touched.email && errors.email && <div >{errors.email}</div>}

                                                                    </Form>
                                                                )}
                                                            </Formik>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="col-md-3">*/}


                                        {/*    <ul className="sigma_social-icons mt-0 justify-content-start">*/}
                                        {/*        <li>*/}

                                        {/*            /!*</FacebookShareButton>*!/*/}

                                        {/*            <FacebookShareButton url={referralLink} title={"Welcome To Medic Staff"}>*/}
                                        {/*                <a href="#"><img src="/img/fb-dark.svg"/></a>*/}
                                        {/*            </FacebookShareButton>*/}
                                        {/*        </li>*/}
                                        {/*        <li>*/}
                                        {/*            <TwitterShareButton url={referralLink} title={"Welcome To Medic Staff"}>*/}
                                        {/*                <a href="#"><img src="/img/twitter-dark.svg"/></a>*/}
                                        {/*            </TwitterShareButton>*/}
                                        {/*        </li>*/}
                                        {/*        <li>*/}
                                        {/*            <WhatsappShareButton url={referralLink} title={"Welcome To Medic Staff"}>*/}
                                        {/*                <a href="#">*/}
                                        {/*                    <img src="/img/whatsapp-dark.svg" style={{width: "29px"}}/>*/}
                                        {/*                </a>*/}
                                        {/*            </WhatsappShareButton>*/}

                                        {/*        </li>*/}
                                        {/*    </ul>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="box-white-n mt-5">
                                    <h3 className="title-w">Share the referal link</h3>
                                    <p className="text-p">Copy the referral link below and share it directly on social media or other platforms.</p>
                                    <div className="row">
                                        <div className="col-md-9">
                                            <div className="form-block n-border">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <input id='1' className="js-copytextarea" name="text"
                                                                type="text"
                                                                readOnly={true}
                                                                placeholder={referralLink}

                                                            />
                                                            <button onClick={() => {
                                                                navigator.clipboard.writeText(referralLink);
                                                            }}
                                                                className="js-textareacopybtn copy_button"
                                                                data-id="1"><img src="/img/copy.svg" />
                                                            </button>
                                                        </div>
                                                        <div className="user-input-wrp form-group mt-20 referral_label">
                                                            {/*<input type="text" onBlur={({ target }) => target.focus()}value={currentUser.referral_code} className="inputText" readOnly={true}/>*/}
                                                            <input type="text"
                                                                value={currentUser.referral_code}
                                                                className="inputText"
                                                                readOnly={true}
                                                            />
                                                            <span className="floating-label">Referral Code</span>
                                                            <button onClick={() => {
                                                                navigator.clipboard.writeText(currentUser.referral_code);
                                                            }}
                                                                className="js-textareacopybtn copy_button"
                                                                data-id="1"><img src="/img/copy.svg" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">


                                            <ul className="sigma_social-icons mt-0 justify-content-start">
                                                <li>

                                                    {/*</FacebookShareButton>*/}

                                                    <FacebookShareButton url={referralLink} title={"Welcome To Medic Staff"}>
                                                        <a href="#"><img src="/img/fb-dark.svg" /></a>
                                                    </FacebookShareButton>
                                                </li>
                                                <li>
                                                    <TwitterShareButton url={referralLink} title={"Welcome To Medic Staff"}>
                                                        <a href="#"><img src="/img/twitter-dark.svg" /></a>
                                                    </TwitterShareButton>
                                                </li>
                                                <li>
                                                    <WhatsappShareButton url={referralLink} title={"Welcome To Medic Staff"}>
                                                        <a href="#">
                                                            <img src="/img/whatsapp-dark.svg" style={{ width: "29px" }} />
                                                        </a>
                                                    </WhatsappShareButton>

                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </Dashboard>
    )
}